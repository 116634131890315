import React, { useEffect, useState, useContext } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';

import { useIntl } from 'react-intl';

import ModalWindow from '../modal_window';
import Profile from '../profile';
import Support from '../support';
import Messaging from '../../components/messaging/messaging';

import { logoutUser, setPreferredLanguage } from '../../store/user/actions';
import { UserStoreContext, UserDispatchContext } from '../../store/user/store';
import { useNotifyListDataUpdate } from '../../hooks/use_notify_list_data_update';

import Avatar from '../avatar';
import { ROLE_ADMIN, ROLE_APPROVER, ROLE_FINAL_APPROVER, ROLE_INITIATOR, ROLE_ROOT } from '../../constants/roles';
import { useData } from '../../rest/client';
import {
  URL_AUTH,
  URL_FAMILIARIZATION,
  URL_INBOX,
  URL_MEMBERS,
  URL_OUTGOING,
  URL_SPLASH,
  URL_LOGOUT,
} from '../../constants/urls';

import './menu.scss';

function Menu({ history, location }) {
  const intl = useIntl();
  const user = useContext(UserStoreContext);
  const dispatch = useContext(UserDispatchContext);

  const MINUTE = 60000;
  const { pathname } = location;
  const [profileOpen, setProfileOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const isOnNotAuthedPage = [URL_AUTH, URL_SPLASH, URL_LOGOUT].some(url => pathname.includes(url));

  const [notifications, setNotifications] = useState({
    [URL_INBOX]: 0,
    [URL_OUTGOING]: 0,
    [URL_FAMILIARIZATION]: 0,
  });

  const updateList = useNotifyListDataUpdate();
  const { data: packageCountsData, unsubscribe: packageCountsUnsubscribe, notify: packageCountsNotify } = useData(
    'rpc',
    'post',
    '/getDetailedDocumentPackageCounts',
    {},
    true
  );

  useEffect(() => {
    const { data, status } = packageCountsData;
    if (status) {
      setNotifications({
        [URL_INBOX]: data.incoming,
        [URL_OUTGOING]: data.outgoing,
        [URL_FAMILIARIZATION]: data.watching,
      });
    }
  }, [packageCountsData]);

  useEffect(() => {
    if (typeof user.role !== 'undefined') {
      setIsAdmin([ROLE_ROOT, ROLE_ADMIN].includes(user.role));
    }
  }, [user]);

  useEffect(() => {
    if (typeof isAdmin !== 'undefined' && !isAdmin && !isOnNotAuthedPage) {
      packageCountsNotify();
    }

    return () => packageCountsUnsubscribe();
    // eslint-disable-next-line
  }, [isAdmin, isOnNotAuthedPage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateList();
      if (typeof isAdmin !== 'undefined' && !isAdmin && !isOnNotAuthedPage) {
        packageCountsNotify();
      }
    }, MINUTE);

    return () => clearInterval(intervalId);
  }, [isAdmin, isOnNotAuthedPage, packageCountsNotify, updateList]);

  if (isOnNotAuthedPage || user.authenticated !== true) {
    return null;
  }
  const goTo = url => {
    history.push(url);
  };

  const goToAdmin = () => {
    window.location.replace('https://admin.' + window.location.host);
  };

  const menus = getMenuLocalizedConfig(intl);

  return (
    <div className="menu">
      <Link to="/" className="menu__block menu__logo" />
      {menus.map(({ url, text, roles }) =>
        roles.includes(user.role) ? (
          <Route
            key={url}
            path={url}
            children={({ match }) => (
              <div className={`menu__block --${url.slice(1)} ${(match && '--active') || ''}`} onClick={() => goTo(url)}>
                <div className={`menu__image --${url.slice(1)}`} />
                <div className="menu__text">{text}</div>
                {notifications[url] > 0 && <div className="menu__not_viewed">{notifications[url]}</div>}
              </div>
            )}
          />
        ) : null
      )}
      {[ROLE_ADMIN, ROLE_ROOT].includes(user.role) && (
        <div className="menu__block" onClick={goToAdmin}>
          <div className="menu__text">{intl.formatMessage({ id: 'menu.adminPanel' })}</div>
        </div>
      )}
      <div className="menu__block menu__question" onClick={() => setSupportOpen(true)}>
        <div className="menu__image --question" />
        <div className="menu__text">{intl.formatMessage({ id: 'menu.support' })}</div>
      </div>
      {supportOpen && (
        <ModalWindow onClose={() => setSupportOpen(false)}>
          <Support />
        </ModalWindow>
      )}
      <div className="menu__avatar">
        <Avatar size={36} src={user.photoId} onClick={() => setProfileOpen(true)} />
      </div>
      {profileOpen && (
        <ModalWindow onClose={() => setProfileOpen(false)}>
          <Profile
            user={user}
            logoutUser={async () => {
              // need to disable push-notification token before user logged out
              await Messaging.stopReceivingNotifications();
              setProfileOpen(false);
              logoutUser()(dispatch);
            }}
            onPreferredLanguageChange={lang => setPreferredLanguage(lang)(dispatch)}
          />
        </ModalWindow>
      )}
    </div>
  );
}

export default withRouter(Menu);

// Helpers

function getMenuLocalizedConfig(intl) {
  return [
    {
      url: URL_INBOX,
      text: intl.formatMessage({ id: 'menu.incoming' }),
      roles: [ROLE_INITIATOR, ROLE_APPROVER, ROLE_FINAL_APPROVER],
    },
    {
      url: URL_OUTGOING,
      text: intl.formatMessage({ id: 'menu.outgoing' }),
      roles: [ROLE_INITIATOR, ROLE_APPROVER, ROLE_FINAL_APPROVER],
    },
    {
      url: URL_FAMILIARIZATION,
      text: intl.formatMessage({ id: 'menu.familiarization' }),
      roles: [ROLE_INITIATOR, ROLE_APPROVER],
    },
    {
      url: URL_MEMBERS,
      text: intl.formatMessage({ id: 'menu.members' }),
      roles: [ROLE_FINAL_APPROVER, ROLE_APPROVER, ROLE_INITIATOR, ROLE_ROOT, ROLE_ADMIN],
    },
  ];
}
