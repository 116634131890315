import React, { Fragment, useEffect, useState, useContext, useCallback, useReducer } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { useIntl } from 'react-intl';

import compose from 'lodash/fp/compose';
import curry from 'lodash/fp/curry';
import eq from 'lodash/fp/eq';
import filter from 'lodash/fp/filter';
import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';
import sumBy from 'lodash/fp/sumBy';

import DocumentsHeader from '../../components/documents_header';
import DocumentsList from '../../components/documents_list';
import { ImportRequest } from '../../components/request_creator';
import EmptyListDocuments from '../../components/empty_list_documents';
import LoadMoreItem from '../../components/documents_list/load_more_item';
import NewRequest from '../../containers/new_request';
import NoSelectedDocument from '../../components/no_selected_document';
import RequestView from '../../containers/request_view';
import Searcher from '../../components/searcher';

import http from '../../rest';
import { useData } from '../../rest/client';
import { UserStoreContext } from '../../store/user/store';

import { EXECUTE, FINAL_APPROVE, FINISHED, MAKE_DECISION, REST } from '../../constants/document_list_names';
import { ROLE_APPROVER } from '../../constants/roles';

import { toTuple } from '../../utils/helpers';
import { hasNextPage, reducer, init } from '../../utils/pagination';

import './inbox.scss';

const DOC_LIST_NAME_LABELS = {
  EXECUTE: 'list.incomingRequests.name.label.execute',
  FINAL_APPROVE: 'list.incomingRequests.name.label.finalApprove',
  FINISHED: 'list.incomingRequests.name.label.finished',
  MAKE_DECISION: 'list.incomingRequests.name.label.makeDecision',
  REST: 'list.incomingRequests.name.label.rest',
};

const DOC_LIST_EMPTY_LABELS = {
  EXECUTE: 'list.incomingRequests.empty.label.execute',
  FINAL_APPROVE: 'list.incomingRequests.empty.label.finalApprove',
  MAKE_DECISION: 'list.incomingRequests.empty.label.makeDecision',
};

const START_LOADING = '';

const PACKAGES_PER_PAGE = 20;

function Inbox({ match, location }) {
  const intl = useIntl();
  const alert = useAlert();

  const user = useContext(UserStoreContext);
  const { role } = user;

  const [requestParams, changeRequestParams] = useReducer(reducer, PACKAGES_PER_PAGE, init);

  const [filterComponentProps, setFilterComponentProps] = useState({
    readOnly: false,
    availableStatuses: [],
    selectedStatuses: [],
  });

  const [fixed, setFixed] = useState([]);
  const [paged, setPaged] = useState([]);

  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(START_LOADING);
  const { pathname } = location;

  // Get Data

  const { data: fixedDocumentData, unsubscribe: fixedDocumentDataUnsubscribe } = useData(
    'rpc',
    'post',
    '/getIncomingFixedDocumentPackages',
    // hasn't pagination
    {
      /* FIXME: enable when filters be ready on backend ...requestParams.filter */
    }
  );

  // load data per page for local use only, don't need to listen notifications from other part of app
  const [pagedDocumentData, setPagedDocumentData] = useState([]);
  useEffect(() => {
    http.rpc
      .post('/getIncomingPagedDocumentPackages', {
        /* FIXME: enable when filters be ready on backend ...requestParams.filter, */ ...requestParams.pagination,
      })
      .then(resp => {
        setPagedDocumentData(resp);
      });
  }, [requestParams]);

  // load data for all loaded pages, updates list with new data if someone in app fires notify
  const { data: pagedDocumentAllLoadedData, unsubscribe: pagedDocumentAllLoadedDataUnsubscribe } = useData(
    'rpc',
    'post',
    '/getIncomingPagedDocumentPackages',
    { /* FIXME: enable when filters be ready on backend ...requestParams.filter, */ ...requestParams.paginationAll },
    true
  );

  const toIdTuple = curry(toTuple)('id');

  // Prepare fixed

  useEffect(() => {
    const { data, status } = fixedDocumentData;

    if (isEmpty(data)) {
      return;
    }

    if (status === false || data.code === 500) {
      alert.error(data.message);
    } else {
      const getFixed = compose(
        map(item => ({ ...item, list: new Map(item.list.map(toIdTuple)) })),
        filter(item => [FINAL_APPROVE, MAKE_DECISION, EXECUTE].includes(item.name)),
        getOr([], 'items')
      );

      setFixed(getFixed(data));
    }
    // eslint-disable-next-line
  }, [fixedDocumentData]);

  // Prepare paged

  // ...per page

  useEffect(() => {
    const { data, status } = pagedDocumentData;

    if (isEmpty(data)) {
      return;
    }

    if (status === false || data.code === 500) {
      alert.error(data.message);
    } else {
      if (data && data.list && [REST, FINISHED].includes(data.name)) {
        const paged = {
          ...data,
          list: new Map((data.list || []).map(toIdTuple)),
        };
        setPaged(([oldPaged]) => {
          // if first page response (skip === 0), don't reuse existed data
          if (oldPaged && data.skip > 0) {
            // append new page to others loaded
            paged.list = new Map([...oldPaged.list, ...paged.list]);
          }
          return [paged];
        });
      }
    }
    // eslint-disable-next-line
  }, [pagedDocumentData]);

  // ...for all loaded pages

  useEffect(() => {
    const { data, status } = pagedDocumentAllLoadedData;

    if (isEmpty(data)) {
      return;
    }

    if (status === false || data.code === 500) {
      alert.error(data.message);
    } else {
      if (data && data.list && [REST, FINISHED].includes(data.name)) {
        const paged = {
          ...data,
          skip: requestParams.pagination.skip,
          list: new Map((data.list || []).map(toIdTuple)),
        };
        // refresh existed pages with new data
        setPaged([paged]);
      }
    }
    // eslint-disable-next-line
  }, [pagedDocumentAllLoadedData]);

  // FIXME: This is for fake filter statuses
  // Replace it by a real request
  useEffect(() => {
    const availableStatuses = [...fixed, ...paged]
      .map(list => {
        const statuses = [...list.list.values()].map(item => item.businessStatus);
        return statuses.filter((s, i) => statuses.indexOf(s) === i);
      })
      .flat(1);
    setFilterComponentProps(props => ({
      ...props,
      availableStatuses,
      selectedStatuses: isEmpty(props.selectedStatuses) ? availableStatuses : props.selectedStatuses,
    }));
  }, [fixed, paged]);

  // unsubscribe from data updates
  useEffect(() => {
    return () => {
      fixedDocumentDataUnsubscribe();
      pagedDocumentAllLoadedDataUnsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const onLoadMore = useCallback(
    name => {
      const pagedPackages = paged.find(p => p.name === name);
      if (pagedPackages) {
        changeRequestParams({
          type: 'CALC_NEXT_PAGE',
          payload: {
            total: pagedPackages.total,
            skip: pagedPackages.skip,
            limit: PACKAGES_PER_PAGE,
          },
        });
      }
    },
    [paged]
  );

  // Filter

  const applyFilter = useCallback(
    filter => {
      changeRequestParams({
        type: 'APPLY_FILTER',
        payload: {
          ...requestParams.filter,
          readOnly: filter.readOnly,
          statuses: filter.selectedStatuses,
        },
      });
      setFilterComponentProps(props => ({
        ...props,
        readOnly: filter.readOnly,
        selectedStatuses: filter.selectedStatuses,
      }));
    },
    [requestParams.filter]
  );

  const clearFilter = useCallback(
    filter => {
      changeRequestParams({
        type: 'APPLY_FILTER',
        payload: {
          ...requestParams.filter,
          readOnly: false,
          statuses: [],
        },
      });
      setFilterComponentProps(props => ({
        ...props,
        readOnly: false,
        selectedStatuses: props.availableStatuses,
      }));
    },
    [requestParams.filter]
  );

  // Search

  // FIXME: remove it when filters be ready on backend
  const afterSearch = list => {
    if (searchString.length >= 3) {
      return filter(
        doc =>
          some(t => t.toLowerCase().indexOf(searchString.toLowerCase()) !== -1, [
            doc.id.toString(),
            doc.title,
            doc.title_en,
          ]) ||
          doc.watchers.some(w =>
            some(el => el.toLowerCase().indexOf(searchString.toLowerCase()) !== -1, [
              w.firstName,
              w.lastName,
              w.firstName_en,
              w.lastName_en,
            ])
          ),
        list
      );
    }
    return list;
  };

  // Render

  useEffect(() => {
    if (loading !== pathname) {
      setLoading(pathname);
    }
  }, [pathname, loading]);

  const respectUserActions = map(item => {
    return { ...item, list: afterSearch([...item.list.values()]) };
  });

  const fixedUser = respectUserActions(fixed);
  const pagedUser = respectUserActions(paged);

  const allDocPacks = [...fixedUser, ...pagedUser];

  const isDocPacksListsEmpty = compose(
    eq(0),
    sumBy(item => item.list.length)
  );

  const isDocPacksListsHasNexPages = some(item =>
    hasNextPage({
      total: item.total || 0,
      skip: item.skip || 0,
      limit: PACKAGES_PER_PAGE,
    })
  );

  const isNoDocPacks = isDocPacksListsEmpty(allDocPacks) && !isDocPacksListsHasNexPages(allDocPacks);

  const canCreate = role === ROLE_APPROVER;

  return (
    <div className="inbox">
      <div className="inbox__list data-list">
        <div className="inbox__header">
          <span className="inbox__title">{intl.formatMessage({ id: 'list.incomingRequests' })}</span>
          <ImportRequest />
        </div>
        <div className="inbox__searcher">
          <div className="inbox__searcher__search">
            <Searcher onChange={setSearchString} defaultValue={searchString} />
          </div>
          {/* FIXME: enable when filters be ready on backend
          <div className="inbox__searcher__filter">
            <FilterPopover applyFilter={applyFilter} clearFilter={clearFilter} filterValue={filterComponentProps} />
          </div>
          */}
        </div>
        {isNoDocPacks && <EmptyListDocuments />}
        {!isNoDocPacks && (
          <div className="data-list">
            {allDocPacks.map((item, key) => {
              const hasMore = hasNextPage({
                total: item.total,
                skip: item.skip,
                limit: PACKAGES_PER_PAGE,
              });
              return (
                <Fragment key={`${item.name}-${key}`}>
                  <DocumentsHeader text={intl.formatMessage({ id: DOC_LIST_NAME_LABELS[item.name] })} clean={false} />
                  {item.list.length === 0 && [FINAL_APPROVE, MAKE_DECISION, EXECUTE].includes(item.name) ? (
                    <EmptyListDocuments
                      singleList={true}
                      text={intl.formatMessage({ id: DOC_LIST_EMPTY_LABELS[item.name] })}
                    />
                  ) : (
                    <DocumentsList data={item.list} path={match.path} />
                  )}
                  {hasMore && <LoadMoreItem onLoadMore={() => onLoadMore(item.name)} />}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
      <div className="inbox__view">
        <Switch>
          <Route
            exact
            path={match.path}
            component={props => (
              <NoSelectedDocument
                {...props}
                isLoaded={loading !== START_LOADING}
                isEmpty={isNoDocPacks}
                canCreate={canCreate}
              />
            )}
          />
          <Route exact path={`${match.path}/new`} component={NewRequest} />
          <Route path={`${match.path}/:id`}>
            <RequestView />
            <Route path={`${match.path}/:id/new`} component={NewRequest} />
          </Route>
          <Redirect to={`${match.path}`} />
        </Switch>
      </div>
    </div>
  );
}

export default Inbox;
