import { CYPRUS, FRANCE, MONACO, RUSSIA, UK, USA, SWITZERLAND, SWEDEN } from './phone_codes';

const phoneMasks = {
  [RUSSIA]: '### ###-##-##',
  [FRANCE]: '#-##-##-##-##',
  [UK]: '#### ###-###',
  [USA]: '### ### ####',
  [MONACO]: '##-##-##-##',
  [CYPRUS]: '##-######',
  [SWITZERLAND]: '## ### ## ##',
  [SWEDEN]: '## ### ## ##',
};
export default phoneMasks;
