interface IRpcError {
  code: number;
  message: string;
}

export interface IRpcResponseError {
  status: false;
  data: IRpcError;
}

export interface IRpcResponseSuccess<T> {
  status: true;
  data: T;
}

export type RpcResponse<T> = IRpcResponseSuccess<T> | IRpcResponseError;

interface INetworkError {
  timestamp: number;
  status: number;
  error: string;
  message: string;
  path: string;
}

// Helpers

export type makeRpcRequest = <T>(url: string, params: unknown) => Promise<RpcResponse<T>>;

export function networkErrorToRpcResponseError(error: INetworkError): IRpcResponseError {
  return {
    status: false,
    data: {
      ...error,
      code: error.status,
    },
  };
}

export function isResponseHasError(response: any): response is IRpcResponseError {
  const { data, status } = response;
  // FIXME: specify a better check for data.code parameter
  if (data.code === 500 || status === false) {
    return true;
  }
  return false;
}
