import { IAccountDTO } from './Account';
import { IDocumentDTO } from './Document';

export interface IDecisionDTO {
  id?: number;
  user?: IAccountDTO;
  comment: string;
  comment_en: string;
  status: DecisionStatus;

  businessStatus?: DecisionBusinessStatus;

  createdDate: number | string;

  completedDate?: number | string;

  lastModifiedDate: number | string;

  documents?: IDocumentDTO[];

  removedDocuments?: IDocumentDTO[];

  daysSpent: number;
  hoursSpent: number;
}

export type DecisionStatus =
  /* Ожидает решения*/
  | 'REQUESTED'
  /* Согласовано/Утверждено */
  | 'APPROVED'
  /* Отлколено/Не утверждено */
  | 'REJECTED'
  /* Рассмотрено */
  | 'EXECUTED'
  /* Завершено исполнителем */
  | 'FINISHED'
  /* Отменено */
  | 'CANCELLED'
  /* Пропущен при согласовании (если утверждающий утвердил пакет/поручение, а пользователь был в нем согласующим и еще не дал ответа) */
  | 'SKIPPED'
  /* Ответ не получен */
  | 'EXPIRED';

export type DecisionBusinessStatus =
  /*
   * Не ожиданный статус документа
   * Статус указывает что что-то пошло не так
   */
  | 'UNDEFINED'
  /* На согласовании */
  | 'REQUESTED'
  /* Согласовано */
  | 'APPROVED'
  /* Отклонено */
  | 'REJECTED'
  /* Рассмотрено */
  | 'EXECUTED'
  /* Ожидает моего решения */
  | 'MY_REQUESTED'
  /* Ожидает моего ответа **/
  | 'MY_RESPONSE'
  /* На утверждении */
  | 'FINAL_REQUESTED'
  /* Утверждено */
  | 'FINAL_APPROVED'
  /* Не утверждено */
  | 'FINAL_REJECTED'
  /* На доработке */
  | 'REWORKING'
  /* В работе */
  | 'EXECUTING'
  /* Вернул на доработку */
  | 'RETURNED_FOR_REWORKING'
  /* Ответ подготовлен */
  | 'RESPONSE_PREPARED'
  /* Пропущен при согласовании */
  | 'SKIPPED'
  /* Ответ не получен */
  | 'EXPIRED';

const decisionBusinessStatusMap: { [status in DecisionBusinessStatus | DecisionStatus]: string } = {
  FINISHED: 'decision.businessStatus.FINISHED',
  CANCELLED: 'decision.businessStatus.CANCELLED',
  UNDEFINED: 'decision.businessStatus.UNDEFINED',
  REQUESTED: 'decision.businessStatus.REQUESTED',
  APPROVED: 'decision.businessStatus.APPROVED',
  REJECTED: 'decision.businessStatus.REJECTED',
  EXECUTED: 'decision.businessStatus.EXECUTED',
  MY_REQUESTED: 'decision.businessStatus.MY_REQUESTED',
  MY_RESPONSE: 'decision.businessStatus.MY_RESPONSE',
  FINAL_REQUESTED: 'decision.businessStatus.FINAL_REQUESTED',
  FINAL_APPROVED: 'decision.businessStatus.FINAL_APPROVED',
  FINAL_REJECTED: 'decision.businessStatus.FINAL_REJECTED',
  REWORKING: 'decision.businessStatus.REWORKING',
  EXECUTING: 'decision.businessStatus.EXECUTING',
  RETURNED_FOR_REWORKING: 'decision.businessStatus.RETURNED_FOR_REWORKING',
  RESPONSE_PREPARED: 'decision.businessStatus.RESPONSE_PREPARED',
  SKIPPED: 'decision.businessStatus.SKIPPED',
  EXPIRED: 'decision.businessStatus.EXPIRED',
};

export function businessStatusToLabel(status: DecisionBusinessStatus | DecisionStatus): string {
  return decisionBusinessStatusMap[status];
}
