// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';
// Firebase Cloud Messaging
import 'firebase/messaging';

import { FIREBASE_MESSAGING_PUBLIC_VAPID_KEY, FIREBASE_MESSAGING_SENDER_ID } from '../../constants/messaging';

// Initialize the Firebase app in the service worker by passing in the
// messagingSenderId.
firebase.initializeApp({
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
});

let messaging = {};
const isSupported = firebase.messaging.isSupported();
if (isSupported) {
  // Retrieve Firebase Messaging object.
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(FIREBASE_MESSAGING_PUBLIC_VAPID_KEY);
}

export { messaging, isSupported };
