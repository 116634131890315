import React from 'react';
import PropTypes from 'prop-types';

import avatar from '../../images/avatar.svg';
import multipleAvatar from '../../images/avatar-group.svg';
import './avatar.scss';
import Image from '../image';
import { getFileURL } from '../../utils/get_document_url';

function Avatar({ size, src, onClick, alt, multiple }) {
  const style = {};
  if (size) {
    Object.assign(style, {
      minWidth: `${size}px`,
      minHeight: `${size}px`,
      maxWidth: `${size}px`,
      maxHeight: `${size}px`,
    });
  }

  const classNames = ['avatar'];
  const _onClick = () => {
    onClick();
  };

  let avatarSrc;
  if (src) {
    avatarSrc = getFileURL(src);
  } else if (multiple) {
    avatarSrc = multipleAvatar;
  } else {
    avatarSrc = avatar;
  }

  return (
    <div className={classNames.join(' ')} style={style} onClick={_onClick}>
      <Image src={avatarSrc} errorFile={avatar} alt={alt} />
    </div>
  );
}

Avatar.defaultProps = {
  src: null,
  alt: 'img',
  multiple: false,
  onClick: _ => _,
};
Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
  multiple: PropTypes.bool,
};

export default Avatar;
