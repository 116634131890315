import React from 'react';

import './loader.scss';
import PropTypes from 'prop-types';

function Loader({ size }) {
  const _size = {
    height: size + 'px',
    width: size + 'px',
  };
  return <div className="loader" style={_size} />;
}

Loader.defaultProps = {
  size: 46,
};
Loader.propTypes = {
  size: PropTypes.number,
};

export default Loader;
