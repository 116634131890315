import { createIntl, createIntlCache } from 'react-intl';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const formatBytesToSize = (bytes, locale = 'ru') => {
  const intl = createIntl(
    {
      locale,
      messages: {},
    },
    cache
  );

  const sizes = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte'];

  let value = 0;
  let sizeIndex = 0;

  if (bytes) {
    sizeIndex = Math.floor(Math.log(bytes) / Math.log(1024));
    if (sizeIndex === 0) {
      value = bytes;
    } else {
      value = (bytes / 1024 ** sizeIndex).toFixed(1);
    }
  }

  return intl.formatNumber(value, { style: 'unit', unit: sizes[sizeIndex], unitDisplay: 'short' });
};

export default formatBytesToSize;
