import React from 'react';
import { Link } from 'react-router-dom';

import { useIntl, FormattedHTMLMessage } from 'react-intl';

import './no_match.scss';

const NoMatch = () => {
  const intl = useIntl();
  return (
    <div className="no-match">
      <span className="no-match__title">{intl.formatMessage({ id: 'noMatch.notFound' })}</span>
      <span className="no-match__text">
        <FormattedHTMLMessage
          id="noMatch.checkUrl"
          values={{
            br: () => <br />,
          }}
        />{' '}
      </span>
      <Link to="/agreement" className="no-match__link">
        {intl.formatMessage({ id: 'noMatch.toHomepage' })}
      </Link>
    </div>
  );
};

export default NoMatch;
