import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Avatar from '../avatar';
import Button from '../button';
import MembersSearch from '../members_search';
import ModalWindow from '../modal_window';

import formatUserName from '../../utils/string_utils';

import './watchers-list.scss';

function WatchersList({ watchers, addWatchers }) {
  const intl = useIntl();

  const [watchersModalOpen, setWatchersModalOpen] = useState(false);
  const [newWatchers, setNewWatchers] = useState([]);

  const canAddNewWatcher = typeof addWatchers === 'function';
  const getMembers = members => {
    setNewWatchers(members);
  };
  const _addWatchers = () => {
    addWatchers(watchers.concat(newWatchers));
    setWatchersModalOpen(false);
    setNewWatchers([]);
  };
  const isAdderLastInline = watchers.length % 3 === 2;
  return (
    <div className="watchers-list">
      <div className="watchers-list__header">{intl.formatMessage({ id: 'watchersList.inCopy' })}</div>
      <div className={'watchers-list__list' + (isAdderLastInline ? ' --collapsed' : '')}>
        {watchers.map(watcher => (
          <div key={watcher.id}>
            <div className="watchers-list__watcher">
              <div className="watchers-list__photo">
                <Avatar size={40} src={watcher.photoId} />
              </div>
              {formatUserName(watcher, intl.locale)}
            </div>
          </div>
        ))}
        {canAddNewWatcher && (
          <div>
            <div className="watchers-list__new" onClick={() => setWatchersModalOpen(true)}>
              <div className="watchers-list__plus">+</div>
              {intl.formatMessage({ id: 'watchersList.addMember' })}
            </div>
          </div>
        )}
      </div>
      {watchersModalOpen && (
        <ModalWindow onClose={() => setWatchersModalOpen(false)} className="watchers-list__modal_shift">
          <div className="watchers-list__modal">
            <div className="watchers-list__modal_header">{intl.formatMessage({ id: 'watchersList.addToCC' })}</div>
            <MembersSearch getMembers={getMembers} members={newWatchers} filterMembers={watchers} />
            <Button color="blue" size="small" onClick={_addWatchers}>
              {intl.formatMessage({ id: 'watchersList.addMember' })}
            </Button>
          </div>
        </ModalWindow>
      )}
    </div>
  );
}

WatchersList.propTypes = {
  watchers: PropTypes.array.isRequired,
  addWatchers: PropTypes.func,
};

export default WatchersList;
