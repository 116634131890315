import React from 'react';

import { useIntl } from 'react-intl';

import { DecisionBusinessStatus } from '../../../rest/dto/Decision';

import '../work_label.scss';
import InWork from '../in_work';

interface ILeftToApproveProps {
  leftHoursToApprove: number;
  daysSpent: number;
  hoursSpent: number;
  status: DecisionBusinessStatus;
  type?: string;
}

const LeftToApprove: React.FC<ILeftToApproveProps> = ({
  leftHoursToApprove,
  daysSpent,
  hoursSpent,
  status,
  type
}) => {
  const intl = useIntl();
  
  const completedStatuses: DecisionBusinessStatus[] = [
    'APPROVED',
    'FINAL_APPROVED',
    'EXECUTED',
    'RESPONSE_PREPARED',
    'REJECTED',
    'FINAL_REJECTED',
    'RETURNED_FOR_REWORKING',
    'EXPIRED',
    'SKIPPED',
  ];
  const isStatusCompleted = completedStatuses.includes(status);

  if (isStatusCompleted) {
    if (hoursSpent < 0) { return null; }
    return <InWork daysSpent={daysSpent} hoursSpent={hoursSpent} status={status} type={type}/>;
  }

  if (leftHoursToApprove < 0) { return null; }

  const labelsMap = {
    lessThanHour: intl.formatMessage({ id: 'coordination.leftToApprove.lessThanHour' }),
    left: intl.formatMessage({ id: 'coordination.leftToApprove.left' }),
    leftHoursToApprove: intl.formatMessage({ id: 'coordination.leftToApprove.inHours' }, { hours: leftHoursToApprove }),
  };

  const time = leftHoursToApprove < 1 ? labelsMap.lessThanHour : labelsMap.leftHoursToApprove;
  const label = intl.locale === 'ru' ? `${labelsMap.left} ${time}` : `${time} ${labelsMap.left}`;

  return <div className="work_label">{label}</div>;
};

export default LeftToApprove;
