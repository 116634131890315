import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import CommentsBlock, { isCommentsValid, validateCommentsWith } from '../../components/comments_block';
import DropZone from '../../components/dropzone';
import Input from '../../components/input';
import MembersSearch from '../../components/members_search';
import Button from '../../components/button';

import http from '../../rest';
import { IAccountDTO } from '../../rest/dto/Account';
import { IDocumentDTO } from '../../rest/dto/Document';
import { IOfflineDocumentPackageDTO } from '../../rest/dto/DocumentPackage';
import { makeRpcRequest, networkErrorToRpcResponseError, isResponseHasError } from '../../rest/dto/Response';

import { useNotifyListDataUpdate } from '../../hooks/use_notify_list_data_update';

import { DOCUMENT_TITLE_MAX_LENGTH, DOCUMENT_DESCRIPTION_MAX_LENGTH } from '../../constants/documents';

import isEmpty from 'lodash/fp/isEmpty';
import every from 'lodash/fp/every';
import first from 'lodash/fp/first';

import './new-package.scss';

export function NewPackageSkipApprove() {
  const alert = useAlert();
  const intl = useIntl();

  const history = useHistory();

  const [title, setTitle] = useState('');
  const [titleEn, setTitleEn] = useState('');

  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');

  const [files, setFiles] = useState<IDocumentDTO[]>([]);

  const [initiators, setInitiators] = useState<IAccountDTO[]>([]);
  const [watchers, setWatchers] = useState<IAccountDTO[]>([]);

  const [comment, setComment] = useState('');
  const [commentEn, setCommentEn] = useState('');

  const [sendingData, setSendingData] = useState(false);

  const [errors, setErrors] = useState(emptyFormErrors());

  const notify = useNotifyListDataUpdate();

  const isPristine = isDocumentPackagePristine({
    title,
    title_en: titleEn,
    description,
    description_en: descriptionEn,
    documents: files,
    initiators,
    watchers,
    comment,
    comment_en: commentEn,
  });

  const makeRequest: makeRpcRequest = (url, params) => {
    return http.rpc
      .post(url, params)
      .then(res => {
        if (isResponseHasError(res)) {
          alert.error(res.data.message);
        }
        return Promise.resolve(res);
      })
      .catch(err => {
        alert.error(err.message);
        const rpcRrror = networkErrorToRpcResponseError(err);
        return Promise.resolve(rpcRrror);
      });
  };

  const sendRequest = () => {
    if (!isFormValid(errors) || !isCommentsValid(errors) || isPristine) {
      return;
    }

    setSendingData(true);
    makeRequest(
      '/finalApproveOfflineDocumentPackage',
      dataToDTO({
        title,
        titleEn,
        description,
        descriptionEn,
        files,
        initiators,
        watchers,
        comment,
        commentEn,
      })
    ).then(res => {
      setSendingData(false);
      if (!isResponseHasError(res)) {
        notify();
        history.goBack();
      }
    });
  };

  useEffect(() => {
    if (isPristine) {
      setErrors(emptyFormErrors());
    } else {
      const validateForm = validateFormWith({
        emptyField: intl.formatMessage({ id: 'newPackage.error.emptyField' }),
      });
      const validateComments = validateCommentsWith({
        emptyField: intl.formatMessage({ id: 'input.error.emptyField' }),
      });
      setErrors({
        ...validateForm({ title, titleEn, description, descriptionEn, initiators }),
        ...validateComments({ comment, commentEn, isCommentRequired: false, needMultipleLanguageComments: true }),
      });
    }
  }, [title, titleEn, description, descriptionEn, initiators, comment, commentEn, files, isPristine, intl]);

  return (
    <Fragment>
      <div className="new-package">
        <div className="new-package__view">
          <form className="new-package__view__form" autoComplete="off">
            <div className="new-request-container__form__section_label" />
            <div className="new-package__initiator">
              <MembersSearch
                label={intl.formatMessage({ id: 'newPackage.skipApprove.initiator' })}
                one={true}
                getMembers={setInitiators}
                members={initiators}
                filterMembers={[]}
                error={errors.initiators}
              />
            </div>

            <MembersSearch
              label={intl.formatMessage({ id: 'newPackage.inCopy' })}
              getMembers={setWatchers}
              members={watchers}
            />

            <div className="new-request-container__form__section_label">
              {intl.formatMessage({ id: 'newPackage.section.title' })}
            </div>
            <Input
              placeholder={intl.formatMessage({ id: 'newPackage.section.title.inRussian' })}
              onChange={value => setTitle(value)}
              value={title}
              maxLength={DOCUMENT_TITLE_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.title}
            />
            <Input
              placeholder={intl.formatMessage({ id: 'newPackage.section.title.inEnglish' })}
              onChange={value => setTitleEn(value)}
              value={titleEn}
              maxLength={DOCUMENT_TITLE_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.titleEn}
            />

            <div className="new-request-container__form__section_label">
              {intl.formatMessage({ id: 'newPackage.section.description' })}
            </div>
            <Input
              placeholder={intl.formatMessage({ id: 'newPackage.section.description.inRussian' })}
              onChange={value => setDescription(value)}
              value={description}
              maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.description}
            />
            <Input
              placeholder={intl.formatMessage({ id: 'newPackage.section.description.inEnglish' })}
              onChange={value => setDescriptionEn(value)}
              value={descriptionEn}
              maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.descriptionEn}
            />

            <div className="new-request-container__form__section_label">
              {intl.formatMessage({ id: 'newPackage.skipApprove.finalApproverComment' })}
            </div>
            <CommentsBlock
              type="MULTIPLE"
              defaultComment={comment}
              defaultCommentEn={commentEn}
              onCommentChange={setComment}
              onCommentEnChange={setCommentEn}
              commentError={errors.comment}
              commentEnError={errors.commentEn}
            />

            <DropZone
              title={intl.formatMessage({ id: 'newPackage.skipApprove.documents' })}
              onFileAddExt={fs => setFiles(fs.concat(files))}
              onFileRemove={fileId => setFiles(files.filter((file: any) => file.id !== fileId))}
              initialFiles={files}
              multiple={true}
            />
          </form>
        </div>
      </div>
      <div className="new-request-container__actions">
        <Button color="blue" onClick={sendRequest} loading={sendingData}>
          {intl.formatMessage({ id: 'newPackage.send' })}
        </Button>
      </div>
    </Fragment>
  );
}

// Utils

function emptyFormErrors() {
  return {
    title: '',
    titleEn: '',
    description: '',
    descriptionEn: '',
    initiators: '',
    comment: '',
    commentEn: '',
  };
}

function validateFormWith(errorsText) {
  return ({ title, titleEn, description, descriptionEn, initiators }) => {
    const errors = emptyFormErrors();

    if (isEmpty(title)) {
      errors.title = errorsText.emptyField;
    }

    if (isEmpty(titleEn)) {
      errors.titleEn = errorsText.emptyField;
    }

    if (description.length === 0 && descriptionEn.length !== 0) {
      errors.description = errorsText.emptyField;
    }

    if (description.length !== 0 && descriptionEn.length === 0) {
      errors.descriptionEn = errorsText.emptyField;
    }

    if (isEmpty(initiators)) {
      errors.initiators = errorsText.emptyField;
    }

    return errors;
  };
}

function isFormValid(errors) {
  return every(isEmpty, errors);
}

function isDocumentPackagePristine({
  title,
  title_en,
  description,
  description_en,
  documents,
  initiators,
  watchers,
  comment,
  comment_en,
}) {
  return (
    title === '' &&
    title_en === '' &&
    description === '' &&
    description_en === '' &&
    documents.length === 0 &&
    isEmpty(initiators) &&
    isEmpty(watchers) &&
    comment === '' &&
    comment_en === ''
  );
}

function dataToDTO({
  title,
  titleEn,
  description,
  descriptionEn,
  files,
  initiators,
  watchers,
  comment,
  commentEn,
}): IOfflineDocumentPackageDTO {
  return {
    /* tslint:disable: no-shadowed-variable */
    title,
    title_en: titleEn,
    description,
    description_en: descriptionEn,
    documents: files.map(({ id }) => id),
    initiator: first(initiators.map(({ id }) => id)),
    watchers: watchers.map(({ id }) => id),
    comment,
    comment_en: commentEn,
    /* tslint:enable: no-shadowed-variable */
  };
}
