import React from 'react';

const TimeIcon = ({ color, pushRight = true, children }) => (
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none">
    <path
      d="M9.93042 0.299561H5.18042V1.88289H9.93042V0.299561ZM6.76375 10.5912H8.34709V5.84123H6.76375V10.5912ZM13.1208 5.35039L14.245 4.22623C13.9046 3.82248 13.5325 3.44248 13.1288 3.10998L12.0046 4.23414C10.7775 3.25248 9.23375 2.66664 7.55542 2.66664C3.62084 2.66664 0.43042 5.85706 0.43042 9.79164C0.43042 13.7262 3.61292 16.9166 7.55542 16.9166C11.4979 16.9166 14.6804 13.7262 14.6804 9.79164C14.6804 8.12123 14.0946 6.57748 13.1208 5.35039ZM7.55542 15.3412C4.49167 15.3412 2.01375 12.8633 2.01375 9.79956C2.01375 6.73581 4.49167 4.25789 7.55542 4.25789C10.6192 4.25789 13.0971 6.73581 13.0971 9.79956C13.0971 12.8633 10.6192 15.3412 7.55542 15.3412Z"
      fill="#EC5A40"/>
  </svg>

);

export default TimeIcon;
