import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import { setup } from 'bem-cn';

import Message from './message';
import DropZone from '../dropzone';
import http from '../../rest';
import Input from '../input';

import { useNotifyListDataUpdateWithDocPackage } from '../../hooks/use_notify_list_data_update';

import './discussion.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const MESSAGE_MAX_LENGTH = 4000;
const GET_UPDATES_INTERVAL = 5000; // in milliseconds

function Discussion({ documentPackageId }) {
  const alert = useAlert();
  const intl = useIntl();
  const notify = useNotifyListDataUpdateWithDocPackage(documentPackageId);
  const [posts, setPosts] = useState({});
  const [total, setTotal] = useState(0);
  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);
  const [isDropZoneVisible, setDropZoneVisible] = useState(false);
  const scrollToPostRef = useRef(null);
  const [disableScrollToPost, setDisableScrollToPost] = useState(false);
  const [direction, onScroll] = useScrollDirection();
  const [sending, setSending] = useState(false);

  const getPosts = ({ documentPackageId, skip = 0, limit }) => {
    return http.rpc
      .post('/getPosts', { documentPackageId, skip, limit })
      .then(resp => {
        if (resp.status === false || resp.data.code === 500) {
          throw new Error(resp.data.message);
        }
        return resp.data;
      })
      .catch(err => {
        alert.error(err.message);
      });
  };

  const createPost = ({ documentPackageId, text, documentsIds, skip = 0 }) => {
    return http.rpc
      .post('/createPost', { documentPackageId, text, documentsIds, skip })
      .then(resp => {
        if (resp.status === false || resp.data.code === 500) {
          throw new Error(resp.data.message);
        }
        return resp.data;
      })
      .catch(err => {
        alert.error(err.message);
      });
  };

  const loadAllPosts = useCallback(() => {
    const skip = 0;
    return getPosts({
      documentPackageId,
      skip,
    }).then(data => {
      if (data) {
        setTotal(data.total);
        setPosts(normalizePosts(skip, data.posts || []));
      }
    });
    // eslint-disable-next-line
  }, [documentPackageId]);

  useEffect(() => {
    if (direction === 'up') {
      setDisableScrollToPost(true);
    }
  }, [direction]);

  const scrollToRef = useCallback(
    (ref, force = false) => {
      if (!disableScrollToPost || force) {
        ref.current && ref.current.scrollIntoView();
      }
      scrollToPostRef.current = null;
    },
    [disableScrollToPost]
  );

  useEffect(() => {
    loadAllPosts()
      .then(() => {
        setTimeout(() => {
          scrollToRef(scrollToPostRef);
        });
      })
      .then(() => {
        notify();
      });
    // eslint-disable-next-line
  }, [documentPackageId, scrollToRef]);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      getPosts({
        documentPackageId,
        skip: total,
      }).then(data => {
        if (data && data.posts && data.posts.length > 0) {
          setPosts({ ...posts, ...normalizePosts(total, data.posts || []) });
          setTotal(data.total);
          setTimeout(() => {
            scrollToRef(scrollToPostRef);
          });
        }
      });
    }, GET_UPDATES_INTERVAL);
    return () => clearInterval(timeoutId);
    // eslint-disable-next-line
  }, [documentPackageId, total, posts, scrollToRef]);

  const handleFileAdd = fs => {
    setFiles(files.concat(fs));
  };

  const handleFileRemove = fileId => {
    setFiles(files.filter(({ id }) => id !== fileId));
  };

  const toggleDropZoneVisibility = () => {
    setDropZoneVisible(!isDropZoneVisible);
  };

  const hideDropZoneVisibility = () => {
    setDropZoneVisible(false);
  };

  const sendMessage = useCallback(() => {
    if (sending) {
      return;
    }

    const documentsIds = files.map(({ id }) => id);
    setSending(true);
    createPost({ documentPackageId, text, documentsIds, skip: total })
      .then(data => {
        setSending(false);

        if (data) {
          markAllRead();
          hideDropZoneVisibility();
          setPosts({ ...posts, ...normalizePosts(total, data.posts || []) });
          setText('');
          setFiles([]);
          setTotal(data.total);
          setTimeout(() => {
            scrollToRef(scrollToPostRef, true);
          });
          notify();
        }
      })
      .catch(() => {
        setSending(false);
      });
    // eslint-disable-next-line
  }, [documentPackageId, text, files, sending]);

  const markAllRead = useCallback(() => {
    Object.keys(posts).forEach(key => (posts[key].read = true));
    setPosts({ ...posts });
  }, [posts]);

  const disableSendMessage = (text.length === 0 && files.length === 0) || sending;
  const cn = block('discussion');
  return (
    <div className={cn()}>
      <div className={cn('messages')} onClick={markAllRead} onScroll={onScroll}>
        {total === 0 && (
          <div className={cn('messages', 'no-messages')}>{intl.formatMessage({ id: 'discussion.noMessages' })}</div>
        )}
        {total > 0 &&
          Object.values(posts).map((post, index, array) => {
            if (!post) {
              return <div key={index}>{intl.formatMessage({ id: 'discussion.loading' })}</div>;
            }

            const isFirst = index === 0;
            const prevPost = isFirst ? posts[index] : posts[index - 1];
            const unreadedPosts = !post.read && prevPost.read;
            return (
              <div
                key={index}
                ref={ref => {
                  if (!scrollToPostRef.current && (unreadedPosts || index === array.length - 1)) {
                    scrollToPostRef.current = ref;
                  }
                }}>
                {unreadedPosts && (
                  <div key={'new'} className={cn('messages', 'new-messages')}>
                    {intl.formatMessage({ id: 'discussion.newMessages' })}
                  </div>
                )}
                <Message post={post} decisionStatus={null} />
              </div>
            );
          })}
      </div>
      <div className={cn('form')}>
        <div className={cn('form', 'refresh')} onClick={loadAllPosts} />
        <div className={cn('form', 'message')}>
          <div className={cn('form', 'message', 'toolbox')}>
            <div className={cn('form', 'message', 'toolbox', 'attach')} onClick={toggleDropZoneVisibility} />
          </div>
          <Input
            className={cn('form', 'message', 'toolbox', 'input').toString()}
            placeholder={intl.formatMessage({ id: 'discussion.messageText' })}
            value={text}
            onChange={value => setText(value)}
            onEnter={() => !disableSendMessage && sendMessage()}
            autoFocus={true}
            maxLength={MESSAGE_MAX_LENGTH}
            counter={true}
          />
          <div className={cn('form', 'message', 'toolbox')}>
            <div
              className={cn('form', 'message', 'toolbox', 'send').is({
                disabled: disableSendMessage,
              })}
              onClick={() => !disableSendMessage && sendMessage()}
            />
          </div>
        </div>
        {isDropZoneVisible && (
          <div className={cn('form', 'dropzone')}>
            <DropZone
              title={intl.formatMessage({ id: 'discussion.attachFilesToMessage' })}
              onFileAddExt={files => handleFileAdd(files)}
              onFileRemove={file => handleFileRemove(file)}
              initialFiles={files}
              multiple={true}
              fileListMaxHeight="150px"
            />
          </div>
        )}
      </div>
    </div>
  );
}

function normalizePosts(skip, posts) {
  const normalized = {};
  posts.forEach((p, index) => {
    normalized[skip + index] = p;
  });
  return normalized;
}

export default Discussion;

function useScrollDirection() {
  let lastScroll = useRef(0);
  // none | up | down
  const [direction, setDirection] = useState('none');

  const onScroll = useCallback(event => {
    const scrollTop = event.target.scrollTop;
    const delta = lastScroll.current - scrollTop;
    let direction;
    if (delta === 0) {
      direction = 'none';
    } else if (delta > 0) {
      direction = 'up';
    } else {
      direction = 'down';
    }
    lastScroll.current = scrollTop;

    setDirection(direction);
  }, []);

  return [direction, onScroll];
}
