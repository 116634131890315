import React, { useState, useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import Button from '../../components/button';
import { EditWarningWindow } from '../request_view/warning-windows';

import { IDocumentPackageDTO } from '../../rest/dto/DocumentPackage';

import http from '../../rest';
import { useNotifyListDataUpdateWithDocPackage } from '../../hooks/use_notify_list_data_update';

export interface ITakeForReworkButtonProps {
  documentPackage: IDocumentPackageDTO;
}

const TakeForRework: React.FC<ITakeForReworkButtonProps> = ({ documentPackage }) => {
  const alert = useAlert();
  const intl = useIntl();

  const [showWarning, setShowWarning] = useState(false);
  const [inProgess, setInProgress] = useState(false);

  const notify = useNotifyListDataUpdateWithDocPackage(documentPackage.id);

  const takeForRework = useCallback(() => {
    if (inProgess) {
      return Promise.resolve(makeError(intl.formatMessage({ id: 'takeForRework.requestIsRunning' })));
    }

    setInProgress(true);

    return http.rpc.post('/takeForRework', { id: documentPackage.id }).then(res => {
      const { data, status } = res;
      if (data.code === 500 || status === false) {
        alert.error(data.message);
      } else {
        notify();
      }

      setInProgress(false);
      return res;
    });
    // eslint-disable-next-line
  }, [inProgess, documentPackage.id]);

  const openWarningHandler = () => {
    setShowWarning(true);
  };

  const closeWarningHandler = () => {
    setShowWarning(false);
  };

  return (
    <>
      <Button
        color="blue"
        onClick={() => {
          openWarningHandler();
        }}>
        <div className="request-view__actions_edit">{intl.formatMessage({ id: 'takeForRework.takeForRework' })}</div>
      </Button>

      <EditWarningWindow
        show={showWarning}
        title={intl.formatMessage({ id: 'takeForRework.takingForRework' })}
        onClose={() => {
          closeWarningHandler();
        }}
        onAccept={() => {
          takeForRework();
          closeWarningHandler();
        }}
      />
    </>
  );
};

export default TakeForRework;

// Utils

function makeError(message) {
  return {
    data: {
      message,
    },
    status: false,
  };
}
