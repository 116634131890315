import { createIntl, createIntlCache } from 'react-intl';

import first from 'lodash/fp/first';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const formatDate = (data, withTime = false, locale = 'ru') => {
  if (data) {
    const date = new Date(data * 1000);

    const intl = createIntl(
      {
        locale,
        messages: {},
      },
      cache
    );

    const datePattern = { day: '2-digit', month: 'short', year: 'numeric' };
    const timePattern = { hour: '2-digit', minute: '2-digit' };

    const pattern = withTime ? { ...datePattern, ...timePattern } : datePattern;
    return intl.formatDate(date, pattern);
  }
  return '';
};

export default formatDate;

export function browserLocale(): string {
  const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  return first(locale.split('-')) || 'ru';
}
