export interface IPagionationProps {
  total: number;
  skip: number;
  limit: number;
}

export function hasNextPage({ total, skip, limit }: IPagionationProps): boolean {
  return total - skip > limit;
}

export function calcNextPageParams({ total, skip, limit }: IPagionationProps) {
  return hasNextPage({ total, skip, limit })
    ? {
        skip: skip + limit,
        limit,
      }
    : { skip, limit };
}

export function firstPageParams<T extends IPagionationProps>(limit: T['limit']): Pick<T, 'skip' | 'limit'> {
  return {
    skip: 0,
    limit,
  };
}

type Action = { type: 'CALC_NEXT_PAGE'; payload: IPagionationProps } | { type: 'APPLY_FILTER'; payload: unknown };

interface State {
  pagination: Pick<IPagionationProps, 'skip' | 'limit'>;
  paginationAll: Pick<IPagionationProps, 'skip' | 'limit'>;
  filter: unknown;
}

export function init(perPage = 10) {
  return {
    pagination: {
      skip: 0,
      limit: perPage,
    },
    // used to get all loaded pages for one request
    paginationAll: {
      skip: 0,
      limit: perPage,
    },
    filter: {
      search: '',
      readOnly: false,
      statuses: [],
    },
  };
}

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'CALC_NEXT_PAGE': {
      const next = calcNextPageParams(action.payload);
      return {
        ...state,
        pagination: next,
        paginationAll: {
          skip: 0,
          limit: next.skip + next.limit,
        },
      };
    }
    case 'APPLY_FILTER':
      return {
        ...state,
        pagination: {
          skip: 0,
          limit: state.pagination.limit,
        },
        paginationAll: {
          skip: 0,
          limit: state.pagination.limit,
        },
        filter: action.payload,
      };
  }
}
