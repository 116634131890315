import React, { useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';

import { useIntl } from 'react-intl';

import http from '../../rest';
import { UserStoreContext } from '../../store/user/store';

import './package.scss';

import { URL_INBOX, URL_OUTGOING, URL_FAMILIARIZATION } from '../../constants/urls';

function Package({ match, history }) {
  const intl = useIntl();

  const {
    params: { packageId },
  } = match;
  const alert = useAlert();

  const user = useContext(UserStoreContext);

  useEffect(() => {
    http.rpc.post('/getDocumentPackage', { id: packageId }).then(res => {
      const { data, status } = res;
      if (data.code === 500 || status === false) {
        alert.error(data.message);
        history.push('/');
      } else {
        history.push(`${redirect(user, data)}/${packageId}`);
      }
    });
  }, [packageId, user, history, alert]);

  return (
    <div className="package">
      <span className="package__title">{intl.formatMessage({ id: 'package.redirect' })}</span>
    </div>
  );
}

export default Package;

// Helpers

function redirect(user, docPackage) {
  if (docPackage.createdBy.id === user.id) {
    return URL_OUTGOING;
  } else if (
    docPackage.executors.find(e => e.id === user.id) ||
    (docPackage.finalApprover && docPackage.finalApprover.id === user.id) ||
    docPackage.approvers.find(a => a.id === user.id) ||
    docPackage.decisions.find(d => d.user.id === user.id)
  ) {
    return URL_INBOX;
  } else if (docPackage.watchers.find(a => a.id === user.id)) {
    return URL_FAMILIARIZATION;
  }
}
