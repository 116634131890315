import React, { Fragment, useEffect, useState, useCallback, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import DropZone from '../../components/dropzone';
import Input from '../../components/input';
import MembersSearch from '../../components/members_search';
import Button from '../../components/button';

import http from '../../rest';
import { useNotifyListDataUpdate } from '../../hooks/use_notify_list_data_update';

import { UserStoreContext } from '../../store/user/store';
import formatUserName from '../../utils/string_utils';

import { ROLE_INITIATOR } from '../../constants/roles';
import { DOCUMENT_TITLE_MAX_LENGTH, DOCUMENT_DESCRIPTION_MAX_LENGTH } from '../../constants/documents';
import { ApproveTypeEnum } from '../constants';
import ApproveTypeRadioGroup from '../../components/approve_type_radio_group';

import isEmpty from 'lodash/fp/isEmpty';
import every from 'lodash/fp/every';

import './new-package.scss';

function NewPackage({ history }) {
  const alert = useAlert();
  const intl = useIntl();
  const user = useContext(UserStoreContext);
  const { role } = user;

  const [approvers, setApprovers] = useState('');
  const [finalApprover, setFinalApprover] = useState('');
  const [title, setTitle] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [filesRus, setFilesRus] = useState([]);
  const [filesEng, setFilesEng] = useState([]);
  const [watchers, setWatchers] = useState([]);
  const [sendingData, setSendingData] = useState(false);
  const [approveType, setApproveType] = useState(ApproveTypeEnum.normal);

  const [errors, setErrors] = useState(emptyFormErrors());

  const notify = useNotifyListDataUpdate();

  const onApproveTypeChange = useCallback(event => {
    setApproveType(event.target.value);
  }, []);

  const sendRequest = () => {
    if (!isFormValid(errors) || isPristine()) {
      return;
    }

    setSendingData(true);
    http.rpc
      .post('/sendDraftDocumentPackageForApproval', save())
      .then(res => {
        setSendingData(false);
        const { data, status } = res;
        if (data.code === 500 || status === false) {
          alert.error(data.message);
        } else {
          notify();
          history.goBack();
        }
      })
      .catch(() => {
        setSendingData(false);
      });
  };

  useEffect(() => {
    http.rpc.post('/getDraftDocumentPackage', {}).then(res => {
      const { data, status } = res;
      if (data.code === 500 || status === false) {
        history.goBack();
      } else {
        const {
          title,
          title_en,
          description,
          description_en,
          approvers,
          finalApprover,
          documentsRu,
          documentsEng,
          watchers = [],
        } = data;

        setTitle(title || '');
        setTitleEn(title_en || '');
        setDescription(description || '');
        setDescriptionEn(description_en || '');
        setApprovers(approvers.map(approver => formatUserName(approver, intl.locale)).join(', '));
        setFinalApprover(
          [finalApprover.lastName || '', finalApprover.firstName || '', finalApprover.middleName || ''].join(' ')
        );
        setFilesRus(documentsRu);
        setFilesEng(documentsEng);
        setWatchers(watchers);
        setErrors(emptyFormErrors());
      }
    });
    /* eslint-disable-next-line */
  }, [history]);

  const save = () => {
    const approveProp =
      approveType === ApproveTypeEnum.normal
        ? {}
        : {
            urgentApproval: approveType === ApproveTypeEnum.urgent || undefined,
            skippedApproval: approveType === ApproveTypeEnum.skipped || undefined,
          };

    return {
      title,
      title_en: titleEn,
      description,
      description_en: descriptionEn,
      watchers: watchers.map(({ id }) => id),
      documentsRu: filesRus.map(({ id }) => id),
      documentsEng: filesEng.map(({ id }) => id),
      ...approveProp,
    };
  };

  const isPristine = useCallback(() => {
    return isDocumentPackagePristine({
      title,
      title_en: titleEn,
      description,
      description_en: descriptionEn,
      documentsRu: filesRus,
      documentsEng: filesEng,
      watchers,
    });
  }, [title, titleEn, description, descriptionEn, filesRus, filesEng, watchers]);

  useEffect(() => {
    if (isPristine()) {
      setErrors(emptyFormErrors());
    } else {
      const validateForm = validateFormWith({
        emptyField: intl.formatMessage({ id: 'newPackage.error.emptyField' }),
      });
      setErrors(validateForm({ title, titleEn, description, descriptionEn, filesRus, filesEng, role }));
    }
  }, [title, titleEn, description, descriptionEn, isPristine, filesRus, filesEng, role, intl]);

  const showApprovers = [ROLE_INITIATOR].includes(role) || !!approvers.length;

  return (
    <Fragment>
      <div className="new-package">
        <div className="new-package__view">
          <form className="new-package__view__form" autoComplete="off">
            <div className="new-package-to">
              <div className="new-package-to__approver">{intl.formatMessage({ id: 'newPackage.finalApprover' })}</div>
              <div className="new-package-to__approver__name">{finalApprover}</div>
            </div>
            {showApprovers && (
              <>
                <div className="new-package-to">
                  <div className="new-package-to__approver">{intl.formatMessage({ id: 'newPackage.approvers' })}</div>
                  <div className="new-package-to__approver__name">{approvers}</div>
                </div>
                <div className="new-package__radio-group">
                  <ApproveTypeRadioGroup onChange={onApproveTypeChange} approveType={approveType} />
                </div>
              </>
            )}
            <MembersSearch
              label={intl.formatMessage({ id: 'newPackage.inCopy' })}
              getMembers={setWatchers}
              members={watchers}
            />

            <div className="new-request-container__form__section_label">
              {intl.formatMessage({ id: 'newPackage.section.title' })}
            </div>
            <Input
              placeholder={intl.formatMessage({ id: 'newPackage.section.title.inRussian' })}
              onChange={value => setTitle(value)}
              value={title}
              maxLength={DOCUMENT_TITLE_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.title}
            />
            <Input
              placeholder={intl.formatMessage({ id: 'newPackage.section.title.inEnglish' })}
              onChange={value => setTitleEn(value)}
              value={titleEn}
              maxLength={DOCUMENT_TITLE_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.titleEn}
            />

            <div className="new-request-container__form__section_label">
              {intl.formatMessage({ id: 'newPackage.section.description' })}
            </div>
            <Input
              placeholder={intl.formatMessage({ id: 'newPackage.section.description.inRussian' })}
              onChange={value => setDescription(value)}
              value={description}
              maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.description}
            />
            <Input
              placeholder={intl.formatMessage({ id: 'newPackage.section.description.inEnglish' })}
              onChange={value => setDescriptionEn(value)}
              value={descriptionEn}
              maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.descriptionEn}
            />

            <DropZone
              title={intl.formatMessage({ id: 'newPackage.section.documents.inRussian' })}
              onFileAddExt={files => setFilesRus(filesRus.concat(files))}
              onFileRemove={fileId => setFilesRus(filesRus.filter((file: any) => file.id !== fileId))}
              initialFiles={filesRus}
              multiple={true}
              error={errors.filesRus}
            />
            <DropZone
              title={intl.formatMessage({ id: 'newPackage.section.documents.inEnglish' })}
              onFileAddExt={files => setFilesEng(filesEng.concat(files))}
              onFileRemove={fileId => setFilesEng(filesEng.filter((file: any) => file.id !== fileId))}
              initialFiles={filesEng}
              multiple={true}
              error={errors.filesEng}
            />
          </form>
        </div>
      </div>
      <div className="new-request-container__actions">
        <Button color="blue" onClick={sendRequest} loading={sendingData}>
          {intl.formatMessage({ id: 'newPackage.send' })}
        </Button>
      </div>
    </Fragment>
  );
}

export default withRouter(NewPackage);

// Utils

function emptyFormErrors() {
  return {
    title: '',
    titleEn: '',
    description: '',
    descriptionEn: '',
    filesRus: '',
    filesEng: '',
  };
}

function validateFormWith(errorsText) {
  return ({ title, titleEn, description, descriptionEn, filesRus, filesEng, role }) => {
    const errors = emptyFormErrors();

    if (isEmpty(title)) {
      errors['title'] = errorsText.emptyField;
    }

    if (isEmpty(titleEn)) {
      errors['titleEn'] = errorsText.emptyField;
    }

    if (description.length === 0 && descriptionEn.length !== 0) {
      errors['description'] = errorsText.emptyField;
    }

    if (description.length !== 0 && descriptionEn.length === 0) {
      errors['descriptionEn'] = errorsText.emptyField;
    }

    if (role === ROLE_INITIATOR) {
      if (isEmpty(filesRus) && isEmpty(filesEng)) {
        errors['filesRus'] = errorsText.emptyField;
        errors['filesEng'] = errorsText.emptyField;
      }
    }

    return errors;
  };
}

function isFormValid(errors) {
  return every(isEmpty, errors);
}

function isDocumentPackagePristine({
  title,
  title_en,
  description,
  description_en,
  documentsRu,
  documentsEng,
  watchers,
}) {
  return (
    title === '' &&
    title_en === '' &&
    description === '' &&
    description_en === '' &&
    documentsRu.length === 0 &&
    documentsEng.length === 0 &&
    watchers.length === 0
  );
}
