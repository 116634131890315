import React from 'react';
import PropTypes from 'prop-types';

import uniqueId from '../../utils/unique_id';
import './radio-group.scss';

function RadioGroup({ list, onChange, currentRadioValue }) {
  return (
    <div className="radio-group">
      {list.map(item => {
        const id = uniqueId();
        return (
          <div key={item.value} className="radio-group__item">
            <input
              id={id}
              type="radio"
              name={item.name}
              value={item.value}
              checked={currentRadioValue === item.value}
              onChange={onChange}
            />
            <label className="radio-group__item-label" htmlFor={id}>
              {item.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}

RadioGroup.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]).isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  currentRadioValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default RadioGroup;
