import React, { createContext, useReducer } from 'react';

import * as types from './action_types';
import { AccountLanguage, isAccountLanguage, IAccount } from '../../rest/dto/Account';
import { browserLocale } from '../../utils/date_utils';

const initialState: IAccount = {
  authenticated: undefined,
  preferredLanguage: getSavedPreferredLanguage(),
  firstName: '',
  firstName_en: '',
  lastName: '',
  lastName_en: '',
  phone: '',
  role: 'ROLE_INITIATOR',
  state: 'REMOVED',
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_AUTH:
      return { ...state, authenticated: action.payload };
    case types.AUTH_USER:
      return { ...state, authenticated: true, ...action.payload };
    case types.LOGOUT_USER:
      return {
        ...initialState,
        preferredLanguage: getSavedPreferredLanguage(),
      };
    case types.SET_PREFERRED_LANGUAGE:
      savePreferredLanguage(action.payload);
      return { ...state, preferredLanguage: action.payload };
    default:
      return state;
  }
}

export const UserStoreContext = createContext<IAccount>(initialState);
export const UserDispatchContext = createContext(null);

export function UserProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserDispatchContext.Provider value={dispatch as any}>
      <UserStoreContext.Provider value={state}>{children}</UserStoreContext.Provider>
    </UserDispatchContext.Provider>
  );
}

// Utils

export function getSavedPreferredLanguage(): AccountLanguage {
  // try to get saved language
  if (localStorage) {
    const langFromStorage: string = localStorage.getItem('preferredLanguage') || '';
    if (isAccountLanguage(langFromStorage)) {
      return langFromStorage;
    }
  }

  // otherwise try to detect browser locale
  const locale = browserLocale().toUpperCase();
  if (isAccountLanguage(locale)) {
    return locale;
  }

  // default value
  return 'EN';
}

export function savePreferredLanguage(lang: AccountLanguage) {
  if (localStorage) {
    localStorage.setItem('preferredLanguage', lang);
  }
}
