import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import './no-selected-document.scss';

function NoSelectedDocument({ isLoaded, isEmpty, canCreate }) {
  const intl = useIntl();

  if (isLoaded === false) {
    return null;
  }

  if (isEmpty) {
    if (canCreate) {
      return (
        <div className="no-loaded-documents">
          <div className="no-document-img" />
          <div className="no-document-text">{intl.formatMessage({ id: 'noSelectedDocument.createDocument' })}</div>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="no-selected-document">
      <div className="no-document-img" />
      <div className="no-document-text">{intl.formatMessage({ id: 'noSelectedDocument.selectDocument' })}</div>
    </div>
  );
}

NoSelectedDocument.defaultProps = {
  isLoaded: false,
  isEmpty: true,
  canCreate: false,
};

NoSelectedDocument.propTypes = {
  isLoaded: PropTypes.bool,
  isEmpty: PropTypes.bool,
  canCreate: PropTypes.bool,
};
export default NoSelectedDocument;
