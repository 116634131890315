import React from 'react';
import { setup } from 'bem-cn';
import { useIntl } from 'react-intl';

import { getExtension } from '../../utils/get_extension';
import { getFileURL } from '../../utils/get_document_url';
import formatBytesToSize from '../../utils/file_size_utils';

import './discussion.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

function Document({ document }) {
  const intl = useIntl();
  const open = () => openFile(document.originalFileId);
  const cn = block('documents');
  return (
    <div className={cn('document')}>
      <div
        className={cn('document', 'icon', {
          [getExtension(document.originalFileId)]: true,
        })}
        onClick={open}
      />
      <div className={cn('document', 'filename')} onClick={open}>
        {document.fileName}
      </div>
      <div className={cn('document', 'filesize')}>{formatBytesToSize(document.size, intl.locale)}</div>
      <a className={cn('document', 'download')} href={getFileURL(document.originalFileId)} download>
        {intl.formatMessage({ id: 'discussion.download' })}
      </a>
    </div>
  );
}

const openFile = fileId => {
  if (fileId) window.open(getFileURL(fileId), '_blank');
};

export default Document;
