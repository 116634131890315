import React, { useState, useMemo } from 'react';
import { setup } from 'bem-cn';

import { useIntl } from 'react-intl';

import Input from '../input';

import { DECISION_COMMENT_MAX_LENGTH } from '../../constants/documents';

import every from 'lodash/fp/every';
import isEmpty from 'lodash/fp/isEmpty';
import noope from 'lodash/fp/noop';
import throttle from 'lodash/fp/throttle';

import './comments_block.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const THROTTLE_ON_CHANGE_COMMENT = 400;

interface SingleCommentsProps {
  type: 'SINGLE';
  placeholder: string;
  defaultComment: string;
  onCommentChange: (comment: string) => void;
  commentError?: string;
  autoFocus?: boolean;
}

interface MultipleCommentsProps {
  type: 'MULTIPLE';
  defaultComment: string;
  defaultCommentEn: string;
  onCommentChange: (comment: string) => void;
  onCommentEnChange: (commentEn: string) => void;
  commentError?: string;
  commentEnError?: string;
  autoFocus?: boolean;
}

export type CommentsBlockProps = SingleCommentsProps | MultipleCommentsProps;

function CommentsBlock(props: CommentsBlockProps) {
  const intl = useIntl();

  const [comment, setComment] = useState(props.defaultComment);
  const [commentEn, setCommentEn] = useState(() => {
    return props.type === 'MULTIPLE' ? props.defaultCommentEn : '';
  });

  // eslint-disable-next-line
  const emitCommentChangeMemo = useMemo(() => throttle(THROTTLE_ON_CHANGE_COMMENT, props.onCommentChange), []);
  const emitCommentEnChangeMemo = useMemo(
    () => throttle(THROTTLE_ON_CHANGE_COMMENT, props.type === 'MULTIPLE' ? props.onCommentEnChange : noope),
    // eslint-disable-next-line
    []
  );

  const onChangeComment = (value: string) => {
    setComment(value);
    emitCommentChangeMemo(value);
  };

  const onChangeCommentEn = (value: string) => {
    setCommentEn(value);
    emitCommentEnChangeMemo(value);
  };

  const cn = block('comments-block');

  return (
    <div className={cn()}>
      {props.type === 'SINGLE' ? (
        <>
          <Input
            className={cn('single_block_input').toString()}
            placeholder={props.placeholder}
            onChange={onChangeComment}
            value={comment}
            autoFocus={props.autoFocus}
            maxLength={DECISION_COMMENT_MAX_LENGTH}
            counter={true}
            error={props.commentError}
          />
        </>
      ) : (
        <>
          <div className={cn('multiple_block_label')} />
          <Input
            placeholder={intl.formatMessage({ id: 'input.inRussian' })}
            onChange={onChangeComment}
            value={comment}
            autoFocus={props.autoFocus}
            maxLength={DECISION_COMMENT_MAX_LENGTH}
            counter={true}
            floatingLabel={true}
            error={props.commentError}
          />
          <Input
            placeholder={intl.formatMessage({ id: 'input.inEnglish' })}
            onChange={onChangeCommentEn}
            value={commentEn}
            maxLength={DECISION_COMMENT_MAX_LENGTH}
            counter={true}
            floatingLabel={true}
            error={props.commentEnError}
          />
        </>
      )}
    </div>
  );
}

export default CommentsBlock;

// Validators

interface ICommentsFields {
  comment: string;
  commentEn: string;
  isCommentRequired: boolean;
  needMultipleLanguageComments: boolean;
}

interface ICommentsErrors {
  comment?: string;
  commentEn?: string;
}

export function emptyCommentsErrors(): ICommentsErrors {
  return {
    comment: '',
    commentEn: '',
  };
}

interface IErrorsText {
  emptyField: string;
}
export function validateCommentsWith(errorsText: IErrorsText) {
  return ({
    comment,
    commentEn,
    isCommentRequired,
    needMultipleLanguageComments,
  }: ICommentsFields): ICommentsErrors => {
    const errors = emptyCommentsErrors();

    if (needMultipleLanguageComments) {
      if (!isExist(comment) && isExist(commentEn)) {
        errors.comment = errorsText.emptyField;
      }

      if (isExist(comment) && !isExist(commentEn)) {
        errors.commentEn = errorsText.emptyField;
      }

      if (isCommentRequired) {
        errors.comment = !isExist(comment) ? errorsText.emptyField : '';
        errors.commentEn = !isExist(commentEn) ? errorsText.emptyField : '';
      }
    } else {
      if (isCommentRequired) {
        errors.comment = !isExist(comment) ? errorsText.emptyField : '';
      }
    }

    return errors;
  };
}

export function isCommentsValid(errors) {
  return every(isEmpty, errors);
}

function isExist(value: string): boolean {
  return !isEmpty((value || '').trim());
}
