import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './image.scss';

function Image({ src, alt, errorFile }) {
  const img = useRef(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const image = img.current;
    function onerror() {
      image.src = errorFile;
    }
    function onload() {
      setLoaded(true);
    }
    image.addEventListener('load', onload);
    image.addEventListener('error', onerror);
    image.src = src;

    return function cleanup() {
      image.removeEventListener('load', onload);
      image.removeEventListener('error', onerror);
    };
  }, [img, src, errorFile]);

  const classNames = ['image'];
  if (loaded) {
    classNames.push('--ready');
  }

  return <img src="" alt={alt} ref={img} className={classNames.join(' ')} />;
}

Image.defaultProps = {
  alt: 'img',
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  errorFile: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Image;
