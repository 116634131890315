import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import Button from '../../components/button';
import CommentsBlock, {
  emptyCommentsErrors,
  isCommentsValid,
  validateCommentsWith,
} from '../../components/comments_block';
import DropZone from '../../components/dropzone';
import ModalWindow from '../../components/modal_window';
import { SkippedApproversWarningWindow } from '../request_view/warning-windows';

import { APPROVED, EXECUTED, REJECTED, REWORKING } from '../../constants/statuses';
import { ROLE_INITIATOR, UserRole } from '../../constants/roles';

import http from '../../rest';
import { IDocumentDTO } from '../../rest/dto/Document';
import { useNotifyListDataUpdateWithDocPackage } from '../../hooks/use_notify_list_data_update';
import ApproveTypeRadioGroup from '../../components/approve_type_radio_group';
import { ApproveTypeEnum } from '../constants';

import differenceBy from 'lodash/fp/differenceBy';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';

import './request-task.scss';

function MakeTaskDecision({ show, toApprove, toSkippedApprove, toReject, onClose, documentPackage, user }) {
  const alert = useAlert();
  const intl = useIntl();
  const { id: userId } = user;
  
  const myDecision = documentPackage.decisions.find(({ user: u }) => u.id === userId) || {};
  const [comment, setComment] = useState(myDecision.comment || '');
  const [commentEn, setCommentEn] = useState(myDecision.comment_en || '');
  const [newFiles, setNewFiles] = useState<IDocumentDTO[]>([]);
  const [removedFiles, setRemovedFiles] = useState<IDocumentDTO[]>([]);
  const [sendingData, setSendingData] = useState(false);

  const [showWarning, setShowWarning] = useState(false);
  const [needShowWarning, setNeedShowWarning] = useState(toSkippedApprove);

  const [approveType, setApproveType] = useState(ApproveTypeEnum.normal);

  const [errors, setErrors] = useState(emptyCommentsErrors());

  const notify = useNotifyListDataUpdateWithDocPackage(documentPackage.id);

  const needMultipleLanguageComments =
    user.role === 'ROLE_FINAL_APPROVER' || (user.role === 'ROLE_INITIATOR' && !user.corporateApprover);

  const isCreator = userId === documentPackage.createdBy.id;
  const isExecutor = Boolean(documentPackage.executors.find(e => e.id === userId));
  const isCommentRequired = toReject || isExecutor;
  const isReworkingPackageStatus = documentPackage.status === REWORKING;
  const canChangeApproveType = [ROLE_INITIATOR].includes(user.role) && isExecutor && toApprove && !isReworkingPackageStatus;
  
  useEffect(() => {
    setComment(myDecision.comment || '');
    setCommentEn(myDecision.comment_en || '');
    setNewFiles([]);
    setRemovedFiles([]);
    // eslint-disable-next-line
  }, [documentPackage.id, show]);

  useEffect(() => {
    setNeedShowWarning(toSkippedApprove);
    // eslint-disable-next-line
  }, [documentPackage.id, toSkippedApprove]);

  useEffect(() => {
    if (show && needShowWarning) {
      setShowWarning(true);
    }
  }, [show, needShowWarning]);

  useEffect(() => {
    const validateComments = validateCommentsWith({ emptyField: intl.formatMessage({ id: 'input.error.emptyField' }) });
    setErrors(validateComments({ comment, commentEn, isCommentRequired, needMultipleLanguageComments }));
  }, [comment, commentEn, isCommentRequired, needMultipleLanguageComments, intl]);

  const onApproveTypeChange = useCallback(event => {
    setApproveType(event.target.value);
  }, []);

  const makeRequest = (url: string, params: object): Promise<object> => {
    return http.rpc
      .post(url, params)
      .then(res => {
        const { data, status } = res;
        if (data.code === 500 || status === false) {
          alert.error(data.message);
        }
        return Promise.resolve(res);
      })
      .catch(err => {
        alert.error(err.message);
      });
  };

  const attacheFilesToDecision = useCallback(() => {
    if (isEmpty(newFiles) && isEmpty(removedFiles)) {
      return Promise.resolve();
    }
    return makeRequest('/updateDecision', {
      documentPackageId: documentPackage.id,
      documents: newFiles.map(({ id }) => id),
      removedDocuments: removedFiles.map(({ id }) => id),
    });
    // eslint-disable-next-line
  }, [newFiles, removedFiles, documentPackage]);

  const executeTask = decision => {
    const approveProp =
      approveType === ApproveTypeEnum.normal
        ? {}
        : {
            urgentApproval: approveType === ApproveTypeEnum.urgent || undefined,
            skippedApproval: approveType === ApproveTypeEnum.skipped || undefined,
          };
    return makeRequest('/executeTask', { ...decision, ...approveProp }).then(() => notify());
  };

  const makeDecision = decision => {
    return makeRequest('/makeDecision', decision).then(() => notify());
  };

  const makeFinalApproveWithSkippedApprovers = decision => {
    return makeRequest('/finalApproveWithSkippedApprovers', decision).then(() => notify());
  };

  const sendChanges = async status => {
    if (sendingData || !isCommentsValid(errors)) {
      return;
    }
    setSendingData(true);

    const decision = {
      comment,
      comment_en: commentEn,
      documentPackageId: documentPackage.id,
      status,
    };

    if (isExecutor) {
      await attacheFilesToDecision();
      await executeTask(omit('status', decision));
    } else if (toSkippedApprove) {
      await makeFinalApproveWithSkippedApprovers({
        ...decision,
        documents: newFiles.map(({ id }) => id),
      });
    } else {
      await attacheFilesToDecision();
      await makeDecision(decision);
    }

    setSendingData(false);
    onClose();
  };

  const onApprove = () => {
    sendChanges(isCreator ? EXECUTED : APPROVED);
  };
  const onReject = () => {
    sendChanges(REJECTED);
  };

  const handleFileAdd = useCallback(fs => setNewFiles([...newFiles, ...fs]), [newFiles]);
  const handleFileRemove = useCallback(
    fileId => {
      // remove from recently added files
      setNewFiles(newFiles.filter(({ id }) => id !== fileId));
      // add to removed files if it was previously attached to the decision documents
      const removedFile = find({ id: fileId }, myDecision.documents);
      if (!isEmpty(removedFile)) {
        setRemovedFiles(rf => [...rf, removedFile]);
      }
    },
    [newFiles, myDecision.documents]
  );

  // recently added files and latelly attached without removed
  const files = [...newFiles, ...differenceBy('id', myDecision.documents as IDocumentDTO[], removedFiles)];

  const title = intl.formatMessage({
    id: makeDecisionModalTitle(
      user.role,
      toSkippedApprove ? 'TO_SKIPPED_APPROVE' : toApprove ? 'TO_APPROVE' : 'TO_REJECT'
    ),
  });
  return show ? (
    <>
      <ModalWindow onClose={onClose}>
        <div className="request-view-approve">
          <div className="request-view-approve__header">{title}</div>

          {needMultipleLanguageComments ? (
            <CommentsBlock
              type="MULTIPLE"
              defaultComment={comment}
              defaultCommentEn={commentEn}
              onCommentChange={setComment}
              onCommentEnChange={setCommentEn}
              commentError={errors.comment}
              commentEnError={errors.commentEn}
              autoFocus={true}
            />
          ) : (
            <CommentsBlock
              type="SINGLE"
              placeholder={
                isCommentRequired
                  ? intl.formatMessage({ id: 'makeTaskDecision.addComment.required' })
                  : intl.formatMessage({ id: 'makeTaskDecision.addComment.notRequired' })
              }
              defaultComment={comment}
              onCommentChange={setComment}
              commentError={errors.comment}
              autoFocus={true}
            />
          )}
          {canChangeApproveType && (
            <div>
              <ApproveTypeRadioGroup onChange={onApproveTypeChange} approveType={approveType} />
            </div>
          )}
          <DropZone
            title={intl.formatMessage({ id: 'makeTaskDecision.attachDocuments' })}
            onFileAddExt={handleFileAdd}
            onFileRemove={handleFileRemove}
            initialFiles={files}
            multiple={true}
            fileListMaxHeight="150px"
          />
          <div className="request-view-approve__actions">
            {(toApprove || toSkippedApprove) && (
              <Button color="blue" onClick={onApprove} className="request-view-approve__button" loading={sendingData}>
                {intl.formatMessage({ id: 'makeTaskDecision.button.send' })}
              </Button>
            )}
            {toReject && (
              <Button color="blue" onClick={onReject} className="request-view-approve__button" loading={sendingData}>
                {intl.formatMessage({ id: 'makeTaskDecision.button.send' })}
              </Button>
            )}
          </div>
        </div>
      </ModalWindow>
      <SkippedApproversWarningWindow
        show={showWarning && needShowWarning}
        onClose={() => {
          setShowWarning(false);
          onClose();
        }}
        onAccept={() => {
          setShowWarning(false);
          setNeedShowWarning(false);
        }}
      />
    </>
  ) : null;
}

export default MakeTaskDecision;

type DecisionIntent = 'TO_APPROVE' | 'TO_SKIPPED_APPROVE' | 'TO_REJECT';

function makeDecisionModalTitle(userRole: UserRole, decision: DecisionIntent): string {
  const titles: [UserRole, DecisionIntent, string][] = [
    ['ROLE_INITIATOR', 'TO_APPROVE', 'makeTaskDecision.comment.ROLE_INITIATOR.TO_APPROVE'],
    ['ROLE_INITIATOR', 'TO_REJECT', 'makeTaskDecision.comment.ROLE_APPROVER.TO_REJECT'],
    ['ROLE_APPROVER', 'TO_APPROVE', 'makeTaskDecision.comment.ROLE_APPROVER.TO_APPROVE'],
    ['ROLE_APPROVER', 'TO_REJECT', 'makeTaskDecision.comment.ROLE_APPROVER.TO_REJECT'],
    ['ROLE_FINAL_APPROVER', 'TO_APPROVE', 'makeTaskDecision.comment.ROLE_FINAL_APPROVER.TO_APPROVE'],
    ['ROLE_FINAL_APPROVER', 'TO_SKIPPED_APPROVE', 'makeTaskDecision.comment.ROLE_FINAL_APPROVER.TO_SKIPPED_APPROVE'],
    ['ROLE_FINAL_APPROVER', 'TO_REJECT', 'makeTaskDecision.comment.ROLE_FINAL_APPROVER.TO_REJECT'],
  ];
  return titles.reduce(
    (title, [r, d, t]) => (r === userRole && d === decision ? t : title),
    'makeTaskDecision.comment.default'
  );
}
