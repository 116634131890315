import React from 'react';
import PropTypes from 'prop-types';
import { getFileURL } from '../../utils/get_document_url';

function FileInTab({ doc, className }) {
  const fileId = doc.pdfFileId || doc.originalFileId;
  return (
    <a className={className} href={getFileURL(fileId)} target="_blank" rel="noopener noreferrer">
      {doc.fileName}
    </a>
  );
}

FileInTab.defaultProps = {
  className: '',
};
FileInTab.propTypes = {
  className: PropTypes.string,
  doc: PropTypes.object.isRequired,
};
export default FileInTab;
