import React, { useCallback, useState } from 'react';
import { setup } from 'bem-cn';

import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import * as linkify from 'linkifyjs';
import Linkify from 'linkifyjs/react';
import ticket from 'linkifyjs/plugins/ticket';

import Avatar from '../avatar';
import Document from './document';
import ModalWindow from '../modal_window';
import Profile from '../profile';

import formatDate from '../../utils/date_utils';
import formatUserName from '../../utils/string_utils';
import http from '../../rest';
import { businessStatusToLabel } from '../../rest/dto/Decision';

import './discussion.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

ticket(linkify);
const linkifyOptions = {
  formatHref: {
    ticket: function(href) {
      return '/package/' + href.substring(1);
    },
  },
};

function Message({ post, decisionStatus, style }) {
  const alert = useAlert();
  const intl = useIntl();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [profile, setProfile] = useState(null);

  const getUser = id => {
    return http.rpc
      .post('/getUser', { id })
      .then(resp => {
        if (resp.status === false || resp.data.code === 500) {
          throw new Error(resp.data.message);
        }
        return resp.data;
      })
      .catch(err => {
        alert.error(err.message);
      });
  };

  const showProfile = useCallback(() => {
    if (profile) {
      setUserModalOpen(true);
    } else {
      getUser(post.account.id).then(data => {
        setProfile(data);
        setUserModalOpen(true);
      });
    }
    // eslint-disable-next-line
  }, [post, profile]);

  const message = post.text;

  const cn = block('message');
  return (
    <div className={cn()} style={style}>
      <div className={cn('header')}>
        <div className={cn('user')} onClick={showProfile}>
          <div className={cn('user', 'avatar')}>
            <Avatar size={30} src={post.account.photoId} />
          </div>
          <div className={cn('user', 'username')}>{formatUserName(post.account, intl.locale)}</div>
          {decisionStatus && (
            <div className={cn('user', 'status').is({ [decisionStatus]: true })}>
              {intl.formatMessage({ id: businessStatusToLabel(decisionStatus) }) || ''}
            </div>
          )}
        </div>
        <div className={cn('date')}>{formatDate(post.lastModifiedDate, true, intl.locale)}</div>
      </div>
      <div className={cn('text')}>
        <Linkify options={linkifyOptions}>{message}</Linkify>
      </div>
      <div className={cn('documents')}>
        {post.documents.map(doc => (
          <Document key={doc.id} document={doc} />
        ))}
      </div>
      {userModalOpen && (
        <ModalWindow onClose={() => setUserModalOpen(false)}>
          <Profile user={profile || {}} />
        </ModalWindow>
      )}
    </div>
  );
}

export default Message;
