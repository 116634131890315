import React from 'react';
import { useIntl } from 'react-intl';

import { DocumentPackageBusinessStatus, businessStatusToLabel } from '../../rest/dto/DocumentPackage';
import { UserRole } from '../../rest/dto/User';
import ClockIcon from '../alerts/icons/ClockIcon';

import './label.scss';

interface IDocumentPackageBusinessStatusLabelProps {
  type: 'view' | 'list';
  role: UserRole;
  status: DocumentPackageBusinessStatus;
}
export function DocumentPackageBusinessStatusLabel({ role, type, status }: IDocumentPackageBusinessStatusLabelProps) {
  const intl = useIntl();
  const classNames = ['decision-business-status-label', `--${role}`, `--${status}`, `--${type}`];
  return <div className={classNames.join(' ')}>{intl.formatMessage({ id: businessStatusToLabel(role, status) })}</div>;
}

export function DocumentPackageUrgentApproveLabel({ type }: { type: 'view' | 'list' }) {
  const intl = useIntl();
  const classNames = ['urgent-approve-label', `--${type}`];
  return (
    <div className={classNames.join(' ')}>
      <ClockIcon />
      {type === 'view' && (
        <div className="urgent-approve-label__text">{intl.formatMessage({ id: 'documentPackage.urgentApprove' })}</div>
      )}
    </div>
  );
}
