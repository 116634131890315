import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import CoordinationInitiator from './coordination_initiator';
import CoordinationApprover from './coordination_approver';

import { REQUEST_APPROVEMENT, REQUEST_TASK } from '../../constants/request_types';
import { UserStoreContext } from '../../store/user/store';

import './coordination.scss';

function Coordination({ people, documentPackageId, type, permissions = [], skippedApproval, urgentApproval }) {
  const intl = useIntl();
  const user = useContext(UserStoreContext);
  const { id: userId } = user;

  const header =
    type === REQUEST_APPROVEMENT
      ? intl.formatMessage({ id: 'coordination.approvement' })
      : intl.formatMessage({ id: 'coordination.execution' });
  const initiator = people[0];
  const approvers = people.slice(1);

  return (
    <div className="coordination__levels">
      <CoordinationInitiator
        key={`${documentPackageId}-${initiator.id}`}
        person={initiator}
        skippedApproval={skippedApproval}
        urgentApproval={urgentApproval}
      />
      {approvers.length > 0 && (
        <>
          <div className="coordination__agreement">
            <div className="coordination__agreement_header">{header}</div>
          </div>
          {approvers.map(person => {
            const me = person.id === userId;
            return (
              <CoordinationApprover
                key={`${documentPackageId}_${person.id}`}
                person={person}
                me={me}
                permissions={permissions}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

Coordination.propTypes = {
  people: PropTypes.array.isRequired,
  documentPackageId: PropTypes.number.isRequired,
  type: PropTypes.oneOf([REQUEST_APPROVEMENT, REQUEST_TASK]).isRequired,
  skippedApproval: PropTypes.bool.isRequired,
  urgentApproval: PropTypes.bool.isRequired,
};

export default Coordination;
