import React, { useState } from 'react';
import { setup } from 'bem-cn';

import { AccountLanguage } from '../../rest/dto/Account';

import map from 'lodash/fp/map';
import keys from 'lodash/fp/keys';
import isFunction from 'lodash/fp/isFunction';

import './language_switcher.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

export interface ILanguageSwitcherProps {
  defaultValue: AccountLanguage;
  onChange: (lang: AccountLanguage) => unknown;
  shortLabels: boolean;
}

function LanguageSwitcher({ defaultValue, onChange, shortLabels = false }: ILanguageSwitcherProps) {
  const [choosedLang, setChoosedLang] = useState<AccountLanguage>(defaultValue);

  const onChangeHandler = (lang: AccountLanguage) => (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    evt.preventDefault();
    evt.stopPropagation();

    setChoosedLang(lang);

    if (isFunction(onChange)) {
      onChange(lang);
    }
  };

  const cn = block('language-switcher');
  return (
    <div className={cn()}>
      {map((lang: AccountLanguage) => {
        return (
          <div className={cn('lang').is({ active: choosedLang === lang })} key={lang} onClick={onChangeHandler(lang)}>
            {SupportedLanguages[lang][shortLabels ? 'short' : 'long']}
          </div>
        );
      }, keys(SupportedLanguages))}
    </div>
  );
}

export default LanguageSwitcher;

const SupportedLanguages: { [key in AccountLanguage]: { long: string; short: string } } = {
  RU: { long: 'Русский', short: 'Рус' },
  EN: { long: 'English', short: 'Eng' },
};
