import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useIntl } from 'react-intl';

import http from '../../../rest/http';

import './member_info.scss';
import Avatar from '../../../components/avatar';
import * as phoneCodes from '../../../constants/phone_codes';
import phoneMasks from '../../../constants/phone_masks';
import { formatWithMask } from '../../../utils/mask';
import { fieldIntl } from '../../../utils/helpers';

const MemberInfo = ({ match, history }) => {
  const intl = useIntl();

  let [member, setMember] = useState(null);
  let [mask, setMask] = useState('0');
  let [code, setCode] = useState('0');

  useEffect(() => {
    http.rpc.post('/getUser', { id: match.params.id }).then(res => {
      if (res.data.code === 500) history.push('/members');
      else {
        setMember(res.data);
        for (const code in phoneCodes) {
          if (res.data.phone.indexOf(phoneCodes[code]) === 0) {
            setMask(phoneMasks[phoneCodes[code]]);
            setCode(phoneCodes[code]);
          }
        }
      }
    });
  }, [history, match.params.id]);

  const phone = (member && member.phone && `+${code} ${formatWithMask(member.phone.slice(code.length), mask)}`) || null;
  const notSpecified = intl.formatMessage({ id: 'profile.notSpecified' });
  return (
    member && (
      <div className="member">
        <Avatar size={110} src={member.photoId} />
        <div className="member__text-block">
          <span className="member__text-block__name">
            {fieldIntl(member, 'lastName', intl.locale)} {fieldIntl(member, 'firstName', intl.locale)}
          </span>
          <span className="member__text-block__company">
            {fieldIntl(member, 'company', intl.locale) || notSpecified}
          </span>
          <span className="member__text-block__position">
            {fieldIntl(member, 'position', intl.locale) || notSpecified}
          </span>
          <span className="member__text-block__description">
            {fieldIntl(member, 'description', intl.locale) || notSpecified}
          </span>
          {phone && <span className="member__text-block__label">{intl.formatMessage({ id: 'profile.phone' })}</span>}
          {phone && <span className="member__text-block__phone">{phone}</span>}
          <span className="member__text-block__label">{intl.formatMessage({ id: 'profile.email' })}</span>
          <span className="member__text-block__email">{member.email || notSpecified}</span>
        </div>
      </div>
    )
  );
};

export default withRouter(MemberInfo);
