import React, { useEffect } from 'react';
import { transitions, positions } from 'react-alert';

import { useIntl } from 'react-intl';
import SuccessIcon from './icons/SuccessIcon';
import TimeIcon from './icons/TimeIcon';
import CloseIcon from './icons/CloseIcon';
import ErrorIcon from './icons/ErrorIcon';

import { ALERTS_TIMEOUT } from '../../constants/alerts';
import './alerts.scss';

import sound from './sounds/notification.mp3';

const AlertTemplate = ({ message, options, style, close }) => {
  const intl = useIntl();
  const audio = new Audio(sound);

  useEffect(() => {
    audio.play();
  }, []);

  const onClose = evt => {
    evt.stopPropagation();
    if (typeof close === 'function') {
      close(evt);
    }
  };
  return (
    <div className="alerts" style={{ ...style }} onClick={options.open}>
      {(options.type === 'error' || options.type === 'success') && (
        <>
          {options.type === 'success' && <SuccessIcon />}
          {options.type === 'error' && <ErrorIcon />}
          <span style={{ flex: 2 }}>{message}</span>
          <button onClick={onClose} className="close-button">
            {intl.formatMessage({ id: 'alert.close' })}
          </button>
        </>
      )}
      {options.type === 'info' && (
        <div style={{ width: '100%', paddingBottom: 5, minWidth: 500 }}>
          <div style={{ display: 'inline-block', width: '95%' }}>{message.body}</div>
          <div onClick={onClose} style={{ display: 'inline-block', float: 'right' }}>
            <CloseIcon />
          </div>
          {message?.isUrgentApproval === 'true' && (
            <div style={{ marginTop: 10 }}>
              <div style={{ display: 'inline-block' }}>
                <TimeIcon />
              </div>
              <div className="warn-message">{intl.formatMessage({ id: 'alert.urgentApproval' })}</div>
              <div className="warn-message-time">
                {intl.formatMessage({ id: 'alert.hoursForApproval' }, { hours: message?.hoursForApproval })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const defaultOptions = {
  position: positions.TOP_RIGHT,
  transition: transitions.FADE,
  timeout: ALERTS_TIMEOUT,
  containerStyle: {
    zIndex: 4100,
  },
};

export { AlertTemplate, defaultOptions };
