import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Dots from '../dots';

import './auth-pin-create.scss';

const [COME_UP_WITH_PIN, REPEAT_PIN] = [0, 1];

function AuthPinCreate({ authByPin }) {
  const intl = useIntl();

  const MAX_LENGTH = 4;
  const [pin, setPin] = useState('');
  const [repeatPin, setRepeatPin] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(COME_UP_WITH_PIN);
  const onChangePin = enteredPin => {
    if (step === COME_UP_WITH_PIN) {
      setPin(enteredPin);
      if (enteredPin.length === MAX_LENGTH) {
        setTimeout(() => {
          setStep(REPEAT_PIN);
        }, 250);
      }
    }
    if (step === REPEAT_PIN) {
      setRepeatPin(enteredPin);
      if (enteredPin.length === MAX_LENGTH) {
        const arePinsSame = enteredPin === pin;
        if (arePinsSame) {
          authByPin(enteredPin, error => {
            if (error) {
              setErrorMessage(error.message || intl.formatMessage({ id: 'authPin.error' }));
            }
          });
        } else {
          setErrorMessage(intl.formatMessage({ id: 'authPin.pinNotMatch' }));
        }
      } else if (errorMessage) {
        setErrorMessage('');
      }
    }
  };
  return step === COME_UP_WITH_PIN ? (
    <div className="auth-pin-create">
      <div className="auth-pin-create__header">{intl.formatMessage({ id: 'authPin.createPin' })}</div>
      <div className="auth-pin-create__text">{intl.formatMessage({ id: 'authPin.createPinNote' })}</div>
      <Dots pin={pin} onChangePin={onChangePin} />
      <div className="auth-pin-create__hint">{intl.formatMessage({ id: 'authPin.createPinHint' })}</div>
    </div>
  ) : (
    <div className={'auth-pin-create-repeat' + (errorMessage ? ' --error' : '')}>
      <div className="auth-pin-create-repeat__text">{intl.formatMessage({ id: 'authPin.repeatPin' })}</div>
      <Dots pin={repeatPin} onChangePin={onChangePin} />
      {errorMessage && <div className="auth-pin-create-repeat__error">{errorMessage}</div>}
    </div>
  );
}

AuthPinCreate.propTypes = {
  authByPin: PropTypes.func.isRequired,
};
export default AuthPinCreate;
