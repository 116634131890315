import React from 'react';

function usePortal() {
  const rootElemRef = React.useRef(document.createElement('div'));

  React.useEffect(() => {
    const root = rootElemRef.current;
    const portalRoot = document.querySelector(`#portal-root`);
    portalRoot.appendChild(root);
    return function cleanup() {
      portalRoot.removeChild(root);
    };
  }, []);

  return rootElemRef.current;
}

export default usePortal;
