import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import './empty-list-documents.scss';

const EmptyListDocuments = ({ text, singleList }) => {
  const intl = useIntl();

  const header = intl.formatMessage({ id: 'list.noRequests.header' });
  const defaultText = intl.formatMessage({ id: 'list.noRequests.text' });

  return singleList ? (
    <div className="empty-list-documents --singleList">
      <div className="empty-list-documents__img --singleList" />
      <div className="empty-list-documents__header --singleList">{text || defaultText}</div>
    </div>
  ) : (
    <div className="empty-list-documents">
      <div className="empty-list-documents__img" />
      <div className="empty-list-documents__header">{header}</div>
      <div className="empty-list-documents__text">{text || defaultText}</div>
    </div>
  );
};

EmptyListDocuments.defaultProps = {
  text: '',
  singleList: false,
};

EmptyListDocuments.propTypes = {
  text: PropTypes.string,
  singleList: PropTypes.bool,
};

export default EmptyListDocuments;
