import React from 'react';
import './documents-header.scss';
function DocumentsHeader({ text, clean = false }) {
  return clean ? (
    <div className="documents-header__clean" />
  ) : (
    <div className="documents-header">
      <span className="documents-header__text">{text}</span>
    </div>
  );
}

export default DocumentsHeader;
