import React, { useState, useEffect, useContext } from 'react';

import { useIntl } from 'react-intl';

import Avatar from '../avatar';
import LanguageSwitcher from '../language_switcher';

import { formatWithMask } from '../../utils/mask';
import { fieldIntl } from '../../utils/helpers';

import * as phoneCodes from '../../constants/phone_codes';
import phoneMasks from '../../constants/phone_masks';
import { ROLE_INITIATOR } from '../../constants/roles';

import { UserStoreContext } from '../../store/user/store';

import './profile.scss';

function Profile({ logoutUser, user, onPreferredLanguageChange }) {
  const intl = useIntl();
  const userFromStore = useContext(UserStoreContext);

  let [mask, setMask] = useState('0');
  let [code, setCode] = useState('0');

  useEffect(() => {
    for (const code in phoneCodes) {
      if (user.phone.indexOf(phoneCodes[code]) === 0) {
        setMask(phoneMasks[phoneCodes[code]]);
        setCode(phoneCodes[code]);
      }
    }
  }, [user]);

  const phone =
    (user &&
      (user.id === userFromStore.id || userFromStore.role !== ROLE_INITIATOR) &&
      user.phone &&
      `+${code} ${formatWithMask(user.phone.slice(code.length), mask)}`) ||
    null;

  const notSpecified = intl.formatMessage({ id: 'profile.notSpecified' });

  return (
    <div className="profile">
      <Avatar size={110} src={user.photoId} />
      <div className="profile__text-block">
        <span className="profile__text-block__name">
          {fieldIntl(user, 'lastName', intl.locale)} <br /> {fieldIntl(user, 'firstName', intl.locale)}
        </span>

        <span className="profile__text-block__company">{fieldIntl(user, 'company', intl.locale) || notSpecified}</span>

        <span className="profile__text-block__position">
          {fieldIntl(user, 'position', intl.locale) || notSpecified}
        </span>

        {phone && <span className="profile__text-block__label">{intl.formatMessage({ id: 'profile.phone' })}</span>}
        {phone && <span className="profile__text-block__email">{phone}</span>}

        <span className="profile__text-block__label">{intl.formatMessage({ id: 'profile.email' })}</span>
        <span className="profile__text-block__email">{user.email || notSpecified}</span>

        {user.authenticated && (
          <div className="profile__text-block__preferred-language">
            <span className="profile__text-block__label">{intl.formatMessage({ id: 'profile.chooseLanguage' })}</span>
            <LanguageSwitcher defaultValue={user.preferredLanguage} onChange={onPreferredLanguageChange} />
          </div>
        )}

        {logoutUser && (
          <div className="profile__text-block__logout" onClick={() => logoutUser()}>
            {intl.formatMessage({ id: 'profile.logout' })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
