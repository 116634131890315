import React, { Fragment, useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { UserStoreContext } from '../../store/user/store';

import Button from '../../components/button';
import CoordinationApprover from '../../components/coordination/coordination_approver';
import CoordinationExecutor from '../../components/coordination/coordination_executor';
import CoordinationFinalApprover from '../../components/coordination/coordination_final_approver';
import CoordinationInitiator from '../../components/coordination/coordination_initiator';
import DiscussionPopup from '../../components/discussion/popup';
import { DocumentPackageBusinessStatusLabel, DocumentPackageUrgentApproveLabel } from '../../components/label';

import ThreeDotsMenu from '../../components/three_dots_menu';

import MakeTaskDecision from './make-task-decision';
import UpdateDecision from '../request_view/update-decision';
import UpdateRequest from '../request_view/update-request';
import TakeForRework from '../request_view/take-for-rework';

import { REQUEST_TASK } from '../../constants/request_types';
import {
  UPDATE_DESCRIPTION,
  UPDATE_MY_DECISION_COMMENT,
  VIEW_CHAT,
  TAKE_FOR_REWORK,
  FINAL_APPROVE_WITH_SKIPPED_APPROVERS,
} from '../../constants/permissions';
import { MY_APPROVING, MY_RESPONSE, REQUESTED } from '../../constants/statuses';

import './request-task.scss';

// Поручение - запрос, который создает утверждающий

function RequestTask({ info, history, match }) {
  const intl = useIntl();
  const user = useContext(UserStoreContext);
  const { id: userId, preferredLanguage, role } = user;

  const myDecision = info.decisions.find(({ user }) => user.id === userId) || {};

  const [toApprove, setToApprove] = useState(false);
  const [toSkippedApprove, setToSkippedApprove] = useState(false);
  const [toReject, setToReject] = useState(false);

  const [status, setStatus] = useState('');

  const [chatOpen, setChatOpen] = useState(false);
  const [updateDecisionModalOpen, setUpdateDecisionModalOpen] = useState(false);
  const [updateRequestModalOpen, setUpdateRequestModalOpen] = useState(false);
  const [displayedLanguage, setDisplayedLanguage] = useState(preferredLanguage);

  const isCreator = userId === info.createdBy.id;
  const isExecutor = Boolean(info.executors.find(e => e.id === userId));
  const isApprover = !isExecutor && !isCreator && info.businessStatus === MY_APPROVING;

  useEffect(() => {
    const newStatus = info.businessStatus;
    if (newStatus !== status) {
      setStatus(newStatus);
    }
  }, [status, info]);

  useEffect(() => {
    if (match.params.id !== info.id) {
      setToApprove(false);
      setToSkippedApprove(false);
      setToReject(false);
      setChatOpen(false);
      setDisplayedLanguage(preferredLanguage);
    }
    // eslint-disable-next-line
  }, [info.id, match.params.id]);

  useEffect(() => {
    setDisplayedLanguage(preferredLanguage);
  }, [preferredLanguage]);

  if (status === '') {
    return null;
  }

  const creator = {
    ...info.createdBy,
    createdDate: info.createdDate,
    lastStartedDate: info.lastStartedDate,
    comment: displayedLanguage === 'RU' ? info.description : info.description_en,
    documents: {
      ru: info.documentsRu,
      eng: info.documentsEng,
    },
    removedDocuments: info.removedDocuments || [],
  };

  const executors = info.decisions
    .filter(({ user }) => info.executors.map(({ id }) => id).includes(user.id))
    .map(decision => {
      if (decision.user.id === userId) {
        return Object.assign({}, decision, decision.user, {
          comment: displayedLanguage === 'RU' ? decision.comment : decision.comment_en,
          date: decision.lastModifiedDate,
          status: decision.businessStatus,
        });
      }
      return Object.assign({}, decision, decision.user, {
        comment: displayedLanguage === 'RU' ? decision.comment : decision.comment_en,
        date: decision.lastModifiedDate,
        status: decision.businessStatus,
      });
    });

  const inputApprovers = info.decisions.filter(
    ({ user }) => ![...info.executors.map(({ id }) => id), info.createdBy.id].includes(user.id)
  );

  const approvers = (function(decisions) {
    return decisions.map(el =>
      Object.assign({}, el, el.user, {
        date: el.lastModifiedDate,
        status: el.businessStatus,
      })
    );
  })((inputApprovers.length && inputApprovers) || info.approvers);

  const finalApprover = (function(el = {}) {
    return Object.assign({}, el, info.createdBy, {
      comment: displayedLanguage === 'RU' ? el.comment : el.comment_en,
      date: el.lastModifiedDate,
      status: el.businessStatus,
    });
  })(info.decisions.find(({ user }) => user.id === info.createdBy.id));

  const finalButtonText =
    (isCreator && intl.formatMessage({ id: 'requestTask.button.closeRequest' })) ||
    (isApprover && intl.formatMessage({ id: 'requestTask.button.approve' })) ||
    intl.formatMessage({ id: 'requestTask.button.sendReply' });

  const changeLanguage = {
    key: 'change-language',
    text:
      displayedLanguage === 'RU'
        ? intl.formatMessage({ id: 'requestTask.showTitleDescriptionInEnglish' })
        : intl.formatMessage({ id: 'requestTask.showTitleDescriptionInRussian' }),
  };
  const dotsMenu = [changeLanguage];

  const handleChangeLang = () => {
    const lang = displayedLanguage === 'RU' ? 'EN' : 'RU';
    setDisplayedLanguage(lang);
  };

  const permissions = info.permissions || [];
  const canUpdateMyDecisionComment =
    permissions.includes(UPDATE_MY_DECISION_COMMENT) && info.businessStatus !== MY_RESPONSE;
  const canUpdateDescription = permissions.includes(UPDATE_DESCRIPTION);
  const canViewChat = permissions.includes(VIEW_CHAT);
  const canTakeForRework = permissions.includes(TAKE_FOR_REWORK);
  const canApproveWithSkippedApprovers = permissions.includes(FINAL_APPROVE_WITH_SKIPPED_APPROVERS);
  const canMakeDecision = myDecision.status === REQUESTED;

  const areActionsVisible =
    canMakeDecision ||
    canUpdateDescription ||
    canUpdateMyDecisionComment ||
    canViewChat ||
    canTakeForRework ||
    canApproveWithSkippedApprovers;

  const displayedTitle = displayedLanguage === 'RU' ? info.title : info.title_en;

  return (
    <Fragment>
      <div className="request-task">
        <div className="request-task__id">
          <div className="request-task__id__label">
            <div>{intl.formatMessage({ id: 'requestTask.requestNumber' }, { number: info.id })}</div>
          </div>
        </div>
        <div className="request-task__header">
          <div className="request-task__title">{displayedTitle || intl.formatMessage({ id: 'requestTask.task' })}</div>
          <div className="request-task__id__lang-switcher">
            <ThreeDotsMenu options={dotsMenu} onSelect={() => handleChangeLang()} small={true} />
          </div>
        </div>
        <div className="request-task__statuses">
          <div className="request-task__status">
            <DocumentPackageBusinessStatusLabel role={role} type="view" status={status} />
          </div>
          {info.urgentApproval && <DocumentPackageUrgentApproveLabel type="view" />}
        </div>
        <CoordinationInitiator
          key={`${info.id}-${creator.id}`}
          person={creator}
          urgentApproval={false}
          skippedApproval={false}
        />
        <div className="request-task__subheader">{intl.formatMessage({ id: 'requestTask.section.execution' })}</div>
        {executors.map(executor => {
          const me = executor.id === userId;
          return (
            <CoordinationExecutor
              key={`${executor.id}-${executor.lastModifiedDate}`}
              person={executor}
              me={me}
              permissions={permissions}
              urgentApproval={info.urgentApproval}
              skippedApproval={info.skippedApproval}
            />
          );
        })}
        {approvers.length > 0 && (
          <div className="request-task__agreement">
            <div className="request-task__subheader">
              {intl.formatMessage({ id: 'requestTask.section.approvement' })}
            </div>
            {approvers.map(approver => {
              const me = approver.id === userId;
              return (
                <CoordinationApprover
                  key={`${approver.id}-${approver.lastModifiedDate}`}
                  person={approver}
                  me={me}
                  permissions={permissions}
                />
              );
            })}
          </div>
        )}
        <div className="request-task__agreement">
          <div className="request-task__subheader">
            {intl.formatMessage({ id: 'requestTask.section.finalApprovement' })}
          </div>
          <CoordinationFinalApprover
            key={`${finalApprover.id}-${finalApprover.lastModifiedDate}`}
            person={finalApprover}
            me={isCreator}
            permissions={permissions}
          />
        </div>
      </div>
      {areActionsVisible && (
        <div className="request-view__actions">
          {canUpdateDescription && (
            <Button color="blue" onClick={() => setUpdateRequestModalOpen(true)}>
              <div className="request-view__actions_edit">
                {intl.formatMessage({ id: 'requestTask.button.editRequest' })}
              </div>
            </Button>
          )}
          {canTakeForRework && <TakeForRework documentPackage={info || {}} />}
          {canUpdateMyDecisionComment && (
            <Button color="blue" onClick={() => setUpdateDecisionModalOpen(true)}>
              {isExecutor ? (
                <div className="request-view__actions_edit">
                  {intl.formatMessage({ id: 'requestTask.button.editReply' })}
                </div>
              ) : (
                <div className="request-view__actions_edit">
                  {intl.formatMessage({ id: 'requestTask.button.changeDecision' })}
                </div>
              )}
            </Button>
          )}
          {canMakeDecision && (
            <Button color="blue" onClick={() => setToApprove(true)}>
              <div className="request-view__actions_approve">{finalButtonText}</div>
            </Button>
          )}
          {canApproveWithSkippedApprovers && (
            <Button color="blue" onClick={() => setToSkippedApprove(true)}>
              <div className="request-view__actions_edit">
                {intl.formatMessage({ id: 'requestTask.button.approveWithoutCoordination' })}
              </div>
            </Button>
          )}
          {canViewChat && (
            <div className="request-view__actions_chat">
              <Button
                color="blue"
                onClick={() => {
                  setChatOpen(true);
                }}>
                <div className="request-view__actions_chat__text">
                  <div>
                    {intl.formatMessage({ id: 'requestTask.button.discuss' })}
                    {info.totalPostsCount > 0 ? ` (${info.totalPostsCount})` : ''}
                  </div>
                </div>
                {info.unreadPostsCount > 0 && (
                  <div className="request-view__actions_chat__amount">{info.unreadPostsCount}</div>
                )}
              </Button>
            </div>
          )}
          {canMakeDecision && isApprover && (
            <Button color="blue" onClick={() => setToReject(true)}>
              <div className="request-view__actions_reject">
                {intl.formatMessage({ id: 'requestTask.button.reject' })}
              </div>
            </Button>
          )}
        </div>
      )}
      {chatOpen && (
        <DiscussionPopup style={{ bottom: 78 }} documentPackageId={info.id} onClose={() => setChatOpen(false)} />
      )}

      <MakeTaskDecision
        key={`${info.id}-${info.lastModifiedDate}-make-decision`}
        show={toApprove || toSkippedApprove || toReject}
        toApprove={toApprove}
        toSkippedApprove={toSkippedApprove}
        toReject={toReject}
        onClose={() => {
          setToApprove(false);
          setToSkippedApprove(false);
          setToReject(false);
        }}
        documentPackage={info}
        user={user}
      />

      <UpdateDecision
        key={`${info.id}-${info.lastModifiedDate}-update-decision`}
        title={
          isExecutor
            ? intl.formatMessage({ id: 'requestTask.button.editReply' })
            : intl.formatMessage({ id: 'requestTask.button.changeDecision' })
        }
        show={updateDecisionModalOpen}
        onClose={() => setUpdateDecisionModalOpen(false)}
        documentPackage={info}
        user={user}
      />

      <UpdateRequest
        type={REQUEST_TASK}
        title={intl.formatMessage({ id: 'requestTask.button.editRequest' })}
        show={updateRequestModalOpen}
        onClose={() => setUpdateRequestModalOpen(false)}
        documentPackageId={info.id}
        documentPackage={info || {}}
      />
    </Fragment>
  );
}

export default withRouter(RequestTask);
