import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import throttle from 'lodash/fp/throttle';

import './searcher.scss';

const THROTTLE_ON_CHANGE = 400;

function Searcher({ onChange, defaultValue, onEnter }) {
  const intl = useIntl();

  const [searchString, setSearchString] = useState(defaultValue || '');

  const _onKeyDown = e => {
    if (e.which === 13) {
      onEnter();
    }
  };

  // eslint-disable-next-line
  const emitChangeSearchMemo = useMemo(() => throttle(THROTTLE_ON_CHANGE, onChange), []);

  const _onChange = event => {
    const s = event.target.value;
    setSearchString(s);
    emitChangeSearchMemo(s);
  };

  return (
    <div className="searcher">
      <input
        type="text"
        placeholder={intl.formatMessage({ id: 'search.placeholder' })}
        onKeyDown={_onKeyDown}
        value={searchString}
        onChange={_onChange}
      />
    </div>
  );
}

Searcher.defaultProps = {
  defaultValue: '',
  onChange: () => {},
  onEnter: () => {},
};
Searcher.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
};

export default Searcher;
