import { UserRole } from './User';

export interface IAccountDTO {
  id?: number;
  phone: string;
  email?: string;
  corporateApprover?: boolean;

  // RU

  firstName: string;
  lastName: string;
  middleName?: string;
  company?: string;
  position?: string;
  description?: string;

  // EN

  firstName_en: string;
  lastName_en: string;
  middleName_en?: string;
  company_en?: string;
  position_en?: string;
  description_en?: string;

  role: UserRole;
  state: AccountState;
  photoId?: string;
  signId?: string;
}

export type AccountState = 'ACTIVE' | 'REMOVED';

export interface IAccount extends IAccountDTO {
  authenticated?: boolean;
  preferredLanguage: AccountLanguage;
}

export type AccountLanguage = 'EN' | 'RU';

export function isAccountLanguage(lang: any): lang is AccountLanguage {
  return ['EN', 'RU'].includes(lang);
}
