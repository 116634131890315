import React, { Fragment, useCallback, useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import Button from '../../components/button';
import CommentsBlock, {
  emptyCommentsErrors,
  isCommentsValid,
  validateCommentsWith,
} from '../../components/comments_block';
import Coordination from '../../components/coordination';
import CoordinationFinalApprover from '../../components/coordination/coordination_final_approver';
import DiscussionPopup from '../../components/discussion/popup';
import DropZone from '../../components/dropzone';
import { DocumentPackageBusinessStatusLabel, DocumentPackageUrgentApproveLabel } from '../../components/label';
import ModalWindow from '../../components/modal_window';

import ThreeDotsMenu from '../../components/three_dots_menu';

import UpdateDecision from '../request_view/update-decision';
import UpdateRequest from '../request_view/update-request';
import TakeForRework from '../request_view/take-for-rework';

import http from '../../rest';
import { IAccount } from '../../rest/dto/Account';
import { IDocumentDTO } from '../../rest/dto/Document';
import { DocumentPackageBusinessStatus } from '../../rest/dto/DocumentPackage';
import { UserStoreContext } from '../../store/user/store';

import { REQUEST_APPROVEMENT } from '../../constants/request_types';
import {
  UPDATE_DESCRIPTION,
  UPDATE_MY_DECISION_COMMENT,
  VIEW_CHAT,
  TAKE_FOR_REWORK,
} from '../../constants/permissions';
import { ROLE_INITIATOR, ROLE_APPROVER, ROLE_FINAL_APPROVER, UserRole } from '../../constants/roles';
import { APPROVED, REJECTED, REQUESTED, MY_RESPONSE, REWORKING } from '../../constants/statuses';

import { useNotifyListDataUpdate } from '../../hooks/use_notify_list_data_update';

import differenceBy from 'lodash/fp/differenceBy';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import reject from 'lodash/fp/reject';

import './request-package.scss';

// Пакет документов - создает инициатор, согласующий или согласующий с доп.согласующими

function RequestPackage({ info, history, match }) {
  const user: IAccount = useContext(UserStoreContext);
  const { role, id: userId, preferredLanguage } = user;

  const myDecision = info.decisions.find(({ user: u }) => u.id === userId) || {};
  const needMultipleLanguageComments =
    user.role === 'ROLE_FINAL_APPROVER' || (user.role === 'ROLE_INITIATOR' && !user.corporateApprover);

  const alert = useAlert();
  const intl = useIntl();
  const [toApprove, setToApprove] = useState(false);
  const [toReject, setToReject] = useState(false);
  const [comment, setComment] = useState(myDecision.comment || '');
  const [commentEn, setCommentEn] = useState(myDecision.comment_en || '');
  const [newFiles, setNewFiles] = useState<IDocumentDTO[]>([]);
  const [removedFiles, setRemovedFiles] = useState<IDocumentDTO[]>([]);
  const [status, setStatus] = useState<DocumentPackageBusinessStatus>('UNDEFINED');
  const [sendingData, setSendingData] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [updateDecisionModalOpen, setUpdateDecisionModalOpen] = useState(false);
  const [updateRequestModalOpen, setUpdateRequestModalOpen] = useState(false);
  const [displayedLanguage, setDisplayedLanguage] = useState(preferredLanguage);
  const [errors, setErrors] = useState(emptyCommentsErrors());

  const notify = useNotifyListDataUpdate();

  useEffect(() => {
    const newStatus = info.businessStatus;
    if (newStatus !== status) {
      setStatus(newStatus);
    }
  }, [status, info]);

  useEffect(() => {
    if (match.params.id !== info.id) {
      setNewFiles([]);
      setRemovedFiles([]);
      setComment(myDecision.comment || '');
      setCommentEn(myDecision.comment_en || '');
      setChatOpen(false);
      setDisplayedLanguage(preferredLanguage);
    }
    // eslint-disable-next-line
  }, [info.id, match.params.id]);

  useEffect(() => {
    setDisplayedLanguage(preferredLanguage);
  }, [preferredLanguage]);

  useEffect(() => {
    const validateComments = validateCommentsWith({ emptyField: intl.formatMessage({ id: 'input.error.emptyField' }) });
    setErrors(validateComments({ comment, commentEn, isCommentRequired: !toApprove, needMultipleLanguageComments }));
  }, [comment, commentEn, toApprove, needMultipleLanguageComments, intl]);

  const addAttachedFiles = useCallback(() => {
    if (isEmpty(newFiles) && isEmpty(removedFiles)) {
      return Promise.resolve();
    }

    return http.rpc
      .post('/updateDecision', {
        documentPackageId: info.id,
        documents: newFiles.map(({ id }) => id),
        removedDocuments: removedFiles.map(({ id }) => id),
      })
      .then(res => {
        const { data, status: resStatus } = res;
        if (data.code === 500 || resStatus === false) {
          alert.error(data.message);
        }
      })
      .catch(err => {
        alert.error(err.message);
      });
    // eslint-disable-next-line
  }, [newFiles, removedFiles, info]);

  const handleFileAdd = useCallback(
    fs => {
      setNewFiles([...newFiles, ...fs]);
    },
    [newFiles]
  );

  const handleFileRemove = useCallback(
    fileId => {
      // remove from recently added files
      setNewFiles(reject({ id: fileId }, newFiles));

      // add to removed files if it was previously attached to the decision documents
      const removedFile = find({ id: fileId }, myDecision.documents);
      if (!isEmpty(removedFile)) {
        setRemovedFiles(rf => [...rf, removedFile]);
      }
    },
    [newFiles, myDecision.documents]
  );

  const mapCoordinationUsers = () => {
    return [
      {
        ...info.createdBy,
        createdDate: info.createdDate,
        lastStartedDate: info.lastStartedDate,
        comment: displayedLanguage === 'RU' ? info.description : info.description_en,
        documents: {
          ru: info.documentsRu,
          eng: info.documentsEng,
        },
        removedDocuments: info.removedDocuments || [],
      },
      ...info.decisions
        .filter(({ user: u }) => u.role === ROLE_APPROVER || (u.role === ROLE_INITIATOR && u.corporateApprover))
        .map(el => ({ ...el, ...el.user, date: el.lastModifiedDate, status: el.businessStatus })),
    ];
  };

  const coordinationFinalApprover = {
    ...info.finalApprover,
    ...(() => {
      const decision = info.decisions.find(({ user: u }) => u.id === info.finalApprover.id);
      return (
        decision && {
          ...decision,
          comment: displayedLanguage === 'RU' ? decision.comment : decision.comment_en,
          date: decision.lastModifiedDate,
          status: decision.businessStatus,
        }
      );
    })(),
  };

  const startReject = () => {
    setToReject(true);
  };
  const startApprove = () => {
    setToApprove(true);
  };
  const finish = async () => {
    if (sendingData) {
      return;
    }

    if (isCommentsValid(errors)) {
      setSendingData(true);

      await addAttachedFiles();
      await makeDecision({
        status: (toApprove && APPROVED) || REJECTED,
        comment,
        comment_en: commentEn,
        documentPackageId: match.params.id,
      });

      setSendingData(false);
      resetDescision();

      setTimeout(() => {
        history.push('/inbox');
      });
    }
  };

  const resetDescision = () => {
    setToApprove(false);
    setToReject(false);
    setComment(myDecision.comment || '');
    setCommentEn(myDecision.comment_en || '');
    setNewFiles([]);
    setRemovedFiles([]);
  };

  const makeDecision = decision => {
    return http.rpc
      .post('/makeDecision', decision)
      .then(res => {
        const { data, status: resStatus } = res;
        if (data.code === 500 || resStatus === false) {
          alert.error(data.message);
        } else {
          notify();
        }
      })
      .catch(err => {
        alert.error(err.message);
      });
  };

  const changeLanguage = {
    key: 'change-language',
    text:
      displayedLanguage === 'RU'
        ? intl.formatMessage({ id: 'requestPackage.showTitleDescriptionInEnglish' })
        : intl.formatMessage({ id: 'requestPackage.showTitleDescriptionInRussian' }),
  };
  const dotsMenu = [changeLanguage];

  const handleChangeLang = () => {
    const lang = displayedLanguage === 'RU' ? 'EN' : 'RU';
    setDisplayedLanguage(lang);
  };

  const text =
    (role === ROLE_FINAL_APPROVER && intl.formatMessage({ id: 'requestPackage.button.finalApprove' })) ||
    intl.formatMessage({ id: 'requestPackage.button.approve' });
  const modalTitle = intl.formatMessage({
    id: makeDecisionModalTitle(user.role, toApprove ? 'TO_APPROVE' : 'TO_REJECT'),
  });

  if (status === 'UNDEFINED') {
    return null;
  }

  const toFinish = toApprove || toReject;

  const permissions = info.permissions || [];
  const canUpdateMyDecisionComment =
    permissions.includes(UPDATE_MY_DECISION_COMMENT) && info.businessStatus !== MY_RESPONSE;
  const canUpdateDescription = permissions.includes(UPDATE_DESCRIPTION);
  const canViewChat = permissions.includes(VIEW_CHAT);
  const canTakeForRework = permissions.includes(TAKE_FOR_REWORK);
  const canMakeDecision = myDecision.status === REQUESTED;

  const areActionsVisible =
    canMakeDecision || canUpdateDescription || canUpdateMyDecisionComment || canViewChat || canTakeForRework;

  // recently added files and latelly attached without removed
  const files = [...newFiles, ...differenceBy('id', myDecision.documents as IDocumentDTO[], removedFiles)];

  const displayedTitle = displayedLanguage === 'RU' ? info.title : info.title_en;

  return (
    <Fragment>
      <div className="request-package">
        <div className="request-package__id">
          <div className="request-package__id__label">
            <div>{intl.formatMessage({ id: 'requestPackage.requestNumber' }, { number: info.id })}</div>
          </div>
        </div>
        <div className="request-package__header">
          <div className="request-package__title">
            {displayedTitle || intl.formatMessage({ id: 'requestPackage.documentPackage' })}
          </div>
          <div className="request-task__id__lang-switcher">
            <ThreeDotsMenu options={dotsMenu} onSelect={key => handleChangeLang()} small={true} />
          </div>
        </div>
        <div className="request-package__labels">
          <div className="request-package__status">
            <DocumentPackageBusinessStatusLabel role={role} type="view" status={status} />
          </div>
          {info.urgentApproval && <DocumentPackageUrgentApproveLabel type="view" />}
        </div>
        <Coordination
          people={mapCoordinationUsers()}
          documentPackageId={info.id}
          type={REQUEST_APPROVEMENT}
          permissions={permissions}
          skippedApproval={info.skippedApproval}
          urgentApproval={info.urgentApproval}
        />
        <div className="request-package__agreement">
          <div className="request-package__subheader">
            {intl.formatMessage({ id: 'requestPackage.section.finalApprovement' })}
          </div>
          <CoordinationFinalApprover
            key={`${coordinationFinalApprover.id}-${coordinationFinalApprover.lastModifiedDate}`}
            person={coordinationFinalApprover}
            me={user.id === coordinationFinalApprover.id}
            permissions={permissions}
          />
        </div>
      </div>
      {areActionsVisible && (
        <div className="request-view__actions">
          {canUpdateDescription && (
            <Button color="blue" onClick={() => setUpdateRequestModalOpen(true)} loading={sendingData}>
              <div className="request-view__actions_edit">
                {intl.formatMessage({ id: 'requestPackage.button.editPackage' })}
              </div>
            </Button>
          )}
          {canUpdateMyDecisionComment && (
            <Button color="blue" onClick={() => setUpdateDecisionModalOpen(true)} loading={sendingData}>
              <div className="request-view__actions_edit">
                {intl.formatMessage({ id: 'requestPackage.button.changeDecision' })}
              </div>
            </Button>
          )}
          {canTakeForRework && <TakeForRework documentPackage={info || {}} />}
          {canMakeDecision && (
            <Button color="blue" onClick={startApprove} loading={sendingData}>
              <div className="request-view__actions_approve">{text}</div>
            </Button>
          )}
          {canViewChat && (
            <div className="request-view__actions_chat">
              <Button
                color="blue"
                onClick={() => {
                  setChatOpen(true);
                }}>
                <div className="request-view__actions_chat__text">
                  <div>
                    {intl.formatMessage({ id: 'requestPackage.button.discuss' })}
                    {info.totalPostsCount > 0 ? ` (${info.totalPostsCount})` : ''}
                  </div>
                </div>
                {info.unreadPostsCount > 0 && (
                  <div className="request-view__actions_chat__amount">{info.unreadPostsCount}</div>
                )}
              </Button>
            </div>
          )}
          {canMakeDecision && (
            <Button color="blue" onClick={startReject} loading={sendingData}>
              <div className="request-view__actions_reject">
                {intl.formatMessage({ id: 'requestPackage.button.reject' })}
              </div>
            </Button>
          )}
        </div>
      )}
      {chatOpen && (
        <DiscussionPopup style={{ bottom: 78 }} documentPackageId={info.id} onClose={() => setChatOpen(false)} />
      )}
      {toFinish && (
        <ModalWindow onClose={resetDescision}>
          <div className="request-view-approve">
            <div className="request-view-approve__header">{modalTitle}</div>

            {needMultipleLanguageComments ? (
              <CommentsBlock
                type="MULTIPLE"
                defaultComment={comment}
                defaultCommentEn={commentEn}
                onCommentChange={setComment}
                onCommentEnChange={setCommentEn}
                commentError={errors.comment}
                commentEnError={errors.commentEn}
                autoFocus={true}
              />
            ) : (
              <CommentsBlock
                type="SINGLE"
                placeholder={
                  (toApprove && intl.formatMessage({ id: 'requestPackage.addComment.notRequired' })) ||
                  intl.formatMessage({ id: 'requestPackage.addComment.required' })
                }
                defaultComment={comment}
                onCommentChange={setComment}
                commentError={errors.comment}
                autoFocus={true}
              />
            )}

            <DropZone
              title={intl.formatMessage({ id: 'requestPackage.attachDocuments' })}
              onFileAddExt={handleFileAdd}
              onFileRemove={handleFileRemove}
              initialFiles={files}
              multiple={true}
              fileListMaxHeight="150px"
            />
            <Button color="blue" onClick={finish} className="request-view-approve__button" loading={sendingData}>
              {intl.formatMessage({ id: 'requestPackage.button.send' })}
            </Button>
          </div>
        </ModalWindow>
      )}

      <UpdateDecision
        key={`${info.id}-${info.lastModifiedDate}`}
        title={intl.formatMessage({ id: 'requestPackage.button.changeDecision' })}
        show={updateDecisionModalOpen}
        onClose={() => setUpdateDecisionModalOpen(false)}
        documentPackage={info}
        user={user}
      />

      <UpdateRequest
        type={REQUEST_APPROVEMENT}
        title={intl.formatMessage({ id: 'requestPackage.button.editPackage' })}
        show={updateRequestModalOpen}
        onClose={() => setUpdateRequestModalOpen(false)}
        documentPackageId={info.id}
        documentPackage={info || {}}
        submitAsApprove={info.businessStatus === MY_RESPONSE && info.status === REWORKING}
      />
    </Fragment>
  );
}

export default withRouter(RequestPackage);

// Utils

type DecisionIntent = 'TO_APPROVE' | 'TO_REJECT';

function makeDecisionModalTitle(userRole: UserRole, decision: DecisionIntent): string {
  const titles: [UserRole, DecisionIntent, string][] = [
    ['ROLE_INITIATOR', 'TO_APPROVE', 'requestPackage.comment.ROLE_APPROVER.TO_APPROVE'],
    ['ROLE_INITIATOR', 'TO_REJECT', 'requestPackage.comment.ROLE_APPROVER.TO_REJECT'],
    ['ROLE_APPROVER', 'TO_APPROVE', 'requestPackage.comment.ROLE_APPROVER.TO_APPROVE'],
    ['ROLE_APPROVER', 'TO_REJECT', 'requestPackage.comment.ROLE_APPROVER.TO_REJECT'],
    ['ROLE_FINAL_APPROVER', 'TO_APPROVE', 'requestPackage.comment.ROLE_FINAL_APPROVER.TO_APPROVE'],
    ['ROLE_FINAL_APPROVER', 'TO_REJECT', 'requestPackage.comment.ROLE_FINAL_APPROVER.TO_REJECT'],
  ];
  return titles.reduce(
    (title, [r, d, t]) => (r === userRole && d === decision ? t : title),
    'requestPackage.comment.default'
  );
}
