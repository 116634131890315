import React from 'react';

import { useIntl } from 'react-intl';

import { DecisionBusinessStatus } from '../../../rest/dto/Decision';

import '../work_label.scss';

interface IInWorkProps {
  daysSpent: number;
  hoursSpent: number;
  status: DecisionBusinessStatus;
  type?: string;
}

const InWork: React.FC<IInWorkProps> = ({ daysSpent, hoursSpent, status, type }) => {
  const intl = useIntl();

  const completedStatuses: DecisionBusinessStatus[] = [
    'APPROVED',
    'FINAL_APPROVED',
    'EXECUTED',
    'RESPONSE_PREPARED',
    'REJECTED',
    'FINAL_REJECTED',
    'RETURNED_FOR_REWORKING',
    'EXPIRED',
    'SKIPPED',
  ];

  const label: string = completedStatuses.includes(status)
    ? intl.formatMessage({ id: 'coordination.inWork.inCompleted' })
    : intl.formatMessage({ id: 'coordination.inWork.inProgress' });

  const coordinationDays: string = intl.formatMessage({ id: 'coordination.inWork.inHours' }, { hours: hoursSpent });
  const days: string =
    hoursSpent < 24 && hoursSpent > 0
      ? intl.formatMessage({ id: 'coordination.inWork.inHours' }, { hours: hoursSpent })
      : intl.formatMessage({ id: 'coordination.inWork.inDays' }, { days: daysSpent });
  
  return <div className="work_label">{`${label} ${ type === 'coordination' ? coordinationDays : days}`}</div>;
};

export default InWork;
