import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, withRouter, Link } from 'react-router-dom';

import { useIntl } from 'react-intl';

import Searcher from '../../components/searcher';
import Avatar from '../../components/avatar';
import MemberInfo from './member_info';

import formatUserName from '../../utils/string_utils';
import { fieldIntl } from '../../utils/helpers';

import http from '../../rest';

import './members.scss';

function Members({ match, history, location }) {
  const intl = useIntl();
  const [membersList, setMembersList] = useState([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    http.rpc.post('/getAllUsers', {}).then(res => {
      if (res.data.code === 500) history.push('/');
      else setMembersList(res.data.items);
    });
  }, [history]);
  const members = membersList
    .map(member => Object.assign({}, member, { username: formatUserName(member, intl.locale) }))
    .filter(member => ~member.username.toLowerCase().indexOf(searchString.toLowerCase()));
  return (
    <div className="members">
      <div className="members__list data-list">
        <div className="members__header">
          <span className="members__title">{intl.formatMessage({ id: 'list.membersRequests' })}</span>
        </div>
        <div className="members__searcher">
          <Searcher onChange={setSearchString} defaultValue={searchString} />
        </div>
        <div className="members__overview">
          {members.map(member => {
            const isSelected = +location.pathname.split('/').pop() === member.id;
            return (
              <Link
                to={`/members/${member.id}`}
                className={`members__overview_item${isSelected ? '--active' : ''}`}
                key={member.id}>
                <Avatar src={member.photoId} />
                <div className="members__overview_text">
                  <div className="members__overview_name">{member.username}</div>
                  <div className="members__overview_company">{fieldIntl(member, 'company', intl.locale)}</div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <Switch>
        <Route exact path={`${match.path}/:id`} render={() => <MemberInfo />} />
        <Redirect to={`${match.path}`} />
      </Switch>
    </div>
  );
}

export default withRouter(Members);
