import React from 'react';
import './splash.scss';

function Splash() {
  return (
    <div className="splash">
      <div className="splash__logo" />
      <div className="splash__text" />
    </div>
  );
}

export default Splash;
