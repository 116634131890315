import React, { useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import { ROLE_FINAL_APPROVER, ROLE_APPROVER, ROLE_INITIATOR } from '../../constants/roles';
import { UserStoreContext } from '../../store/user/store';

import createImg from '../../images/create.svg';
import importImg from '../../images/import.svg';

import './request-creator.scss';

function RequestCreator({ history }) {
  const user = useContext(UserStoreContext);
  const { role } = user;

  const createRequest = () => {
    history.push(`${history.location.pathname}/new`);
  };

  if (![ROLE_INITIATOR, ROLE_FINAL_APPROVER, ROLE_APPROVER].includes(role)) {
    return null;
  }

  return <img className="request-creator" src={createImg} alt="create" onClick={createRequest} />;
}

export default withRouter(RequestCreator);

export function ImportRequest() {
  const history = useHistory();

  const user = useContext(UserStoreContext);
  const { role } = user;

  const create = () => {
    history.push(`${history.location.pathname}/new`);
  };

  if (![ROLE_FINAL_APPROVER].includes(role)) {
    return null;
  }

  return <img className="request-creator" src={importImg} alt="import" onClick={create} />;
}
