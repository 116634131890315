import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';

import RequestPackage from '../request_package';
import RequestTask from '../request_task';
import WatchersList from '../../components/watchers_list';

import http from '../../rest';
import { useData } from '../../rest/client';
import { REQUEST_APPROVEMENT } from '../../constants/request_types';
import { VIEW_APPROVAL_SHEET } from '../../constants/permissions';
import {getApiUrl, getApprovementUrl, getDiscussionUrl} from '../../utils/get_document_url';

import './request-view.scss';

function RequestView({ history, match }) {
  const intl = useIntl();
  const [info, setInfo] = useState(null);
  const [watchers, setWatchers] = useState([]);
  const { id } = match.params;
  const path = match.path.split('/')[1] || '';

  const { data: packageData } = useData('rpc', 'post', '/getDocumentPackage', { id });

  useEffect(() => {
    if (packageData.status && packageData.data) {
      setInfo(packageData.data);
      setWatchers(packageData.data.watchers);
    } else if (packageData.status === false && packageData.data.code === 500) {
      history.push(`/${path}`);
    }
  }, [id, path, history, packageData]);

  if (info === null) return null;
  const addWatchers = members => {
    const newMembers = members.filter(({ id }) => !watchers.some(watcher => watcher.id === id));
    http.rpc
      .post('/addDocumentPackageWatchers', {
        documentPackageId: info.id,
        watchers: newMembers.map(({ id }) => id),
      })
      .then(res => {
        if (res.status) {
          setWatchers(watchers.concat(newMembers));
        }
      });
  };

  const type = info && info.type;
  const isBlocked = info.id !== +match.params.id;

  const permissions = info.permissions || [];
  const canViewApprovalList = permissions.includes(VIEW_APPROVAL_SHEET);

  return (
    <div className="request-view">
      {isBlocked && <div className="request-view__loader" />}
      <div className={'request-view__container'}>
        {type === REQUEST_APPROVEMENT ? <RequestPackage info={info} /> : <RequestTask info={info} />}
        {canViewApprovalList && (
          <div className="request-view__agreement_list">
            <div className="request-view__agreement_list_header">
              {intl.formatMessage({ id: 'requestView.approvalSheet' })}
            </div>
            <div className="request-view__agreement_list_file">
              <a href={getApprovementUrl(info.id)} target="_blank" rel="noopener noreferrer">
                <div className="preview__item_icon --pdf" />
                <span className="request-view__agreement_list_text">Лист_согласования.pdf</span>
              </a>
              <a className="request-view__agreement_list_dl" href={getApprovementUrl(info.id)} download>
                {intl.formatMessage({ id: 'requestView.download' })}
              </a>
            </div>
          </div>
        )}
        {
          info.totalPostsCount > 0 && <div className="request-view__agreement_list">
            <div className="request-view__agreement_list_header">
              {intl.formatMessage({ id: 'discussion.discuss' })}
            </div>
            <div className="request-view__agreement_list_file">
              <a href={getDiscussionUrl(info.id)} target="_blank" rel="noopener noreferrer">
                <div className="preview__item_icon --pdf" />
                <span className="request-view__agreement_list_text">Обсуждение.pdf</span>
              </a>
              <a className="request-view__agreement_list_dl" href={getDiscussionUrl(info.id)} download>
                {intl.formatMessage({ id: 'discussion.download' })}
              </a>
            </div>
          </div>
        }
        <div className="request-view__watchers">
          <WatchersList watchers={watchers} addWatchers={addWatchers} />
        </div>
      </div>
    </div>
  );
}

export default withRouter(RequestView);
