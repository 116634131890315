import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import Dots from '../dots';
import { REDIRECT_TIME, REDIRECT_TIME_DECREASE_INTERVAL, REDIRECT_MESSAGE } from '../../../constants/logout';
import { URL_AUTH } from '../../../constants/urls';

import './auth-pin.scss';

const MAX_LENGTH = 4;
const BLOCK_USER_CODE = 107;

function AuthPin({ authByPin, history }) {
  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState(false);
  const [pin, setPin] = useState('');
  const [userBlocked, setUserBlocked] = useState(false);
  const [timeToRedirect, setTimeToRedirect] = useState(REDIRECT_TIME);

  const redirect = useCallback(() => {
    history.push({ pathname: URL_AUTH });
  }, [history]);

  useEffect(() => {
    let timeoutId;

    if (userBlocked) {
      timeoutId = setTimeout(() => {
        setTimeToRedirect(timeToRedirect - REDIRECT_TIME_DECREASE_INTERVAL);
      }, REDIRECT_TIME_DECREASE_INTERVAL * 1000);

      if (timeToRedirect <= 0) {
        redirect();
      }
    }

    return () => clearTimeout(timeoutId);
  }, [userBlocked, redirect, timeToRedirect]);

  const onChangePin = useCallback(
    pin => {
      if (userBlocked) {
        return;
      }

      setPin(pin);

      if (pin.length === MAX_LENGTH) {
        authByPin(pin, error => {
          if (error) {
            setErrorMessage(error.message || intl.formatMessage({ id: 'authPin.error' }));

            if (error.code === BLOCK_USER_CODE) {
              setUserBlocked(true);
            }

            setPin('');
          }
        });
      } else if (errorMessage) {
        setErrorMessage('');
      }
    },
    [userBlocked, errorMessage, authByPin, intl]
  );

  const errorClass = errorMessage && pin.length > 0 ? ' --error' : '';

  return (
    <div className={'auth-pin' + errorClass}>
      <div className="auth-pin__text">{intl.formatMessage({ id: 'authPin.enterPin' })}</div>
      <Dots pin={pin} onChangePin={onChangePin} />
      {errorMessage && <div className="auth-pin__error">{errorMessage}</div>}
      {userBlocked && (
        <div className="auth-pin__redirect">
          {intl.formatMessage({ id: REDIRECT_MESSAGE }, { seconds: timeToRedirect })}
        </div>
      )}
    </div>
  );
}

AuthPin.propTypes = {
  authByPin: PropTypes.func.isRequired,
};
export default AuthPin;
