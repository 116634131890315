import React, { useState, useContext } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_INITIATOR, ROLE_APPROVER, ROLE_FINAL_APPROVER, ROLE_ROOT } from '../../constants/roles';
import { URL_SPLASH } from '../../constants/urls';
import { UserStoreContext } from '../../store/user/store';

function DefaultRedirect({ location }) {
  const user = useContext(UserStoreContext);
  const [prevAuthenticated, setPrevAuthenticated] = useState(undefined);

  const { authenticated, role } = user;

  let link = '';
  switch (role) {
    case ROLE_ROOT:
    case ROLE_ADMIN:
    case ROLE_INITIATOR:
    case ROLE_APPROVER:
    case ROLE_FINAL_APPROVER:
      if (prevAuthenticated !== undefined) {
        link = location.pathname === URL_SPLASH ? location.key || '' : '404';
      }
      break;
    default:
  }

  if (authenticated !== prevAuthenticated) {
    setPrevAuthenticated(authenticated);
  }

  if (authenticated === false) {
    link = 'auth';
  }

  return <Redirect to={`/${link}`} />;
}

export default withRouter(DefaultRedirect);
