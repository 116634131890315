import React from 'react';
import './not-viewed.scss';
import PropTypes from 'prop-types';

function NotViewed({ right, top }) {
  const style = {
    right,
    top,
  };
  return <div className="not-viewed" style={style} />;
}

NotViewed.defaultProps = {
  right: 0,
  top: 0,
};

NotViewed.propTypes = {
  right: PropTypes.number,
  top: PropTypes.number,
};

export default NotViewed;
