import { useCallback, useEffect, useState, useRef } from 'react';
import http from './index';
import { shallowEqual } from '../utils/shallowEqual';
import {
  RpcResponse,
  IRpcResponseSuccess,
  IRpcResponseError,
  isResponseHasError,
  networkErrorToRpcResponseError,
} from './dto/Response';

const requestsStore = new Map();

function genKey(service, method, url) {
  return `${service}:${method}:${url}`;
}

function addToStore(key, request) {
  const requests = requestsStore.get(key) || [];
  requests.push(request);
  requestsStore.set(key, requests);
}

function removeFromStore(key, request) {
  const requests = requestsStore.get(key) || [];
  if (requests.length > 0) {
    requestsStore.set(
      key,
      requests.filter(req => req !== request)
    );
  }
}

/**
 * Data fetching function
 * @param { ('rpc' | 'api') } service
 * @param { ('get' | 'post' | 'put' | 'delete' | 'options') } method
 * @param { string } url
 * @param { Object } props

 * @param { boolean } slaveMode
 * If set to 'true' then will not make request by itself,
 * reacts only for subscribers notify calls
 */
function useData<T>(service = 'rpc', method, url, props, slaveMode = false) {
  const [needUpdate, setNeedUpdate] = useState(false);
  const [data, setData] = useState<IRpcResponseSuccess<T> | {}>({});
  const [error, setError] = useState<IRpcResponseError | {}>({});
  const [storedProps, setStoredProps] = useState(props || {});
  const key = genKey(service, method, url);

  const request = useRef<() => Promise<RpcResponse<T>>>();

  const buildRequest = useCallback(props => {
    return () => {
      return http[service][method](url, props)
        .then(res => {
          if (isResponseHasError(res)) {
            setError(res);
            setData({});
          } else {
            setData(res);
            setError({});
          }
        })
        .catch(err => {
          const rpcRrror = networkErrorToRpcResponseError(err);
          setError(rpcRrror);
        });
    };
    // eslint-disable-next-line
  }, []);

  const notify = useCallback(() => setNeedUpdate(true), []);

  const subscribe = useCallback(request => {
    addToStore(key, request);
    // eslint-disable-next-line
  }, []);

  const unsubscribe = useCallback(request => {
    removeFromStore(key, request);
    // eslint-disable-next-line
  }, []);

  // works only once
  useEffect(() => {
    // store props to detect updates
    setStoredProps(props);
    // store request method link, for later unsubscribe
    request.current = buildRequest(props);
    // store link for notifiers
    subscribe(request.current);

    if (!slaveMode) {
      // get data
      request.current();
    }

    return () => unsubscribe(request.current);
    // eslint-disable-next-line
  }, []);

  // if props was updated
  useEffect(() => {
    if (!shallowEqual(storedProps, props)) {
      // update link
      unsubscribe(request.current);
      request.current = buildRequest(props);
      subscribe(request.current);
      // update stored props for next comparison
      setStoredProps(props);

      if (!slaveMode) {
        // get new data
        request.current();
      }
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (needUpdate) {
      const requests = requestsStore.get(key) || [];
      requests.forEach(req => {
        req();
      });
      setNeedUpdate(false);
    }
    // eslint-disable-next-line
  }, [needUpdate]);

  return { data, error, unsubscribe, notify };
}

function useDataNotify(service = 'rpc', method, url) {
  const [needUpdate, setNeedUpdate] = useState(false);
  const key = genKey(service, method, url);

  const notify = useCallback(() => setNeedUpdate(true), []);

  useEffect(() => {
    if (needUpdate) {
      const requests = requestsStore.get(key) || [];
      requests.forEach(req => {
        req();
      });
      setNeedUpdate(false);
    }
    // eslint-disable-next-line
  }, [needUpdate]);

  return { notify };
}

export { useData, useDataNotify };
