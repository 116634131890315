import * as types from './action_types';
import http from '../../rest';
import { AccountLanguage } from '../../rest/dto/Account';

const setUserAuth = authenticated => ({
  type: types.SET_USER_AUTH,
  payload: authenticated,
});

export const authCheckState = _ => dispatch => {
  http.api
    .get('/me')
    .then(user => {
      dispatch(authUser(user));
    })
    .catch(_ => {
      dispatch(setUserAuth(false));
    });
};

export const authUser = user => {
  return {
    type: types.AUTH_USER,
    payload: user,
  };
};

export const sendLogoutReq = () => {
  return http.api.post('/auth/logout');
};

export const logoutAction = () => dispatch => {
  dispatch({
    type: types.LOGOUT_USER,
  });
};

export const logoutUser = () => dispatch => {
  sendLogoutReq().then(() => {
    dispatch({
      type: types.LOGOUT_USER,
    });
  });
};

export const setPreferredLanguage = (lang: AccountLanguage) => (dispatch: React.Dispatch<unknown>) => {
  dispatch({
    type: types.SET_PREFERRED_LANGUAGE,
    payload: lang,
  });
};
