import React from 'react';
import ReactDOM from 'react-dom';

import { IntlProvider } from 'react-intl';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';
import '@formatjs/intl-pluralrules/dist/locale-data/en';

import '@formatjs/intl-unified-numberformat/polyfill';
import '@formatjs/intl-unified-numberformat/dist/locale-data/ru';
import '@formatjs/intl-unified-numberformat/dist/locale-data/en';

import { Provider as AlertProvider } from 'react-alert';
import { AlertTemplate, defaultOptions as alertOptions } from './components/alerts';

import './index.css';
import App from './app/app';
import * as serviceWorker from './serviceWorker';

// Enable Firebase Cloud Messaging service worker
import './components/messaging/init_sw';
import './components/messaging/init_messaging';

import { UserProvider, UserStoreContext } from './store/user/store';

// Import translated messages
import messagesEn from './translations/en.json';
import messagesRu from './translations/ru.json';

import './styles/main.scss';

const messages = {
  en: messagesEn,
  ru: messagesRu,
};

ReactDOM.render(
  <UserProvider>
    <UserStoreContext.Consumer>
      {user => {
        const locale = user.preferredLanguage.toLowerCase();
        return (
          <IntlProvider locale={locale} messages={messages[locale]}>
            <AlertProvider template={AlertTemplate} {...alertOptions}>
              <App />
            </AlertProvider>
          </IntlProvider>
        );
      }}
    </UserStoreContext.Consumer>
  </UserProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
