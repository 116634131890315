import React from 'react';
import ReactDOM from 'react-dom';
import { setup } from 'bem-cn';

import { useIntl } from 'react-intl';

import usePortal from '../../hooks/use_portal';

import './discussion.scss';
import Discussion from './discussion';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

function DiscussionPopup({ documentPackageId, onClose, style }) {
  const intl = useIntl();
  const portal = usePortal();
  const cn = block('discussion-popup');

  return ReactDOM.createPortal(
    <div className={cn()} style={style ? style : {}}>
      <div className={cn('header')}>
        <div className={cn('icon')} />
        <div className={cn('title')}>{intl.formatMessage({ id: 'discussion.discuss' })}</div>
        <div className={cn('shutter')} onClick={onClose} />
      </div>
      <div className={cn('container')}>
        <Discussion documentPackageId={documentPackageId} />
      </div>
    </div>,
    portal
  );
}

export default DiscussionPopup;
