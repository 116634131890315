import React from 'react';

import { useIntl, FormattedHTMLMessage } from 'react-intl';

import ModalWindow from '../../components/modal_window';
import Button from '../../components/button';

export function EditWarningWindow({ show, title, onClose, onAccept }) {
  const intl = useIntl();
  const caption = <span>{intl.formatMessage({ id: 'editWarningWindows.caption' })}</span>;
  const warning = (
    <span>
      <FormattedHTMLMessage
        id="editWarningWindows.warning"
        values={{
          br: () => <br />,
        }}
      />
    </span>
  );
  return (
    <CommonWarningWindow
      show={show}
      title={title}
      caption={caption}
      warning={warning}
      onClose={onClose}
      onAccept={onAccept}
    />
  );
}

export function SkippedApproversWarningWindow({ show, onClose, onAccept }) {
  const title = (
    <span>
      <FormattedHTMLMessage
        id="skippedApproversWarningWindow.title"
        values={{
          br: () => <br />,
        }}
      />
    </span>
  );
  const caption = <span></span>;
  const warning = (
    <span>
      <FormattedHTMLMessage
        id="skippedApproversWarningWindow.warning"
        values={{
          br: () => <br />,
        }}
      />
    </span>
  );
  return (
    <CommonWarningWindow
      show={show}
      title={title}
      caption={caption}
      warning={warning}
      onClose={onClose}
      onAccept={onAccept}
    />
  );
}

function CommonWarningWindow({ show, title, caption, warning, onClose, onAccept }) {
  const intl = useIntl();
  return show ? (
    <ModalWindow onClose={onClose}>
      <div className="update-decision --warning">
        <div className="update-decision__header">{title}</div>
        <div className="update-decision__content">
          <div className="update-decision__warning_text --error">{caption}</div>
          <div className="update-decision__warning_text">{warning}</div>
        </div>
        <div className="update-decision__actions">
          <Button color="blue" size="normal" onClick={onAccept} className="update-decision__button">
            {intl.formatMessage({ id: 'commonWarningWindow.button.proceed' })}
          </Button>
          <Button color="link" size="normal" onClick={onClose} className="update-decision__button">
            {intl.formatMessage({ id: 'commonWarningWindow.button.cancel' })}
          </Button>
        </div>
      </div>
    </ModalWindow>
  ) : null;
}
