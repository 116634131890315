//  do not forget change the sender id inside /public/firebase-messaging-sw.js too
export const FIREBASE_MESSAGING_SENDER_ID = '910476627259';

export const FIREBASE_MESSAGING_PUBLIC_VAPID_KEY =
  'BBeLG8oXN2uD6FgVlLdAGwweTcdZ4TOY7poxwVoLlasJ8-XpgG3qIN1Ui6z5q0bxsk4WYgWyDhplTAtHRu_fvnI';

export const FIREBASE_MESSAGING_DELIVERY_TYPE = 'FBS';

export const SAFARI_MESSAGING_DELIVERY_TYPE = 'SFR';
export const SAFARI_WEB_PUSH_ID = 'web.com.cometrica.hermes';

export const NOTIFICATION_TIMEOUT = 0;
