import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import formatUserName from '../../utils/string_utils';

import './members-search.scss';

import Select from 'react-select';
import http from '../../rest';
import Avatar from '../avatar';

function MembersSearch({ label, getMembers, members: error }) {
  const intl = useIntl();

  const [visible, setVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [members, setMembers] = useState([]);
  const container = useRef(null);

  useEffect(() => {
    http.rpc.post('/getAllUsers', {}).then(res => {
      if (res.data.code !== 500) {
        setMembers(
          res.data.items.map(member => {
            return { value: member, label: formatUserName(member, intl.locale) };
          })
        );
      }
    });
  }, []);

  useLayoutEffect(() => {
    const closeDropdown = e => {
      if (container.current && !container.current.contains(e.target)) {
        setVisible(false);
        document.removeEventListener('click', closeDropdown);
      } else if (!container.current) {
        document.removeEventListener('click', closeDropdown);
      }
    };
    if (visible) {
      document.addEventListener('click', closeDropdown);
    } else {
      document.removeEventListener('click', closeDropdown);
    }
  }, [visible, container]);

  const _className = ['members-search'];
  if (visible) {
    _className.push('--z-index100');
  }
  if (error) {
    _className.push('--error');
  }

  const handleChange = selectedOptions => {
    setSelectedOptions(selectedOptions);
    getMembers(selectedOptions ? selectedOptions.map(member => member.value) : []);
  };

  const Option = props => {
    const { innerRef, innerProps, value, label } = props;
    
    const optionClasses = selectedOptions.some((el) => el.value.id === value.id) ?
      'option option__selected' : 'option'
    
    return (
      <div ref={innerRef} {...innerProps} className={optionClasses}>
        <div className='optionLabel'>
          <Avatar src={value.photoId} /> <span>{label}</span>
        </div>
        <svg className='check' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path 
            fill="currentColor" 
            d="M13.005 23.003L6 15.502l2.076-2.223 4.28 4.584c.324.348.778.348 1.103.07L24.225 8l2.075 2.223-13.295 12.78z"
          />
        </svg>
      </div>
    );
  };

  const styles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 999,
      margin: 0,
      border: 0,
      boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.16)',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    control: (provided) => ({
      ...provided,
      border: 0,
      borderRadius: 0,
      borderBottom: '1px solid',
      boxShadow: 0,
      borderColor: '#CCCCCC !important'
    }),
    indicatorsContainer: (_) => ({
      display: 'none',
    }),
    multiValue: (provider) => ({
      ...provider,
      borderRadius: '50px',
      background: '#F7F7F7',
      padding: '3px',
      svg: {
        width: '20px',
        height: '20px',
        color: '#F7F7F7',
        background: 'gray',
        borderRadius: '50%',
      },
    }),
    selectedOptions: (provider) => ({
      background: 'red'
    })
  };

  return (
    <div className={_className.join(' ').trim()} ref={container}>
      <Select
        components={{ Option }}
        styles={styles}
        value={selectedOptions}
        placeholder={label || intl.formatMessage({ id: 'membersSearch.members' })}
        onChange={handleChange}
        options={members}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isMulti
      />
    </div>
  );
}

MembersSearch.defaultProps = {
  label: '',
  getMembers: _ => _,
  members: [],
  filterMembers: [],
  one: false,
  error: '',
};

MembersSearch.propTypes = {
  label: PropTypes.string,
  getMembers: PropTypes.func,
  members: PropTypes.array,
  filterMembers: PropTypes.array,
  one: PropTypes.bool,
  error: PropTypes.string,
};
export default MembersSearch;
