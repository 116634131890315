export const APPROVED = 'APPROVED';
export const APPROVING = 'APPROVING';
export const AWAITING_EXECUTION = 'AWAITING_EXECUTION';
export const AWAITING_MY_DECISION = 'AWAITING_MY_DECISION';
export const CANCELLED = 'CANCELLED';
export const COMMENT_REQUIRED = 'COMMENT_REQUIRED';
export const DRAFT = 'DRAFT';
export const EXECUTED = 'EXECUTED';
export const EXECUTING = 'EXECUTING';
export const FINAL_APPROVED = 'FINAL_APPROVED';
export const FINAL_REJECTED = 'FINAL_REJECTED';
export const FINAL_REQUESTED = 'FINAL_REQUESTED';
export const MY_APPROVING = 'MY_APPROVING';
export const MY_REQUESTED = 'MY_REQUESTED';
export const MY_RESPONSE = 'MY_RESPONSE';
export const ON_FINAL_APPROVING = 'ON_FINAL_APPROVING';
export const REJECTED = 'REJECTED';
export const REQUESTED = 'REQUESTED';
export const RESPONSE_PREPARED = 'RESPONSE_PREPARED';
export const RETURNED_FOR_REWORKING = 'RETURNED_FOR_REWORKING';
export const REWORKING = 'REWORKING';

export type DocumentPackageStatus =
  /* Черновк */
  | 'DRAFT'
  /* На согласовании (для утверждений и поручений) */
  | 'APPROVING'
  /* На утверждении (для утверждений) */
  | 'APPROVED'
  /* Отклонено (для утверждений) */
  | 'REJECTED'
  /* Утвержденно (для утверждений) */
  | 'FINAL_APPROVED'
  /* Не утвержденно (для утверждений) */
  | 'FINAL_REJECTED'
  /* Удален */
  | 'DELETED'
  /* В работе (для поручений) */
  | 'EXECUTING'
  /* В доработке (для поручений) */
  | 'REWORKING'
  /* Завершено (для поручений) */
  | 'EXECUTED';

export type DocumentPackageBusinessStatus =
  /* Не определен */
  | 'UNDEFINED'
  /* Черновк */
  | 'DRAFT'
  /* Удален */
  | 'DELETED'
  /* На согласовании (для утверждений и поручений) */
  | 'APPROVING'
  /* На утверждении (для утверждений) */
  | 'APPROVED'
  /* Отклонено (для утверждений) */
  | 'REJECTED'
  /* Утвержденно (для утверждений) */
  | 'FINAL_APPROVED'
  /* Не утвержденно (для утверждений) */
  | 'FINAL_REJECTED'
  /* В работе (для поручений) */
  | 'EXECUTING'
  /* В доработке (для поручений) */
  | 'REWORKING'
  /* Завершено (для поручений) */
  | 'EXECUTED'
  /* Ожидает моего ответа */
  | 'MY_RESPONSE'
  /* Ожидает моего решения */
  | 'MY_APPROVING';
