import React, { useCallback, useState, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import Avatar from '../avatar';
import { DocumentPackageBusinessStatusLabel, DocumentPackageUrgentApproveLabel } from '../label';
import NotViewed from '../not_viewed';
import ThreeDotsMenu from '../three_dots_menu';

import http from '../../rest';
import { UserStoreContext } from '../../store/user/store';

import formatDate from '../../utils/date_utils';
import formatUserName from '../../utils/string_utils';

import { REQUEST_APPROVEMENT, REQUEST_TASK } from '../../constants/request_types';
import { VIEW_CHAT } from '../../constants/permissions';

import { useNotifyListDataUpdate, useNotifyDocumentPackageCounts } from '../../hooks/use_notify_list_data_update';

import throttle from 'lodash/fp/throttle';
import without from 'lodash/fp/without';

import './documents_list.scss';

const THROTTLE_NOTIFY_LIST_TIMEOUT = 100;

const DocumentsList = ({ data, path, location, onClick }) => {
  const user = useContext(UserStoreContext);
  const { preferredLanguage, role } = user;

  const alert = useAlert();
  const intl = useIntl();

  const [readed, setReaded] = useState([]);

  const notify = useNotifyListDataUpdate();
  const packageCountNotify = useNotifyDocumentPackageCounts();
  const throttledDebounceNotify = useCallback(
    throttle(THROTTLE_NOTIFY_LIST_TIMEOUT, () => {
      packageCountNotify();
      notify();
    }),
    [notify, packageCountNotify]
  );

  const setReadStatus = (id, read = true) => {
    return http.rpc.post('/setReadStatus', { id, read });
  };

  const markUnreadMenuItem = { key: 'mark-unread', text: intl.formatMessage({ id: 'list.item.markAsUnread' }) };
  const dotsMenu = [markUnreadMenuItem];
  const onSelectDotsMenu = (elId, key) => {
    if (key === markUnreadMenuItem.key) {
      makeUnread(elId);
    }
  };

  const makeRead = useCallback(
    async elId => {
      setReaded(readed => readed.concat([elId]));
      try {
        await setReadStatus(elId, true);
        throttledDebounceNotify();
      } catch {}
    },
    [throttledDebounceNotify, setReaded]
  );

  const makeUnread = useCallback(
    async elId => {
      setReaded(without([elId]));
      try {
        await setReadStatus(elId, false);
        throttledDebounceNotify();
      } catch {
        alert.error(intl.formatMessage({ id: 'list.item.markAsUnread.failed' }));
      }
    },
    // eslint-disable-next-line
    [throttledDebounceNotify, setReaded]
  );

  const stopEvent = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return data.map((el, elIndex) => {
    const { displayAccounts = [], type, businessStatus } = el;

    const isSelected = +location.pathname.split('/').pop() === el.id;
    const isDraft = el.businessStatus === 'DRAFT';
    const isNotRead = !el.read && !readed.includes(el.id);
    const isNotTouched = isNotRead && !isSelected;
    const isUrgent = !!el.urgentApproval;
    const isUserVisible = displayAccounts.length > 0;
    const isMultipleUsers = displayAccounts.length > 1;

    let photoId;
    let userName =
      el.type === REQUEST_TASK && user.role === 'ROLE_INITIATOR'
        ? formatUserName(el.executor, intl.locale)
        : displayAccounts.map(user => formatUserName(user, intl.locale)).join(', ');
    if (!isMultipleUsers && isUserVisible) {
      const user = displayAccounts[0];
      photoId = user.photoId;
    }

    if (el.type === REQUEST_TASK) {
      photoId = el.executor.photoId;
    }

    if (!userName && el.type === REQUEST_TASK && user.role === 'ROLE_INITIATOR') {
      userName = intl.formatMessage({ id: 'list.item.noExecutor' });
    }

    const permissions = el.permissions || [];
    const showUnreadMessagesNum = permissions.includes(VIEW_CHAT);

    const itemClickHandler = () => {
      if (!el.read) {
        makeRead(el.id);
      }
      onClick(el.id);
    };

    const displayedTitle =
      (preferredLanguage === 'RU' ? el.title : el.title_en) ||
      (type === REQUEST_APPROVEMENT
        ? intl.formatMessage({ id: 'list.item.newDocumentPackage' })
        : intl.formatMessage({ id: 'list.item.newOutgoing' }));

    return (
      <Link
        to={`${path}/${el.id}`}
        key={el.id}
        className={`document-list-link${isSelected ? '--active' : ''} ${isNotTouched ? '--read' : ''}`}
        onClick={itemClickHandler}>
        <div className={`document-list-el ${isDraft || isNotRead ? '' : '--can-mark-unread'}`}>
          <div className="document-list-el__avatar">
            {isUserVisible && <Avatar size={40} src={photoId} multiple={isMultipleUsers} />}
            {isNotRead && !isDraft && <NotViewed right={(isUserVisible && 44) || 6} top={(isUserVisible && 16) || 2} />}
          </div>
          <div className={'document-list-el__text-block' + (isUserVisible ? ' --shift' : '')}>
            <div className="document-list-el__header">
              {isUserVisible && <div className="document-list-el__user">{userName}</div>}
              {!isUserVisible && <div className="document-list-el__text-block__name">{displayedTitle}</div>}
              <div className="document-list-el__text-block__date">
                {formatDate(el.displayDate || el.createdDate, true, intl.locale)}
              </div>
              <div className="document-list-el__text-block__menu" onClick={stopEvent}>
                <ThreeDotsMenu options={dotsMenu} onSelect={key => onSelectDotsMenu(el.id, key)} small={true} />
              </div>
            </div>
            {isUserVisible && <div className="document-list-el__text-block__name">{displayedTitle}</div>}
            <div className="document-list-el__text-block__statuses">
              <DocumentPackageBusinessStatusLabel role={role} type="list" status={businessStatus} />
              {isUrgent && <DocumentPackageUrgentApproveLabel type="list" />}
            </div>
          </div>
          {showUnreadMessagesNum && el.unreadPostsCount > 0 && (
            <div className="document-list-el__unread">
              <div className={`document-list-el__unread__icon ${isSelected ? '--active' : ''}`} />
              <div className={`document-list-el__unread__text ${isSelected ? '--active' : ''}`}>
                {el.unreadPostsCount}
              </div>
            </div>
          )}
        </div>
      </Link>
    );
  });
};

DocumentsList.propTypes = {
  data: PropTypes.array,
  path: PropTypes.string,
  onClick: PropTypes.func,
  isAuthor: PropTypes.bool,
};

DocumentsList.defaultProps = {
  data: [],
  path: '',
  onClick: _ => _,
  isAuthor: false,
};

export default withRouter(DocumentsList);
