import React, { Fragment, useEffect, useState, useCallback, useReducer } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAlert } from 'react-alert';

import { useIntl } from 'react-intl';

import compose from 'lodash/fp/compose';
import curry from 'lodash/fp/curry';
import eq from 'lodash/fp/eq';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import some from 'lodash/fp/some';
import sumBy from 'lodash/fp/sumBy';

import DocumentsHeader from '../../components/documents_header';
import DocumentsList from '../../components/documents_list';
import EmptyListDocuments from '../../components/empty_list_documents';
import LoadMoreItem from '../../components/documents_list/load_more_item';
import NoSelectedDocument from '../../components/no_selected_document';
import RequestView from '../../containers/request_view';
import Searcher from '../../components/searcher';

import http from '../../rest';
import { useData } from '../../rest/client';

import { EMPTY } from '../../constants/document_list_names';

import { toTuple } from '../../utils/helpers';
import { hasNextPage, reducer, init } from '../../utils/pagination';

import './familiarization.scss';

const DOC_LIST_NAME_LABELS = {
  EMPTY: '',
};

const START_LOADING = '';

const PACKAGES_PER_PAGE = 20;

function Familiarization({ match, location }) {
  const intl = useIntl();
  const alert = useAlert();

  const [requestParams, changeRequestParams] = useReducer(reducer, PACKAGES_PER_PAGE, init);

  const [filterComponentProps, setFilterComponentProps] = useState({
    readOnly: false,
    availableStatuses: [],
    selectedStatuses: [],
  });

  const [watching, setWatching] = useState([]);

  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(START_LOADING);
  const { pathname } = location;

  // Get Data

  // load data per page for local use only, don't need to listen notifications from other part of app
  const [watchingData, setWatchingData] = useState([]);
  useEffect(() => {
    http.rpc
      .post('/getWatchingDocumentPackages', {
        /* FIXME: enable when filters be ready on backend ...requestParams.filter, */ ...requestParams.pagination,
      })
      .then(resp => {
        setWatchingData(resp);
      });
  }, [requestParams]);

  // load data for all loaded pages, updates list with new data if someone in app fires notify
  const { data: pagedDocumentAllLoadedData, unsubscribe: pagedDocumentAllLoadedDataUnsubscribe } = useData(
    'rpc',
    'post',
    '/getWatchingDocumentPackages',
    { /* FIXME: enable when filters be ready on backend ...requestParams.filter, */ ...requestParams.paginationAll },
    true
  );

  const toIdTuple = curry(toTuple)('id');

  // Prepare watching

  // ...per page

  useEffect(() => {
    const { data, status } = watchingData;

    if (isEmpty(data)) {
      return;
    }

    if (status === false || data.code === 500) {
      alert.error(data.message);
    } else {
      if (data && data.list && data.name === EMPTY) {
        const watching = {
          ...data,
          list: new Map((data.list || []).map(toIdTuple)),
        };
        setWatching(([oldWatching]) => {
          // if first page response (skip === 0), don't reuse existed data
          if (oldWatching && data.skip > 0) {
            // append new page to others loaded
            watching.list = new Map([...oldWatching.list, ...watching.list]);
          }
          return [watching];
        });
      }
    }
    // eslint-disable-next-line
  }, [watchingData]);

  // ...for all loaded pages

  useEffect(() => {
    const { data, status } = pagedDocumentAllLoadedData;

    if (isEmpty(data)) {
      return;
    }

    if (status === false || data.code === 500) {
      alert.error(data.message);
    } else {
      if (data && data.list && data.name === EMPTY) {
        const watching = {
          ...data,
          skip: requestParams.pagination.skip,
          list: new Map((data.list || []).map(toIdTuple)),
        };
        // refresh existed pages with new data
        setWatching([watching]);
      }
    }
    // eslint-disable-next-line
  }, [pagedDocumentAllLoadedData]);

  // FIXME: This is for fake filter statuses
  // Replace it by a real request
  useEffect(() => {
    const availableStatuses = [...watching]
      .map(list => {
        const statuses = [...list.list.values()].map(item => item.businessStatus);
        return statuses.filter((s, i) => statuses.indexOf(s) === i);
      })
      .flat(1);
    setFilterComponentProps(props => ({
      ...props,
      availableStatuses,
      selectedStatuses: isEmpty(props.selectedStatuses) ? availableStatuses : props.selectedStatuses,
    }));
  }, [watching]);

  // unsubscribe from data updates
  useEffect(() => {
    return () => {
      pagedDocumentAllLoadedDataUnsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const onLoadMore = useCallback(
    name => {
      const pagedPackages = watching.find(p => p.name === name);
      if (pagedPackages) {
        changeRequestParams({
          type: 'CALC_NEXT_PAGE',
          payload: {
            total: pagedPackages.total,
            skip: pagedPackages.skip,
            limit: PACKAGES_PER_PAGE,
          },
        });
      }
    },
    [watching]
  );

  // Filter

  const applyFilter = useCallback(
    filter => {
      changeRequestParams({
        type: 'APPLY_FILTER',
        payload: {
          ...requestParams.filter,
          readOnly: filter.readOnly,
          statuses: filter.selectedStatuses,
        },
      });
      setFilterComponentProps(props => ({
        ...props,
        readOnly: filter.readOnly,
        selectedStatuses: filter.selectedStatuses,
      }));
    },
    [requestParams.filter]
  );

  const clearFilter = useCallback(
    filter => {
      changeRequestParams({
        type: 'APPLY_FILTER',
        payload: {
          ...requestParams.filter,
          readOnly: false,
          statuses: [],
        },
      });
      setFilterComponentProps(props => ({
        ...props,
        readOnly: false,
        selectedStatuses: props.availableStatuses,
      }));
    },
    [requestParams.filter]
  );

  // Search

  // FIXME: remove it when filters be ready on backend
  const afterSearch = list => {
    if (searchString.length >= 3) {
      return filter(
        doc =>
          some(t => t.toLowerCase().indexOf(searchString.toLowerCase()) !== -1, [
            doc.id.toString(),
            doc.title,
            doc.title_en,
          ]) ||
          doc.watchers.some(w =>
            some(el => el.toLowerCase().indexOf(searchString.toLowerCase()) !== -1, [
              w.firstName,
              w.lastName,
              w.firstName_en,
              w.lastName_en,
            ])
          ),
        list
      );
    }
    return list;
  };

  // Render

  useEffect(() => {
    if (loading !== pathname) {
      setLoading(pathname);
    }
  }, [pathname, loading]);

  const respectUserActions = map(item => {
    return { ...item, list: afterSearch([...item.list.values()]) };
  });

  const watchingUser = respectUserActions(watching);

  const allDocPacks = [...watchingUser];

  const isDocPacksListsEmpty = compose(
    eq(0),
    sumBy(item => item.list.length)
  );

  const isDocPacksListsHasNexPages = some(item =>
    hasNextPage({
      total: item.total || 0,
      skip: item.skip || 0,
      limit: PACKAGES_PER_PAGE,
    })
  );

  const isNoDocPacks = isDocPacksListsEmpty(allDocPacks) && !isDocPacksListsHasNexPages(allDocPacks);

  return (
    <div className="familiarization">
      <div className="familiarization__list data-list">
        <div className="familiarization__header">
          <span className="familiarization__title">{intl.formatMessage({ id: 'list.familiarizationRequests' })}</span>
        </div>
        <div className="familiarization__searcher">
          <div className="familiarization__searcher__search">
            <Searcher onChange={setSearchString} defaultValue={searchString} />
          </div>
          {/* FIXME: enable when filters be ready on backend
          <div className="familiarization__searcher__filter">
            <FilterPopover applyFilter={applyFilter} clearFilter={clearFilter} filterValue={filterComponentProps} />
          </div>
          */}
        </div>
        {isNoDocPacks && <EmptyListDocuments />}
        {!isNoDocPacks && (
          <div className="data-list">
            {allDocPacks.map((item, key) => {
              const hasMore = hasNextPage({
                total: item.total,
                skip: item.skip,
                limit: PACKAGES_PER_PAGE,
              });
              return (
                <Fragment key={`${item.name}-${key}`}>
                  <DocumentsHeader text={DOC_LIST_NAME_LABELS[item.name]} clean={false} />
                  <DocumentsList data={item.list} path={match.path} />
                  {hasMore && <LoadMoreItem onLoadMore={() => onLoadMore(item.name)} />}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>

      <div className="familiarization__view">
        <Switch>
          <Route exact path={`${match.path}/:id`} component={RequestView} />
          <Route
            exact
            path={match.path}
            component={props => (
              <NoSelectedDocument {...props} isLoaded={loading !== START_LOADING} isEmpty={isNoDocPacks} />
            )}
          />
          <Redirect to={match.path} />
        </Switch>
      </div>
    </div>
  );
}

export default Familiarization;
