import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { isInViewport } from '../../utils/dom';

import isFunction from 'lodash/fp/isFunction';

import loader from '../../images/loader-white.png';
import './documents_list.scss';

const AUTO_LOAD_MORE_TIMEOUT = 1000; // in milliseconds

export default function LoadMoreItem({ onLoadMore }) {
  const intl = useIntl();
  const elRef = useRef(null);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (elRef.current && isInViewport(elRef.current)) {
        isFunction(onLoadMore) && onLoadMore();
      }
    }, AUTO_LOAD_MORE_TIMEOUT);
    return () => clearInterval(timeout);
  }, [onLoadMore]);

  return (
    <div className="document-list-link" onClick={onLoadMore} ref={elRef}>
      <div className="document-list-el">
        <div className="document-list-el__loading">
          <img src={loader} alt={intl.formatMessage({ id: 'list.loading.img' })} className="loading" />
        </div>
      </div>
    </div>
  );
}
