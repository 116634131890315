import Avatar from '../../avatar';
import React from 'react';

const CoordinationAvatar = ({ id = '' }) => {
  return (
    <div className="coordination__level_photo">
      <Avatar size={30} src={id} />
    </div>
  );
};

export default CoordinationAvatar;
