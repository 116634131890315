import React from 'react';

import { useIntl } from 'react-intl';

import './mobile-view.scss';

function MobileView() {
  const intl = useIntl();

  return (
    <div className="mobile-view">
      <div className="mobile-view__container">
        <div className="mobile-view__logo" />
        <div className="mobile-view__title">{intl.formatMessage({ id: 'mobileView.title' })}</div>
        <div className="mobile-view__bitmap" />
        <div className="mobile-view__invite">{intl.formatMessage({ id: 'mobileView.invite' })}</div>
        <a
          target="_blank"
          href="https://apps.apple.com/ru/app/usm-hermes/id1468466977?ign-mpt=uo%3D4"
          rel="noopener noreferrer">
          <div className={`mobile-view__apple --${intl.locale}`} />
        </a>
      </div>
    </div>
  );
}

export default MobileView;
