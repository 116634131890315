import React, { Fragment } from 'react';
import './modal-window.scss';
import PropTypes from 'prop-types';
import usePortal from '../../hooks/use_portal';
import * as ReactDOM from 'react-dom';

function ModalWindow({ children, onClose, className }) {
  const portal = usePortal();
  const cn = ['modal-window__container', className].join(' ');
  return ReactDOM.createPortal(
    <Fragment>
      <div className="modal-window">
        <div className={cn}>
          <div className="modal-window__shutter" onClick={onClose} />
          {children}
        </div>
      </div>
    </Fragment>,
    portal
  );
}
ModalWindow.defaultProps = {
  className: '',
};

ModalWindow.propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};
export default ModalWindow;
