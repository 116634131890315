import React from 'react';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';

import http from '../../rest';

import { messaging, isSupported } from './init_messaging';

import {
  FIREBASE_MESSAGING_DELIVERY_TYPE,
  SAFARI_WEB_PUSH_ID,
  SAFARI_MESSAGING_DELIVERY_TYPE,
  NOTIFICATION_TIMEOUT,
} from '../../constants/messaging';

import { URL_PACKAGE } from '../../constants/urls';

class Messaging extends React.Component {
  messageHandler = data => console.log(data);
  unsubscribe = () => {};
  openedAlerts = [];

  componentDidMount() {
    const { alert, user, history } = this.props;

    this.messageHandler = payload => {
      const merge = payload => {
        if (payload) {
          const data = payload.data || {};
          const notification = payload.notification || {};
          return { ...data, ...notification };
        }
        return {};
      };

      const data = merge(payload);
      const a = alert.info(data, {
        timeout: NOTIFICATION_TIMEOUT,
        open: () => {
          let link = data.link || data.click_action || data['gcm.notification.link'] || '';
          const origin = window.location.origin;
          if (link.startsWith(origin)) {
            link = link.replace(origin, '');
          }
          if (!link && data.packageId) {
            link = `${URL_PACKAGE}/${data.packageId}`;
          }
          if (link) {
            const newTab = window.open(link, '_blank');
            if (newTab != null) {
              newTab.focus();
            }
          }
          a.close();
        },
      });
      this.openedAlerts.push(a);
    };

    const authenticated = user.authenticated;
    if (authenticated) {
      this.startReceivingNotifications();
    }
  }

  startReceivingNotifications() {
    if (!isSupported) {
      if (isSafari()) {
        requestPermissionSafari();
      }
      return;
    }

    messaging
      .getToken()
      .then(token => {
        if (token) {
          sendTokenToServer(token);
        } else {
          markTokenDisabled();
          requestPermission();
        }
      })
      .catch(() => {
        markTokenDisabled();
      });

    // Callback fired if Instance ID token is updated.
    messaging.onTokenRefresh(() => {
      messaging
        .getToken()
        .then(refreshToken)
        .catch(() => {
          markTokenDisabled();
        });
    });

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.setBackgroundMessageHandler` handler.
    this.unsubscribe = messaging.onMessage(this.messageHandler);
  }

  static stopReceivingNotifications() {
    if (!isSupported) {
      if (isSafari()) {
        const permissionData = window.safari.pushNotification.permission(SAFARI_WEB_PUSH_ID);
        if (permissionData.deviceToken) {
          return revokeTokenFromServer(permissionData.deviceToken);
        }
      }
      return Promise.resolve();
    }

    const token = getEnabledToken();

    if (token) {
      const clearToken = () => {
        revokeTokenFromServer(token);
        markTokenDisabled();
      };
      return messaging
        .deleteToken(token)
        .then(clearToken)
        .catch(clearToken);
    }
    return Promise.resolve();
  }

  componentDidUpdate(prevProps) {
    const prevAuth = prevProps.user.authenticated;
    const currAuth = this.props.user.authenticated;
    const isChanged = Boolean(prevAuth) !== Boolean(currAuth);

    if (isChanged) {
      if (currAuth) {
        this.startReceivingNotifications();
      } else {
        this.unsubscribe();
        this.openedAlerts.map(alert => alert.close());
        Messaging.stopReceivingNotifications();
      }
    }
    return;
  }

  render() {
    return null;
  }
}

export default {
  Component: withRouter(withAlert()(Messaging)),
  stopReceivingNotifications: Messaging.stopReceivingNotifications,
};

// Helpers

function requestPermission() {
  // Browsers now requires the user to interact with the page
  // in some way before the page can request permission to perform push notifications.
  const ask = () => {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        messaging
          .getToken()
          .then(token => {
            if (token) {
              sendTokenToServer(token);
            } else {
              markTokenDisabled();
            }
          })
          .catch(() => {
            markTokenDisabled();
          });
      }
    });
    window.removeEventListener('click', ask);
  };
  window.addEventListener('click', ask);
}

function requestPermissionSafari() {
  // Browsers now requires the user to interact with the page
  // in some way before the page can request permission to perform push notifications.
  const ask = () => {
    const permissionData = window.safari.pushNotification.permission(SAFARI_WEB_PUSH_ID);
    if (permissionData.permission === 'default') {
      window.safari.pushNotification.requestPermission(
        `${window.location.origin}/public/api`,
        SAFARI_WEB_PUSH_ID,
        {},
        ask
      );
    } else if (permissionData.permission === 'granted') {
      sendTokenToServer(permissionData.deviceToken);
    }
    window.removeEventListener('click', ask);
  };
  window.addEventListener('click', ask);
}

async function refreshToken(token) {
  const oldEnabledToken = getEnabledToken();
  if (oldEnabledToken) {
    await revokeTokenFromServer(oldEnabledToken);
  }
  markTokenDisabled();

  if (token) {
    await enablePushToken(token);
    markTokenEnabled(token);
  }
}

function sendTokenToServer(token) {
  if (!isTokenEnabled(token)) {
    return enablePushToken(token).then(() => {
      markTokenEnabled(token);
    });
  }
  return Promise.resolve();
}

function revokeTokenFromServer(token) {
  if (isTokenEnabled(token)) {
    return disablePushToken(token).then(() => {
      markTokenDisabled();
    });
  }
  return Promise.resolve();
}

function isSafari() {
  return 'safari' in window && 'pushNotification' in window.safari;
}

// API

function enablePushToken(token) {
  return http.rpc.post('/enablePushToken', {
    pushToken: token,
    deliveryType: getTokenType(),
  });
}

function disablePushToken(token) {
  return http.rpc.post('/disablePushToken', {
    pushToken: token,
    deliveryType: getTokenType(),
  });
}

function getTokenType() {
  if (isSafari()) {
    return SAFARI_MESSAGING_DELIVERY_TYPE;
  }
  return FIREBASE_MESSAGING_DELIVERY_TYPE;
}

// LocalStorage

function markTokenEnabled(token) {
  if (localStorage) {
    localStorage.setItem('enabledPushToken', token);
  }
}

function markTokenDisabled() {
  if (localStorage) {
    localStorage.removeItem('enabledPushToken');
  }
}

function isTokenEnabled(token) {
  if (localStorage) {
    return localStorage.getItem('enabledPushToken') === token;
  }
  return false;
}

function getEnabledToken() {
  if (localStorage) {
    return localStorage.getItem('enabledPushToken');
  }
  return null;
}
