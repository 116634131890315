import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import ModalWindow from '../../components/modal_window';
import Input from '../../components/input';
import DropZone from '../../components/dropzone';
import Button from '../../components/button';
import { EditWarningWindow } from './warning-windows';

import http from '../../rest';

import { DOCUMENT_DESCRIPTION_MAX_LENGTH, DOCUMENT_TITLE_MAX_LENGTH } from '../../constants/documents';
import { REQUEST_APPROVEMENT, REQUEST_TASK } from '../../constants/request_types';
import { SHOW_UPDATE_WARNING } from '../../constants/permissions';
import { APPROVING } from '../../constants/statuses';

import { useNotifyListDataUpdateWithDocPackage } from '../../hooks/use_notify_list_data_update';

import differenceBy from 'lodash/fp/differenceBy';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import reject from 'lodash/fp/reject';

const FILES_TYPES = {
  ENG: 'ENG',
  RU: 'RU',
};

function UpdateRequest({ type, title, show, documentPackageId, onClose, documentPackage, submitAsApprove = false }) {
  const alert = useAlert();
  const intl = useIntl();
  const [myRequestDescription, setMyRequestDescription] = useState(documentPackage.description);
  const [myRequestDescriptionEn, setMyRequestDescriptionEn] = useState(documentPackage.description_en);
  const [myRequestTitle, setMyRequestTitle] = useState(documentPackage.title);
  const [myRequestTitleEn, setMyRequestTitleEn] = useState(documentPackage.title_en);
  const [newFilesRu, setNewFilesRu] = useState([]);
  const [removedFilesRu, setRemovedFilesRu] = useState([]);
  const [newFilesEng, setNewFilesEng] = useState([]);
  const [removedFilesEng, setRemovedFilesEng] = useState([]);

  const [sendingData, setSendingData] = useState(false);

  const [canShowWarning, setCanShowWarning] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [needShowWarning, setNeedShowWarning] = useState(canShowWarning);

  const notify = useNotifyListDataUpdateWithDocPackage(documentPackageId);

  useEffect(() => {
    setNewFilesRu([]);
    setRemovedFilesRu([]);
    setNewFilesEng([]);
    setRemovedFilesEng([]);
    setMyRequestDescription(documentPackage.description);
    setMyRequestDescriptionEn(documentPackage.description_en);
    setMyRequestTitle(documentPackage.title);
    setMyRequestTitleEn(documentPackage.title_en);
    // eslint-disable-next-line
  }, [documentPackageId, show]);

  useEffect(() => {
    const permissions = documentPackage.permissions || [];
    const csw = permissions.includes(SHOW_UPDATE_WARNING);
    setCanShowWarning(csw);
    setNeedShowWarning(csw);

    // eslint-disable-next-line
  }, [documentPackageId, documentPackage.permissions]);

  useEffect(() => {
    if (show && needShowWarning) {
      setShowWarning(true);
    }
  }, [show, needShowWarning]);

  // recently added files and latelly attached without removed
  const filesRu = [...newFilesRu, ...differenceBy('id', documentPackage.documentsRu, removedFilesRu)];
  const filesEng = [...newFilesEng, ...differenceBy('id', documentPackage.documentsEng, removedFilesEng)];

  const updateMyRequestDescription = useCallback(() => {
    if (sendingData) {
      return;
    }

    setSendingData(true);

    let method;
    switch (type) {
      case REQUEST_APPROVEMENT:
        method = '/updateDocumentPackage';
        break;
      case REQUEST_TASK:
        method = '/updateTask';
        break;
      default:
        method = '/updateDocumentPackage';
    }
    http.rpc
      .post(method, {
        ...documentPackage,
        status: submitAsApprove ? APPROVING : documentPackage.status,
        id: documentPackageId,
        title: myRequestTitle,
        title_en: myRequestTitleEn,
        description: myRequestDescription,
        description_en: myRequestDescriptionEn,
        executor: undefined,
        executors: documentPackage.executors ? documentPackage.executors.map(({ id }) => id) : undefined,
        watchers: documentPackage.watchers ? documentPackage.watchers.map(({ id }) => id) : undefined,
        documentsRu: filesRu.map(({ id }) => id),
        documentsEng: filesEng.map(({ id }) => id),
      })
      .then(res => {
        setSendingData(false);
        const { data, status } = res;
        if (data.code === 500 || status === false) {
          alert.error(data.message);
        } else {
          setNewFilesRu([]);
          setRemovedFilesRu([]);
          setNewFilesEng([]);
          setRemovedFilesEng([]);
          onClose();
          notify();
        }
      })
      .catch(err => {
        alert.error(err.message);
        setSendingData(false);
      });

    // eslint-disable-next-line
  }, [
    documentPackageId,
    documentPackage,
    myRequestDescription,
    setMyRequestDescriptionEn,
    myRequestTitle,
    myRequestTitleEn,
    filesRu,
    filesEng,
  ]);

  const handleFileAdd = type => fs => {
    switch (type) {
      case FILES_TYPES.ENG:
        setNewFilesEng(newFilesEng.concat(fs));
        break;
      case FILES_TYPES.RU:
        setNewFilesRu(newFilesRu.concat(fs));
        break;
      default:
    }
  };

  const handleFileRemove = type => fileId => {
    switch (type) {
      case FILES_TYPES.ENG: {
        // remove from recently added files
        setNewFilesEng(reject({ id: fileId }, newFilesEng));

        // add to removed files if it was previously attached to the decision documents
        const removedFile = find({ id: fileId }, documentPackage.documentsEng);
        if (!isEmpty(removedFile)) {
          setRemovedFilesEng(rf => [...rf, removedFile]);
        }
        break;
      }
      case FILES_TYPES.RU: {
        // remove from recently added files
        setNewFilesRu(reject({ id: fileId }, newFilesRu));

        // add to removed files if it was previously attached to the decision documents
        const removedFile = find({ id: fileId }, documentPackage.documentsRu);
        if (!isEmpty(removedFile)) {
          setRemovedFilesRu(rf => [...rf, removedFile]);
        }
        break;
      }
      default:
    }
  };

  return show ? (
    <>
      <ModalWindow onClose={onClose}>
        <div className="update-decision">
          <div className="update-decision__header">{title}</div>

          <div className="update-decision__form__section_label">
            {intl.formatMessage({ id: 'updateRequest.section.title' })}
          </div>
          <Input
            placeholder={intl.formatMessage({ id: 'updateRequest.section.title.inRussian' })}
            className="update-decision__input"
            onChange={setMyRequestTitle}
            value={myRequestTitle}
            autoFocus={true}
            maxLength={DOCUMENT_TITLE_MAX_LENGTH}
            counter={true}
            floatingLabel={true}
          />
          <Input
            placeholder={intl.formatMessage({ id: 'updateRequest.section.title.inEnglish' })}
            className="update-decision__input"
            onChange={setMyRequestTitleEn}
            value={myRequestTitleEn}
            autoFocus={true}
            maxLength={DOCUMENT_TITLE_MAX_LENGTH}
            counter={true}
            floatingLabel={true}
          />

          <div className="update-decision__form__section_label">
            {intl.formatMessage({ id: 'updateRequest.section.description' })}
          </div>
          <Input
            placeholder={intl.formatMessage({ id: 'updateRequest.section.description.inRussian' })}
            className="update-decision__input"
            onChange={setMyRequestDescription}
            value={myRequestDescription}
            maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
            counter={true}
            floatingLabel={true}
          />
          <Input
            placeholder={intl.formatMessage({ id: 'updateRequest.section.description.inEnglish' })}
            className="update-decision__input"
            onChange={setMyRequestDescriptionEn}
            value={myRequestDescriptionEn}
            maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
            counter={true}
            floatingLabel={true}
          />

          <DropZone
            title={intl.formatMessage({ id: 'updateRequest.section.documents.inRussian' })}
            onFileAddExt={files => handleFileAdd(FILES_TYPES.RU)(files)}
            onFileRemove={file => handleFileRemove(FILES_TYPES.RU)(file)}
            initialFiles={filesRu}
            multiple={true}
            fileListMaxHeight="150px"
          />
          <DropZone
            title={intl.formatMessage({ id: 'updateRequest.section.documents.inEnglish' })}
            onFileAddExt={files => handleFileAdd(FILES_TYPES.ENG)(files)}
            onFileRemove={file => handleFileRemove(FILES_TYPES.ENG)(file)}
            initialFiles={filesEng}
            multiple={true}
            fileListMaxHeight="150px"
          />

          <Button
            color="blue"
            size="normal"
            onClick={updateMyRequestDescription}
            className="update-decision__button"
            loading={sendingData}>
            <div className="update-decision__actions_approve">{intl.formatMessage({ id: 'updateRequest.send' })}</div>
          </Button>
        </div>
      </ModalWindow>
      <EditWarningWindow
        show={showWarning && needShowWarning}
        title={title}
        onClose={() => {
          setShowWarning(false);
          onClose();
        }}
        onAccept={() => {
          setShowWarning(false);
          setNeedShowWarning(false);
        }}
      />
    </>
  ) : null;
}

export default UpdateRequest;
