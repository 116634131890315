import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { useDropzone } from 'react-dropzone';
import Loader from '../loader';

import http from '../../rest';
import formatBytesToSize from '../../utils/file_size_utils';
import { FILE_ACCEPT } from '../../constants/file_accept';

import docIcon from '../../images/doc-small.svg';
import closeIcon from '../../images/x-small.svg';

import './dropzone.scss';

function DropZone({
  title,
  onFileAdd,
  onFileRemove,
  initialFiles,
  multiple,
  onFileAddExt,
  fileListMaxHeight: maxHeight,
  error,
}) {
  const intl = useIntl();

  const [files, setFiles] = useState(initialFiles);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setFiles(initialFiles);
  }, [initialFiles]);

  const onDrop = useCallback(
    acceptedFiles => {
      const uploadFiles = acceptedFiles.slice(0, multiple ? acceptedFiles.length : 1);
      const calls = [];
      const info = [];

      uploadFiles.forEach(file => {
        const { name, size } = file;
        if (!files.find(el => el.name === name)) {
          info.push({ name, size });
          const formData = new FormData();
          formData.append('name', name);
          formData.append('type', name.split('.').pop());
          formData.append('file', file);
          calls.push(http.api.post('/document/upload', formData));
        }
      });
      if (calls.length) {
        setIsUploading(true);
        Promise.all(calls)
          .then(results => {
            const addFiles = results.map((res, i) => Object.assign({}, info[i], { id: res.data.id }));
            setFiles([...files, ...addFiles]);
            results.forEach(res => {
              onFileAdd(res.data.id);
            });
            onFileAddExt(results.map(res => res.data));
          })
          .catch(console.log)
          .finally(() => {
            setIsUploading(false);
          });
      }
    },
    [files, onFileAdd, onFileAddExt, multiple]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: FILE_ACCEPT,
    onDrop,
    multiple,
  });

  const removeFile = file => {
    onFileRemove(file.id);
  };

  const acceptedFilesItems = files.map((file, index) => (
    <div key={`${file.name}_${index}`} className="dropzone__file">
      <img src={docIcon} alt="docIcon" />
      <span className="dropzone__file__name">{file.name || file.filename || file.fileName}</span>
      {!!file.size && <span className="dropzone__file__size">{formatBytesToSize(file.size)}</span>}
      <img className="dropzone__file__close" src={closeIcon} alt="closeIcon" onClick={() => removeFile(file)} />
    </div>
  ));
  const listStyle = { maxHeight };
  return (
    <div className="container">
      <div className="dropzone__header">
        <span className="dropzone__header__title">{title || intl.formatMessage({ id: 'dropzone.files' })}</span>
        <span className="dropzone__header__count">{files.length ? files.length : ''}</span>
      </div>
      <div className="dropzone__list" style={listStyle}>
        {acceptedFilesItems}
      </div>
      <section className={`dropzone dropzone__section dropzone__border ${error ? ' --error' : ''}`}>
        {isUploading && (
          <div className="dropzone__uploading">
            <Loader />
          </div>
        )}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <span className="dropzone__text">
            {intl.formatMessage({ id: 'dropzone.dropHere' })}{' '}
            <span style={{ color: '#244a68' }}>{intl.formatMessage({ id: 'dropzone.clickToSelect' })}</span>
          </span>
        </div>
      </section>
    </div>
  );
}

DropZone.propTypes = {
  title: PropTypes.string,
  onFileAdd: PropTypes.func,
  onFileRemove: PropTypes.func.isRequired,
  onFileAddExt: PropTypes.func,
  initialFiles: PropTypes.array,
  multiple: PropTypes.bool,
  fileListMaxHeight: PropTypes.string,
  error: PropTypes.string,
};

DropZone.defaultProps = {
  title: '',
  initialFiles: [],
  multiple: false,
  onFileAdd: _ => _,
  onFileAddExt: _ => _,
  fileListMaxHeight: 'auto',
  error: '',
};

export default DropZone;
