import React from 'react';

const ClockIcon = () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1855 0.882812H5.93555V2.63281H11.1855V0.882812ZM7.68555 12.2578H9.43555V7.00781H7.68555V12.2578ZM14.7118 6.46531L15.9543 5.22281C15.578 4.77656 15.1668 4.35656 14.7205 3.98906L13.478 5.23156C12.1218 4.14656 10.4155 3.49906 8.56055 3.49906C4.2118 3.49906 0.685547 7.02531 0.685547 11.3741C0.685547 15.7228 4.20305 19.2491 8.56055 19.2491C12.918 19.2491 16.4355 15.7228 16.4355 11.3741C16.4355 9.52781 15.788 7.82156 14.7118 6.46531ZM8.56055 17.5078C5.1743 17.5078 2.43555 14.7691 2.43555 11.3828C2.43555 7.99656 5.1743 5.25781 8.56055 5.25781C11.9468 5.25781 14.6855 7.99656 14.6855 11.3828C14.6855 14.7691 11.9468 17.5078 8.56055 17.5078Z"
      fill="#EC5A40"
    />
  </svg>
);

export default ClockIcon;
