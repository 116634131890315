const formatUserName = (user, locale = 'ru') => {
  const suffix = locale === 'ru' ? '' : `_${locale}`;
  return (
    (user[`firstName${suffix}`] &&
      user[`lastName${suffix}`] &&
      `${user[`lastName${suffix}`]} ${user[`firstName${suffix}`] ? `${user[`firstName${suffix}`][0]}.` : ''}${
        user[`middleName${suffix}`] ? `${user[`middleName${suffix}`][0]}.` : ''
      }`) ||
    ''
  );
};

export default formatUserName;
