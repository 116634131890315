import React, { useState, useCallback, useContext } from 'react';

import { useIntl, FormattedHTMLMessage } from 'react-intl';

import NewPackage from '../new_package';
import { NewPackageSkipApprove } from '../new_package/new-package-skip-approve';
import NewTask from '../new_task';

import { ROLE_INITIATOR, ROLE_APPROVER, ROLE_FINAL_APPROVER } from '../../constants/roles';
import { URL_INBOX } from '../../constants/urls';
import { UserStoreContext } from '../../store/user/store';
import http from '../../rest';
import { useNotifyListDataUpdate } from '../../hooks/use_notify_list_data_update';

import './new-request.scss';

function NewRequest({ history, match }) {
  const intl = useIntl();

  const { id } = match.params;
  const user = useContext(UserStoreContext);
  const { role } = user;
  const [onCloseListeners, setOnCloseListeners] = useState([]);
  const [blockCloseButton, setBlockCloseButton] = useState(false);
  const notify = useNotifyListDataUpdate();

  const isInbox = match.path.indexOf(URL_INBOX) === 0;
  const isNewPackageSkipApprove = isInbox && ROLE_FINAL_APPROVER === role;

  const addOnCloseListener = useCallback(listener => {
    if (typeof listener === 'function') {
      setOnCloseListeners(listeners => [...listeners, listener]);
    }
  }, []);

  const removeOnCloseListener = useCallback(listener => {
    if (typeof listener === 'function') {
      setOnCloseListeners(listeners => listeners.filter(l => l !== listener));
    }
  }, []);

  const close = useCallback(() => {
    if (blockCloseButton) {
      return;
    }
    setBlockCloseButton(true);
    Promise.all(onCloseListeners.map(l => l()))
      .then(() => {
        setBlockCloseButton(false);
        notify();
        history.goBack();
      })
      .catch(() => {
        setBlockCloseButton(false);
      });
  }, [blockCloseButton, history, onCloseListeners, notify]);

  const deleteDraftDocumentPackage = useCallback(
    () =>
      http.rpc.post(`/deleteDraftDocumentPackage`, {
        id,
      }),
    [id]
  );

  return (
    <div className="new-request-layout">
      <div className="new-request-container">
        <div className="new-request-container__shutter" onClick={close} />
        {isNewPackageSkipApprove ? (
          <div className="new-request-container__header">
            <FormattedHTMLMessage
              id="newPackage.skipApprove.modal.title"
              values={{
                br: () => <br />,
              }}
            />
          </div>
        ) : (
          <div className="new-request-container__header">{intl.formatMessage({ id: 'newPackage.modal.title' })}</div>
        )}

        {[ROLE_INITIATOR, ROLE_APPROVER].includes(role) ? (
          <NewPackage
            addOnCloseListener={addOnCloseListener}
            removeOnCloseListener={removeOnCloseListener}
            deleteDraft={deleteDraftDocumentPackage}
          />
        ) : isNewPackageSkipApprove ? (
          <NewPackageSkipApprove
            addOnCloseListener={addOnCloseListener}
            removeOnCloseListener={removeOnCloseListener}
            deleteDraft={deleteDraftDocumentPackage}
          />
        ) : (
          <NewTask
            addOnCloseListener={addOnCloseListener}
            removeOnCloseListener={removeOnCloseListener}
            deleteDraft={deleteDraftDocumentPackage}
          />
        )}
      </div>
    </div>
  );
}

export default NewRequest;
