import { useCallback } from 'react';

import { useDataNotify } from '../rest/client';

export function useNotifyListDataUpdate() {
  const { notify: inActualRequestNotify } = useDataNotify('rpc', 'post', '/getIncomingFixedDocumentPackages', {});

  const { notify: inWorkedRequestNotify } = useDataNotify('rpc', 'post', '/getIncomingPagedDocumentPackages', {});

  const { notify: outWorkedRequestNotify } = useDataNotify('rpc', 'post', '/getOutgoingPagedDocumentPackages', {});

  const { notify: outActualRequestNotify } = useDataNotify('rpc', 'post', '/getOutgoingFixedDocumentPackages', {});

  const { notify: readOnlyActualRequestNotify } = useDataNotify('rpc', 'post', '/getWatchingDocumentPackages', {});

  const notify = useCallback(() => {
    inActualRequestNotify();
    inWorkedRequestNotify();
    outWorkedRequestNotify();
    outActualRequestNotify();
    readOnlyActualRequestNotify();
    // eslint-disable-next-line
  }, []);

  return notify;
}

export function useNotifyListDataUpdateWithDocPackage(documentPackageId) {
  const notifyList = useNotifyListDataUpdate();

  const { notify: packageRequestNotify } = useDataNotify('rpc', 'post', '/getDocumentPackage', {
    id: documentPackageId,
  });

  const notify = useCallback(() => {
    notifyList();
    packageRequestNotify();
    // eslint-disable-next-line
  }, [documentPackageId]);

  return notify;
}

export function useNotifyDocumentPackageCounts() {
  const { notify } = useDataNotify('rpc', 'post', '/getDetailedDocumentPackageCounts', {});
  return notify;
}
