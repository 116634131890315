import React, { useEffect, useState } from 'react';
import './three-dots-menu.scss';
import PropTypes from 'prop-types';

function ThreeDotsMenu({ options, onSelect, small }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const closeThreeDotsMenu = () => {
      document.removeEventListener('click', closeThreeDotsMenu);
      setVisible(false);
    };
    if (visible) {
      document.addEventListener('click', closeThreeDotsMenu);
    }
    return function cleanup() {
      document.removeEventListener('click', closeThreeDotsMenu);
    };
  }, [visible]);
  if (options.length === 0) {
    return null;
  }
  return (
    <div
      className={'three-dots-menu' + (visible ? ' --active' : '') + (small ? ' --small' : '')}
      onClick={() => setVisible(true)}>
      {visible && (
        <div className="three-dots-menu__list">
          {options.map(({ key, text }) => (
            <div className="three-dots-menu__item" key={key} onClick={() => onSelect(key)}>
              {text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

ThreeDotsMenu.defaultProps = {
  options: [],
  onSelect: _ => _,
  small: false,
};
ThreeDotsMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  onSelect: PropTypes.func,
  small: PropTypes.bool,
};
export default ThreeDotsMenu;
