import { IAccountDTO } from './Account';
import { IDocumentDTO } from './Document';
import { IDecisionDTO } from './Decision';
import { IUserDetails, UserRole } from './User';

export interface IDocumentPackageDTO {
  id?: number;

  title: string;
  title_en: string;

  description: string;
  description_en: string;

  status: DocumentPackageStatus;
  businessStatus?: DocumentPackageBusinessStatus;

  documentsRu?: IDocumentDTO[];
  documentsEng?: IDocumentDTO[];
  removedDocuments?: IDocumentDTO[];

  createdDate: number | string;
  lastStartedDate: number | string;
  lastModifiedDate?: number | string;

  createdBy?: IAccountDTO;
  lastModifiedBy?: IUserDetails;

  approvers?: IAccountDTO[];
  finalApprover?: IAccountDTO;
  watchers: IAccountDTO[];

  decisions?: IDecisionDTO[];
  permissions: DocumentPackagePermission[];

  isArchived: boolean;

  /* @deprecated Use executors instead */
  executor?: IAccountDTO;
  executors?: IAccountDTO[];

  type: DocumentPackageType;

  unreadPostsCount: number;
  totalPostsCount: number;

  oneForEachExecutor: boolean;
}

export interface IOfflineDocumentPackageDTO {
  title: string;
  title_en: string;

  description: string;
  description_en: string;

  comment: string;
  comment_en: string;

  documents?: IDocumentDTO[];

  watchers?: IAccountDTO[];
  initiator?: IAccountDTO;
}

export type DocumentPackageStatus =
  /* Черновк */
  | 'DRAFT'
  /* На согласовании (для утверждений и поручений) */
  | 'APPROVING'
  /* На утверждении (для утверждений) */
  | 'APPROVED'
  /* Отклонено (для утверждений) */
  | 'REJECTED'
  /* Утвержденно (для утверждений) */
  | 'FINAL_APPROVED'
  /* Не утвержденно (для утверждений) */
  | 'FINAL_REJECTED'
  /* Удален */
  | 'DELETED'
  /* В работе (для поручений) */
  | 'EXECUTING'
  /* В доработке */
  | 'REWORKING'
  /* Завершено (для поручений) */
  | 'EXECUTED';

export type DocumentPackageBusinessStatus =
  /* Не определен */
  | 'UNDEFINED'
  | Extract<
      | 'DRAFT'
      | 'DELETED'
      | 'APPROVING'
      | 'APPROVED'
      | 'REJECTED'
      | 'FINAL_APPROVED'
      | 'FINAL_REJECTED'
      | 'EXECUTING'
      | 'REWORKING'
      | 'EXECUTED',
      DocumentPackageStatus
    >
  /* Ожидает моего ответа */
  | 'MY_RESPONSE'
  /* Ожидает моего решения */
  | 'MY_APPROVING';

export type DocumentPackageType =
  /* Утверждение */
  | 'APPROVEMENT'
  /* Поручение */
  | 'TASK';

export type DocumentPackagePermission =
  /* Редактирование(добавление и удаление) документов */
  /* @deprecated Removed */
  | 'ADD_DOCUMENT'
  /* Изменение своего комментария в решении */
  | 'UPDATE_MY_DECISION_COMMENT'
  /* Просмотр чата */
  | 'VIEW_CHAT'
  /* Изменение описания поручения/пакета документов */
  | 'UPDATE_DESCRIPTION'
  /* Разрешение на просмотр листа согласования */
  | 'VIEW_APPROVAL_SHEET'
  /* Разрешение на просмотр решений */
  | 'VIEW_DECISIONS'
  /* Разрешение на показ предупреждения об опасности обновления задачи на согласовании */
  | 'SHOW_UPDATE_WARNING'
  /* Разрешение брать пакет/поручение на доработку */
  | 'TAKE_FOR_REWORK'
  /* Необходимость отобразить для данного пользователя поле ввода английского комментария к решению */
  | 'INPUT_ENGLISH_COMMENT'
  /* Отображение кнопки "Утвердить без согласования" */
  | 'FINAL_APPROVE_WITH_SKIPPED_APPROVERS';

// Helpers

type RoleStatusMap = {
  [role in UserRole]: { [status in DocumentPackageBusinessStatus]: string };
};

const defaultBusinessStatusMap: { [status in DocumentPackageBusinessStatus]: string } = {
  APPROVED: 'documentPackage.businessStatus.APPROVED',
  APPROVING: 'documentPackage.businessStatus.APPROVING',
  DELETED: 'documentPackage.businessStatus.DELETED',
  DRAFT: 'documentPackage.businessStatus.DRAFT',
  EXECUTED: 'documentPackage.businessStatus.EXECUTED',
  EXECUTING: 'documentPackage.businessStatus.EXECUTING',
  FINAL_APPROVED: 'documentPackage.businessStatus.FINAL_APPROVED',
  FINAL_REJECTED: 'documentPackage.businessStatus.FINAL_REJECTED',
  MY_APPROVING: 'documentPackage.businessStatus.MY_APPROVING',
  MY_RESPONSE: 'documentPackage.businessStatus.MY_RESPONSE',
  REJECTED: 'documentPackage.businessStatus.REJECTED',
  REWORKING: 'documentPackage.businessStatus.REWORKING',
  UNDEFINED: 'documentPackage.businessStatus.UNDEFINED',
};
const roleBusinessStatusMap: RoleStatusMap = {
  ROLE_ROOT: defaultBusinessStatusMap,

  ROLE_FINAL_APPROVER: defaultBusinessStatusMap,

  ROLE_APPROVER: {
    ...defaultBusinessStatusMap,
    APPROVED: 'documentPackage.businessStatus.APPROVED.ROLE_APPROVER',
  },
  ROLE_INITIATOR: {
    ...defaultBusinessStatusMap,
    APPROVED: 'documentPackage.businessStatus.APPROVED.ROLE_INITIATOR',
  },
  ROLE_ADMIN: defaultBusinessStatusMap,
};

export function businessStatusToLabel(role: UserRole, status: DocumentPackageBusinessStatus): string {
  return roleBusinessStatusMap[role][status];
}
