import React from 'react';

import { useIntl } from 'react-intl';

import { SUPPORT_EMAIL } from '../../constants/support'; // SUPPORT_PHONE
// import { formatWithMask } from '../../utils/mask';
// import * as phoneCodes from '../../constants/phone_codes';
// import phoneMasks from '../../constants/phone_masks';

import './support.scss';

function Support() {
  const intl = useIntl();

  // let mask = '0';
  // let code = '0';
  // for (const c in phoneCodes) {
  //   if (SUPPORT_PHONE.indexOf(phoneCodes[c]) === 0) {
  //     mask = phoneMasks[phoneCodes[c]];
  //     code = phoneCodes[c];
  //     break;
  //   }
  // }
  // const phone = `+${code} ${formatWithMask(SUPPORT_PHONE.slice(code.length), mask)}`;
  return (
    <div className="support">
      <div className="support__block">
        <span className="support__block__header">{intl.formatMessage({ id: 'support.header' })}</span>
      </div>
      {/* HERMES-487: hide support phone
      <div className="support__block">
        <a href={`tel:${phone}`}>
          <div className="support__icon support__icon--phone" />
        </a>
        <span className="support__block__sub-header">
          Телефон центра поддержки по любым вопросам работы приложения
        </span>
        <a href={`tel:${phone}`} className="support__block__body">
          {phone}
        </a>
      </div> */}
      <div className="support__block">
        <a href={`mailto:${SUPPORT_EMAIL}`}>
          <div className="support__icon support__icon--mail" />
        </a>
        <span className="support__block__sub-header">{intl.formatMessage({ id: 'support.subheader' })}</span>
        <a href={`mailto:${SUPPORT_EMAIL}`} className="support__block__body">
          {SUPPORT_EMAIL}
        </a>
      </div>
    </div>
  );
}

export default Support;
