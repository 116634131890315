import React, { useEffect, useState } from 'react';
import http from '../../rest';
import { ApproveTypeEnum } from '../../containers/constants';
import RadioGroup from '../radio_group';

const URGENT_APPROVAL_HOURS = 'URGENT_APPROVAL_SPENT_HOURS_LIMIT';

interface IConfigurationData {
  data: IConfigurationListItem[];
  status: Boolean;
}

interface IConfigurationListItem {
  configuration: ConfigurationType;
  value: number;
}

type ConfigurationType = typeof URGENT_APPROVAL_HOURS;

const APPROVE_TYPE_LIST = [
  {
    label: 'Обычное согласование',
    name: ApproveTypeEnum.normal,
    value: ApproveTypeEnum.normal,
  },
  {
    label: `Срочное согласование`,
    name: ApproveTypeEnum.urgent,
    value: ApproveTypeEnum.urgent,
  },
  {
    label: 'Согласование не требуется',
    name: ApproveTypeEnum.skipped,
    value: ApproveTypeEnum.skipped,
  },
];

function updateApproveTypeListWithHours(radioList, hours) {
  const newList = [...radioList];
  const idx = newList.findIndex(item => item.value === ApproveTypeEnum.urgent);
  const newLabel = (
    <span>
      {newList[idx].label}
      <span style={{ color: '#a1a6a3' }}> ({hours} часа)</span>
    </span>
  );
  newList.splice(idx, 1, { ...newList[idx], label: newLabel });

  return newList;
}

function ApproveTypeRadioGroup({ onChange, approveType }) {
  const [approveTypeList, setApproveTypeList] = useState(APPROVE_TYPE_LIST);

  useEffect(() => {
    http.rpc.post('/getConfiguration', {}).then((resp: IConfigurationData) => {
      const hours = resp.data.find(item => item.configuration === URGENT_APPROVAL_HOURS)?.value;
      setApproveTypeList(updateApproveTypeListWithHours(APPROVE_TYPE_LIST, hours));
    });
  }, []);

  return <RadioGroup currentRadioValue={approveType} onChange={onChange} list={approveTypeList} />;
}

export default ApproveTypeRadioGroup;
