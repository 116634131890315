export const UPDATE_MY_DECISION_COMMENT = 'UPDATE_MY_DECISION_COMMENT';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const VIEW_CHAT = 'VIEW_CHAT';
export const VIEW_APPROVAL_SHEET = 'VIEW_APPROVAL_SHEET';
export const VIEW_DECISIONS = 'VIEW_DECISIONS';
export const SHOW_UPDATE_WARNING = 'SHOW_UPDATE_WARNING';
export const TAKE_FOR_REWORK = 'TAKE_FOR_REWORK';
export const INPUT_ENGLISH_COMMENT = 'INPUT_ENGLISH_COMMENT';
export const FINAL_APPROVE_WITH_SKIPPED_APPROVERS = 'FINAL_APPROVE_WITH_SKIPPED_APPROVERS';

export type DocumentPackagePermission =
  /* Изменение своего комментария в решении */
  | 'UPDATE_MY_DECISION_COMMENT'
  /* Просмотр чата */
  | 'VIEW_CHAT'
  /* Изменение описания поручения/пакета документов */
  | 'UPDATE_DESCRIPTION'
  /* Разрешение на просмотр листа согласования */
  | 'VIEW_APPROVAL_SHEET'
  /* Разрешение на просмотр решений */
  | 'VIEW_DECISIONS'
  /* Разрешение на показ предупреждения об опасности обновления задачи на согласовании */
  | 'SHOW_UPDATE_WARNING'
  /* Разрешение на самостоятельное взятие на доработку */
  | 'TAKE_FOR_REWORK'
  /* Необходимость отобразить для данного пользователя поле ввода английского комментария к решению */
  | 'INPUT_ENGLISH_COMMENT'
  /* Отображение кнопки "Утвердить без согласования" */
  | 'FINAL_APPROVE_WITH_SKIPPED_APPROVERS';
