import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import { getExtension } from '../../utils/get_extension';
import formatBytesToSize from '../../utils/file_size_utils';
import { getFileURL } from '../../utils/get_document_url';
import FileInTab from '../file_in_tab';

import debounce from 'lodash/fp/debounce';

import './document-preview-vital-list.scss';

const MARK_VIEWED_TIMEOUT = 500;

function DocumentPreviewVitalList({ documents: pristineDocuments, title, newLabel, limit, direction }) {
  const intl = useIntl();

  const [documents, setDocuments] = useState(pristineDocuments || []);

  useEffect(() => {
    setDocuments(pristineDocuments);
  }, [pristineDocuments]);

  const markViewed = useCallback(
    debounce(MARK_VIEWED_TIMEOUT, docId => {
      setDocuments(docs =>
        docs.map(d => {
          if (d.id === docId) {
            d.new = false;
          }
          return d;
        })
      );
    }),
    [setDocuments]
  );

  if (documents.length === 0) {
    return null;
  }
  const visibleDocuments = documents.slice(0, limit || documents.length);
  return (
    <div className={`document-preview-vital-list --direction-${direction}`}>
      <div className="document-preview-vital-list__title">{title || ''}</div>
      {visibleDocuments.map(doc => {
        const extension = getExtension(doc.originalFileId);
        const isRemoved = Boolean(doc.deletedDate);
        const isNew = Boolean(doc.new);
        return (
          <div className="document-preview-vital-list__elem" key={doc.id}>
            <div className={`document-preview-vital-list__icon --${extension} ${isRemoved ? '--removed' : ''}`}>
              {isNew && <div className="document-preview-vital-list__icon__new">{newLabel}</div>}
            </div>
            <div className="document-preview-vital-list__info">
              <div onClick={() => markViewed(doc.id)}>
                <FileInTab className="document-preview-vital-list__name" doc={doc} />
              </div>
              <div className="document-preview-vital-list__size">{formatBytesToSize(doc.size, intl.locale)}</div>
              <a
                className="document-preview-vital-list__download"
                href={getFileURL(doc.originalFileId)}
                download
                onClick={() => markViewed(doc.id)}>
                {intl.formatMessage({
                  id: 'documentPreviewVitalList.download',
                })}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}
DocumentPreviewVitalList.propTypes = {
  documents: PropTypes.array.isRequired,
  title: PropTypes.string,
  newLabel: PropTypes.string,
  limit: PropTypes.number,
  direction: PropTypes.oneOf(['row', 'column']),
};

DocumentPreviewVitalList.defaultProps = {
  title: '',
  newLabel: '',
  limit: 0,
  direction: 'column',
};

export default DocumentPreviewVitalList;
