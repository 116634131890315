import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import CommentsBlock, {
  emptyCommentsErrors,
  isCommentsValid,
  validateCommentsWith,
} from '../../components/comments_block';
import ModalWindow from '../../components/modal_window';
import DropZone from '../../components/dropzone';
import Button from '../../components/button';
import { EditWarningWindow } from './warning-windows';

import http from '../../rest';
import { IDocumentDTO } from '../../rest/dto/Document';
import { REJECTED } from '../../constants/statuses';
import { SHOW_UPDATE_WARNING } from '../../constants/permissions';
import { REQUEST_TASK } from '../../constants/request_types';

import { useNotifyListDataUpdateWithDocPackage } from '../../hooks/use_notify_list_data_update';

import differenceBy from 'lodash/fp/differenceBy';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import reject from 'lodash/fp/reject';

function UpdateDecision({ title, show, onClose, documentPackage, user }) {
  const alert = useAlert();
  const intl = useIntl();

  const decision = documentPackage.decisions.find(({ user: u }) => u.id === user.id) || {};
  const [comment, setComment] = useState(decision.comment || '');
  const [commentEn, setCommentEn] = useState(decision.comment_en || '');

  const [newFiles, setNewFiles] = useState<IDocumentDTO[]>([]);
  const [removedFiles, setRemovedFiles] = useState<IDocumentDTO[]>([]);

  const [sendingData, setSendingData] = useState(false);

  const notify = useNotifyListDataUpdateWithDocPackage(documentPackage.id);

  const [canShowWarning, setCanShowWarning] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [needShowWarning, setNeedShowWarning] = useState(canShowWarning);

  const [errors, setErrors] = useState(emptyCommentsErrors());

  const isExecutor = Boolean(documentPackage.executors.find(e => e.id === user.id));

  const isCommentRequired = decision.status === REJECTED || (isExecutor && documentPackage.type === REQUEST_TASK);
  const needMultipleLanguageComments =
    user.role === 'ROLE_FINAL_APPROVER' || (user.role === 'ROLE_INITIATOR' && !user.corporateApprover);

  useEffect(() => {
    setNewFiles([]);
    setRemovedFiles([]);
    setComment(decision.comment);
    setCommentEn(decision.comment_en);
    // eslint-disable-next-line
  }, [documentPackage.id, show]);

  useEffect(() => {
    const permissions = documentPackage.permissions || [];
    const csw = permissions.includes(SHOW_UPDATE_WARNING);
    setCanShowWarning(csw);
    setNeedShowWarning(csw);

    // eslint-disable-next-line
  }, [documentPackage.id, documentPackage.permissions]);

  useEffect(() => {
    if (show && needShowWarning) {
      setShowWarning(true);
    }
  }, [show, needShowWarning]);

  const updateMyDecisionComment = useCallback(() => {
    if (sendingData || !isCommentsValid(errors)) {
      return;
    }

    setSendingData(true);

    http.rpc
      .post('/updateDecision', {
        documentPackageId: documentPackage.id,
        comment,
        comment_en: commentEn,
        documents: newFiles.map(({ id }) => id),
        removedDocuments: removedFiles.map(({ id }) => id),
      })
      .then(res => {
        setSendingData(false);
        const { data, status } = res;
        if (data.code === 500 || status === false) {
          alert.error(data.message);
        } else {
          setNewFiles([]);
          setRemovedFiles([]);
          onClose();
          notify();
        }
      })
      .catch(err => {
        alert.error(err.message);
        setSendingData(false);
      });

    // eslint-disable-next-line
  }, [documentPackage.id, comment, commentEn, newFiles, errors]);

  const handleFileAdd = fs => {
    setNewFiles([...newFiles, ...fs]);
  };

  const handleFileRemove = fileId => {
    // remove from recently added files
    setNewFiles(reject({ id: fileId }, newFiles));

    // add to removed files if it was previously attached to the decision documents
    const removedFile = find({ id: fileId }, decision.documents);
    if (!isEmpty(removedFile)) {
      setRemovedFiles(rf => [...rf, removedFile]);
    }
  };

  useEffect(() => {
    const validateComments = validateCommentsWith({ emptyField: intl.formatMessage({ id: 'input.error.emptyField' }) });
    setErrors(validateComments({ comment, commentEn, isCommentRequired, needMultipleLanguageComments }));
  }, [comment, commentEn, isCommentRequired, needMultipleLanguageComments, intl]);

  // recently added files and latelly attached without removed
  const files = [...newFiles, ...differenceBy('id', decision.documents as IDocumentDTO[], removedFiles)];

  return show ? (
    <>
      <ModalWindow onClose={onClose}>
        <div className="update-decision">
          <div className="update-decision__header">{title}</div>

          {needMultipleLanguageComments ? (
            <CommentsBlock
              type="MULTIPLE"
              defaultComment={comment}
              defaultCommentEn={commentEn}
              onCommentChange={setComment}
              onCommentEnChange={setCommentEn}
              commentError={errors.comment}
              commentEnError={errors.commentEn}
              autoFocus={true}
            />
          ) : (
            <CommentsBlock
              type="SINGLE"
              placeholder={intl.formatMessage({ id: 'takeForRework.comment' })}
              defaultComment={comment}
              onCommentChange={setComment}
              commentError={errors.comment}
              autoFocus={true}
            />
          )}

          <DropZone
            title={intl.formatMessage({ id: 'takeForRework.attachDocuments' })}
            onFileAddExt={fs => handleFileAdd(fs)}
            onFileRemove={file => handleFileRemove(file)}
            initialFiles={files}
            multiple={true}
            fileListMaxHeight="150px"
          />
          <Button
            color="blue"
            size="normal"
            onClick={updateMyDecisionComment}
            className="update-decision__button"
            loading={sendingData}>
            <div className="update-decision__actions_approve">
              {intl.formatMessage({ id: 'takeForRework.button.send' })}
            </div>
          </Button>
        </div>
      </ModalWindow>
      <EditWarningWindow
        show={showWarning && needShowWarning}
        title={title}
        onClose={() => {
          setShowWarning(false);
          onClose();
        }}
        onAccept={() => {
          setShowWarning(false);
          setNeedShowWarning(false);
        }}
      />
    </>
  ) : null;
}

export default UpdateDecision;
