export const FILE_ACCEPT =
  // чтобы проводник мог правильно показать список доступных файлов явно укажем расширения
  '.pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .bmp, .wmv, .mpg, mov, .avi, .ppt, .pptx, .vdx, .vsd, .vsdx, .vob, .txt, .rtf, .zip, ' +
  'application/pdf, ' +
  'application/msword, ' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
  'application/vnd.ms-excel, ' +
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ' +
  'image/*, ' +
  'video/*, ' +
  'audio/*, ' +
  'application/vnd.ms-powerpoint, ' +
  'application/vnd.openxmlformats-officedocument.presentationml.presentation, ' +
  'application/vnd.openxmlformats-officedocument.presentationml.template, ' +
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow, ' +
  'application/vnd.visio, ' +
  'application/vnd.visio.xml, ' +
  'application/vnd.ms-visio, ' +
  'application/vnd.ms-visio.viewer, ' +
  'application/vnd.ms-visio.drawing, ' +
  'application/x-visio, ' +
  'application/rtf, ' +
  'text/plain, ' +
  'application/zip, application/x-zip-compressed';
