import React, { useState, useEffect, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useIntl } from 'react-intl';

import http from '../../rest';

import AuthWelcome from './welcome';
import AuthPhone from './phone';
import AuthSms from './sms';
import AuthPinCreate from './pin_create';
import AuthPin from './pin';
import Support from '../../components/support';
import ModalWindow from '../../components/modal_window';

import { authUser } from '../../store/user/actions';
import { UserDispatchContext } from '../../store/user/store';

import { ROLE_ROOT } from '../../constants/roles';

import './auth.scss';

const [AUTH, AUTH_PIN, AUTH_PIN_CREATE, AUTH_SMS] = ['/auth', '/auth/pin', '/auth/pin/create', '/auth/sms'];

function Auth({ match, history, location }) {
  const intl = useIntl();

  const dispatch = useContext(UserDispatchContext);
  const [dataByPhone, setDataByPhone] = useState(null);
  const [sms, setSms] = useState('');
  const [phone, setPhone] = useState('');
  const [supportOpen, setSupportOpen] = useState(false);
  const { pathname } = location;

  useEffect(() => {
    if (
      (pathname === AUTH_SMS && dataByPhone === null) ||
      ([AUTH_PIN, AUTH_PIN_CREATE].includes(pathname) && sms === '')
    ) {
      history.replace({ pathname: AUTH });
    }
  }, [pathname, dataByPhone, history, sms]);

  const authByPhone = (phone, cb) => {
    return http.api
      .post('/public/login/phone', { phone })
      .then(({ data, status }) => {
        setDataByPhone(data);
        setPhone(phone);
        history.push({ pathname: AUTH_SMS });
      })
      .catch(err => {
        cb(err.message);
      });
  };

  const authBySms = (sms, cb) => {
    http.api
      .post('/public/login/code', {
        phone: phone,
        otp: sms,
        nonce: dataByPhone.nonce,
      })
      .then(({ data, status }) => {
        if (status) {
          setSms(sms);
          cb();
          if (data.newPin) {
            history.push({ pathname: AUTH_PIN_CREATE });
          } else {
            history.push({ pathname: AUTH_PIN });
          }
        } else {
          cb(data.message);
        }
      });
  };

  const authByPin = (pin, cb) => {
    http.api
      .post('/public/login/complete', {
        phone: phone,
        otp: sms,
        nonce: dataByPhone.nonce,
        pin: pin,
      })
      .then(({ data }) => {
        if (typeof data.token === 'string') {
          const { userDetails = {} } = data;
          if (userDetails.role === ROLE_ROOT && process.env.NODE_ENV === 'production') {
            window.location.replace('https://admin.' + window.location.host);
          } else {
            dispatch(authUser(userDetails));
            history.push({ pathname: '/' });
          }
        } else {
          cb(data);
        }
      });
  };

  const clearAuth = () => {
    setDataByPhone(null);
    setSms('');
    setPhone('');
    history.replace(AUTH);
  };

  return (
    <div className="auth">
      <div className="auth__panel">
        <div className="auth__logo" />
        <Switch>
          <Route exact path={match.path} render={() => <AuthPhone authByPhone={authByPhone} />} />
          <Route exact path={`${match.path}/pin/create`} render={() => <AuthPinCreate authByPin={authByPin} />} />
          <Route exact path={`${match.path}/pin`} render={props => <AuthPin authByPin={authByPin} {...props} />} />
          <Route exact path={`${match.path}/welcome`} render={() => <AuthWelcome />} />
          <Route
            exact
            path={`${match.path}/sms`}
            render={() => <AuthSms authBySms={authBySms} phone={phone} clearAuth={clearAuth} />}
          />
          <Redirect to={match.path} />
        </Switch>
        <div className="auth__bottom">
          <div className="auth__bottom_text" onClick={() => setSupportOpen(true)}>
            {intl.formatMessage({ id: 'auth.needHelp' })}
          </div>
        </div>
        {supportOpen && (
          <ModalWindow onClose={() => setSupportOpen(false)}>
            <Support />
          </ModalWindow>
        )}
      </div>
    </div>
  );
}

export default Auth;
