import React, { Fragment, useEffect, useState, useCallback, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';

import MembersSearch from '../../components/members_search';
import DropZone from '../../components/dropzone';
import Button from '../../components/button';
import Input from '../../components/input';

import { UserStoreContext } from '../../store/user/store';
import http from '../../rest';
import { useNotifyListDataUpdate } from '../../hooks/use_notify_list_data_update';

import { DOCUMENT_TITLE_MAX_LENGTH, DOCUMENT_DESCRIPTION_MAX_LENGTH } from '../../constants/documents';

import isEmpty from 'lodash/fp/isEmpty';
import every from 'lodash/fp/every';

import './new-task.scss';

function NewTask({ history }) {
  const alert = useAlert();
  const intl = useIntl();
  const user = useContext(UserStoreContext);
  const [executors, setExecutors] = useState([]);
  const [title, setTitle] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [filesRus, setFilesRus] = useState([]);
  const [filesEng, setFilesEng] = useState([]);
  const [watchers, setWatchers] = useState([]);
  const [sendingData, setSendingData] = useState(false);
  const filterMembers = [user];

  const [errors, setErrors] = useState(emptyFormErrors());

  const notify = useNotifyListDataUpdate();

  const sendTask = () => {
    if (!isFormValid(errors) || isPristine()) {
      return;
    }

    setSendingData(true);
    http.rpc
      .post('/sendDraftTaskForExecution', save())
      .then(res => {
        setSendingData(false);
        const { data, status } = res;
        if (data.code === 500 || status === false) {
          alert.error(data.message);
        } else {
          notify();
          history.goBack();
        }
      })
      .catch(() => {
        setSendingData(false);
      });
  };

  useEffect(() => {
    http.rpc.post('/getDraftTask', {}).then(res => {
      const { data, status } = res;
      if (data.code === 500 || status === false) {
        history.goBack();
      } else {
        const {
          title,
          title_en,
          description,
          description_en,
          executors = [],
          documentsRu,
          documentsEng,
          watchers = [],
        } = data;
        setTitle(title || '');
        setTitleEn(title_en || '');
        setDescription(description || '');
        setDescriptionEn(description_en || '');
        if (executors.length > 0) {
          setExecutors(executors);
        }
        setFilesRus(documentsRu);
        setFilesEng(documentsEng);
        setWatchers(watchers);
        setErrors(emptyFormErrors());
      }
    });
    /* eslint-disable-next-line */
  }, [history]);

  const save = () => {
    return {
      title,
      title_en: titleEn,
      description,
      description_en: descriptionEn,
      executors: executors.map(({ id }) => id),
      watchers: watchers.map(({ id }) => id),
      documentsRu: filesRus.map(({ id }) => id),
      documentsEng: filesEng.map(({ id }) => id),
    };
  };

  const isPristine = useCallback(() => {
    return isTaskPristine({
      title,
      title_en: titleEn,
      description,
      description_en: descriptionEn,
      executors,
      documentsRu: filesRus,
      documentsEng: filesEng,
      watchers,
    });
  }, [title, titleEn, description, descriptionEn, executors, filesRus, filesEng, watchers]);

  useEffect(() => {
    if (isPristine()) {
      setErrors(emptyFormErrors());
    } else {
      const validateForm = validateFormWith({
        emptyField: intl.formatMessage({ id: 'newTask.error.emptyField' }),
      });
      setErrors(validateForm({ executors, title, titleEn, description, descriptionEn }));
    }
  }, [executors, title, titleEn, description, descriptionEn, isPristine, intl]);

  return (
    <Fragment>
      <div className="new-task">
        <div className="new-task__view">
          <form className="new-task__view__form" autoComplete="off">
            <div className="new-task__exetutor">
              <MembersSearch
                label={intl.formatMessage({ id: 'newTask.executors' }, { executors: executors.length })}
                getMembers={setExecutors}
                members={executors}
                filterMembers={filterMembers}
                error={errors.executors}
              />
            </div>
            <div className="new-task__one-for-each">
              <div className="new-task__one-for-each__labels">
                <div className="new-task__one-for-each__labels__subtitle">
                  {intl.formatMessage({ id: 'newTask.manyExecutorsNotification' })}
                </div>
              </div>
            </div>
            <MembersSearch
              label={intl.formatMessage({ id: 'newTask.inCopy' })}
              getMembers={setWatchers}
              members={watchers}
              filterMembers={filterMembers}
            />

            <div className="new-request-container__form__section_label">
              {intl.formatMessage({ id: 'newTask.section.title' })}
            </div>
            <Input
              placeholder={intl.formatMessage({ id: 'newTask.section.title.inRussian' })}
              onChange={value => setTitle(value)}
              value={title}
              maxLength={DOCUMENT_TITLE_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.title}
            />
            <Input
              placeholder={intl.formatMessage({ id: 'newTask.section.title.inEnglish' })}
              onChange={value => setTitleEn(value)}
              value={titleEn}
              maxLength={DOCUMENT_TITLE_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.titleEn}
            />

            <div className="new-request-container__form__section_label">
              {intl.formatMessage({ id: 'newTask.section.description' })}
            </div>
            <Input
              placeholder={intl.formatMessage({ id: 'newTask.section.description.inRussian' })}
              onChange={value => setDescription(value)}
              value={description}
              maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.description}
            />
            <Input
              placeholder={intl.formatMessage({ id: 'newTask.section.description.inEnglish' })}
              onChange={value => setDescriptionEn(value)}
              value={descriptionEn}
              maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
              counter={true}
              floatingLabel={true}
              error={errors.descriptionEn}
            />

            <DropZone
              title={intl.formatMessage({ id: 'newTask.section.documents.inRussian' })}
              onFileAddExt={files => setFilesRus(filesRus.concat(files))}
              onFileRemove={fileId => setFilesRus(filesRus.filter(file => file.id !== fileId))}
              initialFiles={filesRus}
              multiple={true}
            />
            <DropZone
              title={intl.formatMessage({ id: 'newTask.section.documents.inEnglish' })}
              onFileAddExt={files => setFilesEng(filesEng.concat(files))}
              onFileRemove={fileId => setFilesEng(filesEng.filter(file => file.id !== fileId))}
              initialFiles={filesEng}
              multiple={true}
            />
          </form>
        </div>
      </div>
      <div className="new-request-container__actions">
        <Button color="blue" onClick={sendTask} loading={sendingData}>
          {intl.formatMessage({ id: 'newTask.send' })}
        </Button>
      </div>
    </Fragment>
  );
}

export default withRouter(NewTask);

// Utils

function emptyFormErrors() {
  return {
    executors: '',
    title: '',
    titleEn: '',
    description: '',
    descriptionEn: '',
  };
}

function validateFormWith(errorsText) {
  return ({ executors, title, titleEn, description, descriptionEn }) => {
    const errors = emptyFormErrors();

    if (isEmpty(executors)) {
      errors['executors'] = errorsText.emptyField;
    }

    if (isEmpty(title)) {
      errors['title'] = errorsText.emptyField;
    }

    if (isEmpty(titleEn)) {
      errors['titleEn'] = errorsText.emptyField;
    }

    if (description.length === 0 && descriptionEn.length !== 0) {
      errors['description'] = errorsText.emptyField;
    }

    if (description.length !== 0 && descriptionEn.length === 0) {
      errors['descriptionEn'] = errorsText.emptyField;
    }

    return errors;
  };
}

function isFormValid(errors) {
  return every(isEmpty, errors);
}

function isTaskPristine({
  title,
  title_en,
  description,
  description_en,
  executors,
  documentsRu,
  documentsEng,
  watchers,
}) {
  return (
    title === '' &&
    title_en === '' &&
    description === '' &&
    description_en === '' &&
    executors.length === 0 &&
    documentsRu.length === 0 &&
    documentsEng.length === 0 &&
    watchers.length === 0
  );
}
