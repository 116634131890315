import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import CoordinationAvatar from '../coordination_avatar';
import DocumentPreviewList from '../../document_preview_list';
import LeftToApprove from '../left_to_approve';
import ModalWindow from '../../modal_window';
import Profile from '../../profile';

import formatUserName from '../../../utils/string_utils';
import formatDate from '../../../utils/date_utils';
import { businessStatusToLabel } from '../../../rest/dto/Decision';

import { EXECUTING, REQUESTED, MY_REQUESTED, MY_RESPONSE } from '../../../constants/decision_business_statuses';
import { VIEW_DECISIONS } from '../../../constants/permissions';

import isEmpty from 'lodash/fp/isEmpty';

const CoordinationApprover = ({ person, me, permissions }) => {
  const intl = useIntl();

  const [userModalOpen, setUserModalOpen] = useState(false);
  const [removedVisible, setRemovedVisible] = useState(false);

  const { documents = [], removedDocuments = [], status } = person;

  const haveDocuments = !isEmpty(documents);
  const haveRemovedDocuments = !isEmpty(removedDocuments);
  const showFilesContainer = status && (me || haveDocuments || haveRemovedDocuments);
  const toggleRemoved = () => setRemovedVisible(visible => !visible);
  const collapseText = removedVisible
    ? intl.formatMessage({ id: 'coordination.approver.hideRemovedFiles' })
    : intl.formatMessage({ id: 'coordination.approver.showRemovedFiles' }, { count: removedDocuments.length });

  const classNames = ['coordination__level', '--approver'];
  classNames.push(status !== undefined ? `--${status.toLowerCase()}` : '--waiting');

  const canViewDecisions = permissions.includes(VIEW_DECISIONS);

  const personComment = person.comment;

  return (
    <div className={classNames.join(' ')}>
      <div className="coordination__header">
        <div className="coordination__level_user" onClick={() => setUserModalOpen(true)}>
          <CoordinationAvatar id={person.photoId} />
          <div className="coordination__level_name">{formatUserName(person, intl.locale)}</div>
        </div>
        <div className="coordination__level_date">
          {status && ![REQUESTED, EXECUTING, MY_REQUESTED, MY_RESPONSE].includes(status) && (
            <div>{formatDate(person.date, true, intl.locale)}</div>
          )}
        </div>
        <div className="coordination__level_days_spent">
          <LeftToApprove
            leftDaysToApprove={person.leftDaysToApprove}
            leftHoursToApprove={person.leftHoursToApprove}
            daysSpent={person.daysSpent}
            hoursSpent={person.hoursSpent}
            status={status}
            type="coordination"
          />
        </div>
        <div className={'coordination__level_status' + ((status && ` --${status}`) || '')}>
          {status ? intl.formatMessage({ id: businessStatusToLabel(status) }) : ''}
        </div>
      </div>
      {canViewDecisions && personComment && <div className={`coordination__comment --approver`}>{personComment}</div>}
      {canViewDecisions && showFilesContainer && (
        <div className="coordination__files_container">
          <DocumentPreviewList docList={documents} />

          {removedVisible && (
            <div className="coordination__files_removed">
              <DocumentPreviewList docList={removedDocuments} forceViewed={true} />
            </div>
          )}

          {haveRemovedDocuments && (
            <div className="coordination__files_more" onClick={toggleRemoved}>
              {collapseText}
            </div>
          )}
        </div>
      )}
      {userModalOpen && (
        <ModalWindow onClose={() => setUserModalOpen(false)}>
          <Profile user={person} />
        </ModalWindow>
      )}
    </div>
  );
};
CoordinationApprover.defaultProps = {
  me: false,
  permissions: [],
};

CoordinationApprover.propTypes = {
  me: PropTypes.bool,
  person: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.number,
    status: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
};

export default CoordinationApprover;
