import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import CoordinationAvatar from '../coordination_avatar';
import DocumentPreviewVitalList from '../../document_preview_vital_list';
import DocumentPreviewList from '../../document_preview_list';
import ModalWindow from '../../modal_window';
import Profile from '../../profile';

import formatUserName from '../../../utils/string_utils';
import formatDate from '../../../utils/date_utils';

import isEmpty from 'lodash/fp/isEmpty';

const CoordinationInitiator = ({ person, skippedApproval, urgentApproval }) => {
  const intl = useIntl();

  const [userModalOpen, setUserModalOpen] = useState(false);
  const [removedVisible, setRemovedVisible] = useState(false);

  const { documents = { ru: [], eng: [] }, removedDocuments = [] } = person;

  const toggleRemoved = () => setRemovedVisible(visible => !visible);
  const haveRemovedDocuments = !isEmpty(removedDocuments);
  const collapseText = removedVisible
    ? intl.formatMessage({ id: 'coordination.initiator.hideRemovedFiles' })
    : intl.formatMessage({ id: 'coordination.initiator.showRemovedFiles' }, { count: removedDocuments.length });
  const approvalTypeComment = skippedApproval
    ? intl.formatMessage({ id: 'documentPackage.skippedApprovalComment' })
    : urgentApproval
    ? intl.formatMessage({ id: 'documentPackage.urgentApproveComment' })
    : '';
  const personComment = person.comment;

  return (
    <div className="coordination__level --initiator">
      <div className="coordination__header">
        <div className="coordination__level_user" onClick={() => setUserModalOpen(true)}>
          <CoordinationAvatar id={person.photoId} />
          <div className="coordination__level_name">{formatUserName(person, intl.locale)}</div>
        </div>
        <div className="coordination__level_date --long">
          {`${formatDate(person.createdDate, true, intl.locale)}`}
          {person.lastStartedDate &&
            person.lastStartedDate !== person.createdDate &&
            `, ${intl.formatMessage({ id: 'coordination.initiator.modified' })} ${formatDate(
              person.lastStartedDate,
              true,
              intl.locale
            )}`}
        </div>
      </div>
      {personComment && <div className="coordination__comment">{personComment}</div>}
      <div className="coordination__files_container">
        <div className="coordination__files_vital">
          <DocumentPreviewVitalList documents={documents.ru} title="Рус" />
          <DocumentPreviewVitalList documents={documents.eng} title="Eng" />
        </div>

        {removedVisible && (
          <div className="coordination__files_removed">
            <DocumentPreviewList docList={removedDocuments} forceViewed={true} />
          </div>
        )}

        {haveRemovedDocuments && (
          <div className="coordination__collapse" onClick={toggleRemoved}>
            {collapseText}
          </div>
        )}
      </div>
      <div className="coordination__approval-type">{approvalTypeComment}</div>
      {userModalOpen && (
        <ModalWindow onClose={() => setUserModalOpen(false)}>
          <Profile user={person} />
        </ModalWindow>
      )}
    </div>
  );
};
CoordinationInitiator.propTypes = {
  person: PropTypes.shape({
    name: PropTypes.string,
    createdDate: PropTypes.number,
    lastStartedDate: PropTypes.number,
    status: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
  skippedApproval: PropTypes.bool.isRequired,
  urgentApproval: PropTypes.bool.isRequired,
};
export default CoordinationInitiator;
