import React, { useState, useContext } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Auth from '../pages/auth';
import Logout from '../pages/logout';
import NoMatch from '../pages/no_match';
import Members from '../pages/members';
import Package from '../pages/package';
import Menu from '../components/menu';
import Messaging from '../components/messaging/messaging';
import MobileView from '../components/mobile_view';
import DefaultRedirect from './routes/default_redirect';
import { authCheckState } from '../store/user/actions';
import { UserStoreContext, UserDispatchContext } from '../store/user/store';
import { ROLE_APPROVER, ROLE_FINAL_APPROVER, ROLE_INITIATOR } from '../constants/roles';
import isMobile from '../utils/source_utils';
import './app.scss';
import Familiarization from '../pages/familiarization';
import {
  URL_404,
  URL_AUTH,
  URL_FAMILIARIZATION,
  URL_INBOX,
  URL_MEMBERS,
  URL_OUTGOING,
  URL_LOGOUT,
  URL_PACKAGE,
} from '../constants/urls';
import Splash from '../pages/splash';
import Outgoing from '../pages/outgoing';
import Inbox from '../pages/inbox';

function App() {
  const user = useContext(UserStoreContext);
  const dispatch = useContext(UserDispatchContext);

  const { authenticated, role } = user;
  const [prevAuthenticated, setPrevAuthenticated] = useState(undefined);

  if (isMobile()) {
    return <MobileView />;
  }

  if (typeof authenticated !== 'boolean') {
    authCheckState()(dispatch);
  }

  if (authenticated !== prevAuthenticated) {
    setPrevAuthenticated(authenticated);
  }

  let routes = null;
  if (authenticated) {
    const redirectUrl =
      ([ROLE_APPROVER, ROLE_FINAL_APPROVER, ROLE_INITIATOR].includes(role) && URL_INBOX) ||
      (role === ROLE_INITIATOR && URL_OUTGOING) ||
      URL_MEMBERS;
    routes = (
      <Switch>
        {[ROLE_APPROVER, ROLE_FINAL_APPROVER, ROLE_INITIATOR].includes(role) && (
          <Route path={URL_INBOX} component={Inbox} />
        )}
        {[ROLE_INITIATOR, ROLE_APPROVER, ROLE_FINAL_APPROVER].includes(role) && (
          <Route path={URL_OUTGOING} component={Outgoing} />
        )}
        <Route path={URL_FAMILIARIZATION} component={Familiarization} />
        <Route path={URL_MEMBERS} component={Members} />
        <Route exact path={`${URL_PACKAGE}/:packageId`} component={Package} />
        <Route exact path={URL_404} component={NoMatch} />
        {/* used for force logout if smth goes wrong  */}
        <Route exact path={URL_LOGOUT} component={Logout} />
        <Redirect exact from="/" to={redirectUrl} />
        <DefaultRedirect />
      </Switch>
    );
  } else if (authenticated === false) {
    routes = (
      <Switch>
        <Route path={URL_AUTH} component={Auth} />
        {/* used for force logout if smth goes wrong  */}
        <Route exact path={URL_LOGOUT} component={Logout} />
        <DefaultRedirect />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route component={Splash} />
        {/* used for force logout if smth goes wrong  */}
        <Route exact path={URL_LOGOUT} component={Logout} />
      </Switch>
    );
  }
  return (
    <BrowserRouter>
      <div className="layout">
        <Menu />
        <div className="layout__content">{routes}</div>
      </div>
      <Messaging.Component user={user} />
    </BrowserRouter>
  );
}

export default App;
